import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStudentList } from "../../../actions/school_user/StudentRegistration";
import useLocalStorageData from "./LocalStorageData";
import useSessionRedirect from "./SessionChange";
import { convertDate } from "../../CommonComponent/DateFormatUtil";
import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "../../CommonComponent/modelComponent";
const AllStudentList = (props) => {
  // in this file all student list will be shown here(external also)
  const { STATUS_TYPE, searchType, setstudent_uid } = props;
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const session_id = useSessionRedirect();
  const [searchQuery, setSearchQuery] = useState("");
  const [idQuery, setIdQuery] = useState("");
  const [CurrentPage, setCurrentPage] = useState(1);

  const [Page, setPage] = useState(1);
  const resultPerPage = 10;
  const [setAllPageData, setSetAllPageData] = useState([]);
  const dispatch = useDispatch();
  const { studentdata } = useSelector((state) => state.studentRegistration);
 

  const [searchResults, setSearchResults] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
    setSearchResults([]);
    setNoResultsMessage("");
    // setCurrentPage(1);
    // setPage(1);
    setIdQuery("");
    setSearchQuery("");
  };

  const handleInputChangeSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };
  const handleIdInputChange = (e) => {
    const query = e.target.value;
    setIdQuery(query);
  };
  const handleSearch = () => {
    let filteredResults;
    filteredResults = studentdata?.payload?.filter(
      (item) =>
        item.std_name?.toLowerCase().includes(searchQuery?.toLowerCase()) &&
        item.student_code?.toString().includes(idQuery)
    );
    setSearchResults(filteredResults);
    setSetAllPageData(filteredResults);
    setSearchQuery("");
    setIdQuery("");
    if (filteredResults?.length === 0) {
      setNoResultsMessage("No results found.");
    } else {
      setNoResultsMessage("");
    }
  };

  const handleStudentEdit = (data) => {
    setstudent_uid(data?.student_code);
    setModal(!modal);
  };
  const getList = () => {
    let body = {
      fk_academic: session_id,
      school_id: school_id,
      student_name: searchQuery,
      student_code: idQuery,
      page: CurrentPage,
      status_type: "All",
    };
    if (token) {
      dispatch(getStudentList(token, body)).then((res) =>
        setPage(res.totalRecordCount)
      );
    }
  };
  useEffect(() => {
    getList();
  }, [dispatch, token, session_id, CurrentPage, idQuery, searchQuery]);

  useEffect(() => {
    let filteredResults;
    filteredResults = studentdata?.payload?.filter(
      (item) =>
        item.std_name?.toLowerCase().includes(searchQuery?.toLowerCase()) &&
        item.student_code?.toString().includes(idQuery)
    );
    setSearchResults(filteredResults);
    setSetAllPageData(filteredResults);
  }, [studentdata]);

  return (
    <>
      <button
        className="btn btn-outline-secondary"
        type="button"
        onClick={() => {
          toggle();
          setSetAllPageData([]);
        }}
      >
        <i className="fa fa-search"> </i> Search
      </button>

      <CustomModal
        modal={modal}
        toggle={toggle}
        activePage={CurrentPage}
        itemsCountPerPage={resultPerPage}
        totalItemsCount={Page}
        onChange={(e) => setCurrentPage(e)}
      >
        {" "}
        <CustomModalHeader toggle={toggle}>
          <div className="row">
            <div className="col-lg-5 col-md-5 my-1">
              <input
                name="code"
                type="text"
                className="form-control"
                placeholder="Student code"
                value={idQuery}
                onChange={handleIdInputChange}
                autoComplete="off"
              />
            </div>
            <div className="col-lg-5 col-md-5 my-1">
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Student Name"
                value={searchQuery}
                onChange={handleInputChangeSearch}
                autoComplete="off"
              />
            </div>
            <div className="col-lg-2 col-md-2  my-1 text-right">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => {
                  handleSearch();
                  getList();
                }}
              >
                Search
              </button>
            </div>
          </div>{" "}
        </CustomModalHeader>
        <CustomModalBody noResultsMessage={noResultsMessage}>
          <thead>
            <tr className="text-center">
              <th>Registration Date</th>
              <th>Stud Code</th>
              <th>Student Name</th>
              <th>Gender</th>
              <th>DOB</th>
              <th>Category</th>
              <th>Caste</th>
              <th>Sub Caste</th>
              <th>Student Status</th>
              <th>Enrollment No</th>
              <th>Academic Session</th>
            </tr>
          </thead>
          <tbody>
            {setAllPageData?.map((stu, index) => {
              return (
                <tr
                  key={index}
                  className="text-center clickable-row"
                  onClick={() => handleStudentEdit(stu)}
                >
                  <td>{convertDate(stu.registration_date)}</td>
                  <td>{stu.student_code}</td>
                  <td className="text-left">{stu.std_name}</td>
                  <td>{stu.gender}</td>
                  <td>{convertDate(stu.date_of_birth)}</td>
                  <td>{stu.fk_category_name}</td>
                  <td>{stu.fk_caste_name}</td>
                  <td>{stu.sub_caste}</td>
                  <td>{stu.student_status}</td>
                  <td></td>
                  <td>{stu?.academic_year}</td>
                </tr>
              );
            })}
          </tbody>
        </CustomModalBody>
      </CustomModal>
    </>
  );
};

export default AllStudentList;
