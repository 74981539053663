import React, { useEffect, useState } from "react";
import { Heading } from "../../../CommonComponent/heading";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { CiLight, CiSearch } from "react-icons/ci";
import Select from "react-select";
import {
  ClearButton,
  SaveButton,
  ViewButton,
} from "../../../CommonComponent/ReusableButton";
import { BusMasterApi } from "../../../../api/Transport/BusMasterAPI";
import useLocalStorageData from "../../Comman/LocalStorageData";
import { DriverMasterAPI } from "../../../../api/Transport/DriverMasterAPI";
import { RouteconfigurationAPI } from "../../../../api/Transport/RouteConfiguration";
import { NavLink, useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormGroup,
  Form,
} from "reactstrap";
const EditRoute = () => {
  // user can edit route from here
  const navigate = useNavigate();

  const location = useLocation();
  const id = location.state?.id;

  const { school_id, token, school_emp_id } = useLocalStorageData();
  const [open, setOpen] = useState(false);
  const [focusAfterClose, setFocusAfterClose] = useState(true);
  const [DataFor1DropdownData, setDataFor1DropdownData] = useState("");
  const [routeData, setRouteData] = useState([]);
  const [BusOptions, setBusOptions] = useState([]);
  const [selectBus, setselectBus] = useState(id);
  const [driverData, setDriverData] = useState([]);
  const [driverInfo, setDriverInfo] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [attendantInfo, setAttendantInfo] = useState([]);
  const [driverName ,setDriverName] = useState("")


  useEffect(() => {
    if (!id) {
      navigate("/school/route-configuration");
    }
    getRouteConfiguration(id)
    getDriverMasterData(id)
  }, [id]);

  // console.log(selectBus)
  const toggle = () => {
    setOpen(!open);
    get_Busmaster_data();
  };

 

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  useEffect(() => {
    get_Busmaster_data();
    
  }, [id]);

  const get_Busmaster_data = async () => {
    try {
      let data = {
        school_id: school_id,
      };
      const response = await BusMasterApi.BusMasterListData(data);
      const responseData = response.data;
      const busResponse = responseData.bus_data;
      // setDriverName(busResponse.driver_attendant_name)
      console.log(busResponse)
      const filteredBusOptions = busResponse.map((bus) => ({
        value: bus.id,
        label: bus.registration_no,
      }));
      setBusOptions(filteredBusOptions);
    } catch (err) {
      console.error(err);
    }
  };
  const getRouteConfiguration = async (id) => {
    try {
      let data = {
        school_id: school_id,
      };
      let response = await RouteconfigurationAPI.getRouteconfigurations(data);
      let responseData = response.data;

      const filteredRoute = responseData.route_configuration.find(
        (route) => route.id === id
      );
      setRouteData(filteredRoute);
      setIsChecked(filteredRoute.is_active);

    } catch (err) {
      console.error(err);
    }
  };



  const getDriverMasterData = async (selectBus) => {
    try {
      let data = {
        school_id: school_id,
      };
      const response = await DriverMasterAPI.getDrivermasterdata(data);
      const responseData = response.data;
      const busResponse = responseData.driver_master_data;
  
      // Filter driver data corresponding to the selected bus ID
      const selectedDriverData = busResponse.filter(driver => driver.fk_busMaster_id === selectBus);
      console.log(selectedDriverData)
  
      // Separate drivers and attendants based on emp_type
      const drivers = selectedDriverData.filter(driver => driver.emp_type === 'Driver');
      const attendants = selectedDriverData.filter(driver => driver.emp_type === "Attendant");
  console.log(attendants ,"attendent")
      // Extract names and IDs of drivers and attendants
      const driverInfo = drivers.map(driver => ({ id: driver.id, name: driver.driver_attendant_name }));
      const attendantInfo = attendants.map(attendant => ({ id: attendant.id, name: attendant.attendant_name })); 
      console.log(attendantInfo,"attendantInfo")
  
      // Save names and IDs in different states
      setDriverInfo(driverInfo);
      setAttendantInfo(attendantInfo);
    } catch (err) {
      console.error(err);
      // Handle errors as needed
    }
  };


  // console.log(driverInfo);
  const handleChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };






  let handleChangeClass = (select) => {
    const selectedOption = select;
    setselectBus(selectedOption); 
    formik.setFieldValue('bus_id', selectedOption);
    getDriverMasterData(selectedOption)
      .then(empType => {
        if (empType) {
          setDriverData(empType);
        }
      })
      .catch(error => {
        console.error("Error retrieving emp_type:", error);
      });
  };


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      school_id: school_id,
      bus_id: routeData?.fk_busMaster_id || "",
      route_id: routeData.id || "",
      route_name: routeData.route_name,
      start_time: routeData.start_time,
      end_time: routeData.end_time,
      driver_id: routeData.fk_driver_id || "",
      attendant_id: routeData.fk_attendant_id || "",
      is_active: routeData.is_active,
      insert_by: school_emp_id,
    },
    validationSchema: Yup.object().shape({
      route_name: Yup.string().required("Route Name is required"),

      bus_id: Yup.string().required(" Bus No is required"),
    }),

    onSubmit: async (values) => {
      try {
        let data = {
          ...values,
          is_active: isChecked ? "True" : "False",
        };
        console.log(data)

        const response = await RouteconfigurationAPI.addRouteconfigurations(
          data
        );
        const responseData = response.data;
        console.log(response);
        console.log(responseData);
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
          });
          handelBack()
          handleClear();
        } else {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  });
  useEffect(() => {
    formik.setValues((values) => ({
      ...values,
      driver_id: driverInfo.length > 0 ? driverInfo[0].id : "",
      attendant_id: attendantInfo.length > 0 ? attendantInfo[0].id : "",
    }));
  }, [driverInfo, attendantInfo]);



  const handelClear=()=>{
    formik.resetForm();
    setselectBus(null);
    setDriverData([]);
    setIsChecked(false);

  }
  const handelBack=()=>{
    navigate('/school/route-configuration')
  }
  console.log(driverName)

  return (
    <>
     <div className="section-body">
        <div className="container-fluid">
    
        <Heading pageTitle={"Edit Route"}>
              <div className="row m-3">
              <button className="btn btn-outline-info" onClick={handelBack} type="button">Go Back</button>
              </div>
            </Heading>
            <div className="card">
              <div className="card-body">
                <form
                  className="card-body form-horizontal"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="form-group row mt-4 ">
                    <label className="col-form-label col-3 text-left">
                      Route Name <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        type="Text"
                        placeholder="Enter route name"
                        className="form-control"
                        name="route_name"
                        value={formik.values.route_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.route_name &&
                        formik.errors.route_name && (
                          <div className="text-danger">
                            {formik.errors.route_name}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-3 col-form-label text-left">
                      Bus Number <span className="text-danger">*</span>
                    </label>

                    <div className="col-md-8">
                      <Select
                        options={BusOptions}
                        name="bus_id"
                        placeholder="Select an option"
                        value={
                         BusOptions?.find((option)=>option.value === formik.values.bus_id)
                        }
                        onChange={(option) => {
                          formik.setFieldValue('bus_id', option.value);
                          handleChangeClass(option.value);
                      }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.bus_id && formik.errors.bus_id && (
                        <div className="text-danger">
                          {formik.errors.bus_id}
                        </div>
                      )}
                    </div> 
                  </div>

                  <div className="form-group row">
                    <label className="col-md-3 col-form-label text-left">
                      Start Time
                    </label>
                    <div className="col-md-3">
                      <input
                        type="text"
                        className="form-control custom-time-input"
                        placeholder="Enter start time"
                        name="start_time"
                        value={formik.values.start_time}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <label className="col-md-2 col-form-label text-left">
                      End Time
                    </label>
                    <div className="col-md-3">
                      <input
                        type="text"
                        placeholder="Enter end time"
                        className="form-control custom-time-input"
                        name="end_time"
                        value={formik.values.end_time}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-3 col-form-label text-left">
                      Driver
                    </label>
                    <div className="col-md-8">
                      <input
                        type="text"
                        // placeholder="Enter Driver Name"
                        className="form-control"
                        name=" driver_id"
                        value={driverInfo[0]?.name ||routeData.driver_attendant_name||""}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-3 col-form-label text-left">
                      Attendent
                    </label>
                    <div className="col-md-8">
                      <input
                        type="text"
                        className="form-control"
                        name="attendant_id"
                        value={attendantInfo[0]?.name ||routeData.attendant_name ||""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label text-left">
                      Active
                    </label>
                    <div className="col-md-1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                  </div>
                  <button
                    className="btn btn-primary btn-sm mx-1 m-1"
                    type="submit"
                  >
                    <i className="fa fa-plus px-1"></i>
                    Update
                  </button>
     
                 
                </form>
              </div>
            </div>
            </div>
            </div>
    </>
  );
};

export default EditRoute;
