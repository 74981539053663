import React, { useState, useEffect, lazy, Suspense } from "react";
import Select from "react-select";
import SchoolListApi from "../../api/SchoolList/SchoolList";
import { useNavigate } from "react-router-dom";
import useLocalStorageData from "../schooladmin/Comman/LocalStorageData";
import { FaSearch } from "react-icons/fa";
const SchoolList = () => {
  // this file is for the school list drop down list in the header of Super admin
  const { school_id, token, schoolUser } = useLocalStorageData();
  const [studentsData, setstudentsData] = useState([]);
  const navigate = useNavigate();
   const handleRowClick = (stuId) => {
    const editUrl = "/admin/school-details";
    navigate(editUrl, { state: { id: stuId } });
    setstudent(null);
  };
  const [student, setstudent] = useState(null);

  const genderOptions = studentsData.map((student) => ({
    value: student.id,
    label: student.name,
  }));
  useEffect(() => { 
     fetchData();
  }, []);
  
  const [width ,setWidth] = useState(0)
  const [menuWidth ,setMenuWidth] = useState(0)

  useEffect(() => {
    if(window.innerWidth < 500){
    setWidth("9rem")
    setMenuWidth("18rem")
  }else
  {
    setWidth("18rem")
    setMenuWidth("18rem")
  }

  }, [])

  const fetchData = async () => {
    let data = { 
        search_string:""  
    };
    try {
      const response = await SchoolListApi.getSchoolList(data);
      let responseData = response.data;

      if (responseData.status === 200) {
        setstudentsData(responseData.school_list);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided, 
      minWidth:width,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    option: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      minWidth: menuWidth, 
      maxWidth: menuWidth,
      zIndex: 999,
    }),
  };

  

  const CustomDropdownIndicator = () => {
    return (
      <FaSearch
        size={16}
        style={{
          margin: "auto",
          marginRight: "10px",
          color: "#777",
          cursor: "pointer",
        }}
      />
    );
  };

  return (
    < >
   
      <Select
        name="student"
        placeholder="Search School..."
        className="Select-1  "
        options={genderOptions}
        value={
          student
            ? genderOptions?.find((option) => option.value === student)
            : null
        }
        onChange={(selectedOption) => {
          setstudent(selectedOption?.value);
          handleRowClick(selectedOption?.value);
        }}
        styles={customStyles}
        components={{ DropdownIndicator: CustomDropdownIndicator }}
      />
   
    </>
  );
};

export default SchoolList;