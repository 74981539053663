import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import EmptyListMessage from "../../CommonComponent/EmptyListMessage";
import { convertDate } from "../../CommonComponent/DateFormatUtil";

const Event = ({ eventactivity }) => {

  //this code is for calender the calender theme is displayed here
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [modal, setModal] = useState(false);

  const events = eventactivity?.map((event) => {
    const startDate=new Date(event.start_date).toISOString();
    const endDate=new Date(event.end_date).toISOString();  
    return {
      title: event.title,
      start: startDate,
      end: endDate,
      description: event.description,
      // className: 'bg-green'
    };
  });


  const handleDateClick = (arg) => {
    const clickedDate = arg.date;
    setSelectedDate(clickedDate);

    // const eventsOnDate = events.filter((event) => {
    //   const eventStartDate = new Date(event.start);
    //   const eventEndDate = new Date(event.end);
    //   return (
    //     eventStartDate <= clickedDate &&
    //     clickedDate <= eventEndDate
    //   );
    // });
    // Filter events that overlap with the selected date
    const eventsOnDate = events.filter((event) => {
      const eventStartDate = new Date(event.start);
      const eventEndDate = new Date(event.end);

      // Check if the selected date is within the range of the event's start and end dates (inclusive)
      return (
        (eventStartDate <= clickedDate && clickedDate <= eventEndDate) ||
        // Also include events that start or end on the selected date
        eventStartDate.toLocaleDateString() ===
          clickedDate.toLocaleDateString() ||
        eventEndDate.toLocaleDateString() === clickedDate.toLocaleDateString()
      );
    });

    // const eventsOnDate = events.filter(
    //   (event) =>
    //     new Date(event.start).toLocaleDateString() ===
    //     clickedDate.toLocaleDateString()
    // );
    // console.log(eventsOnDate, "event");
    setSelectedEvents(eventsOnDate);
    handleClick();
  };
  const toggleModal = () => {
    setModal(!modal);
  };

  const handleClick = (arg) => {
    toggleModal();
  };
  const closeBtn = (
    <button className="btn btn-sm close " onClick={toggleModal} type="button">
      <i className="fa fa-times"></i>
    </button>
  );
  return (
    <>
      <h5 className="card-title ">
        <i className="fa fa-bullhorn"></i> Event
        <hr className="m-1" />
      </h5>
      <div style={{ fontSize: "0.6rem"}}>
        <FullCalendar
          // ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          headerToolbar={{
            // left: "prev, today",
            left: "prev",
            center: "title",
            right: "next",
          }}
          initialView="dayGridMonth"
          selectable={true}
          events={events}
          dateClick={handleDateClick}
          showNonCurrentDates={true}
        />
      </div>
      <Modal isOpen={modal} toggle={toggleModal} className="mobel_box2" backdrop="static" keyboard={false}>
  <ModalHeader toggle={toggleModal} tag="h5" close={closeBtn}>
  {/* <Modal isOpen={modal} toggle={toggleModal} className="mobel_box1" >
        <ModalHeader toggle={toggleModal} tag="h5" close={closeBtn}> */}
    Event
  </ModalHeader>
  <ModalBody>
    {selectedEvents?.length > 0 ? (
      <>
        <table className="table table-sm table-hover table-bordered small-table">
          <thead className="thead-light text-nowrap">
            <tr className="text-center">
              <th>Event Title</th> 
              <th>Start</th>
              <th>End</th>
              <th>description</th>
            </tr>
          </thead>
          <tbody>
            {selectedEvents?.map((tp) => (
              <tr key={tp.id}>
                <td>{tp.title}</td>
                <td className="text-nowrap align-middle">
                  {convertDate(tp.start)}
                </td>
                <td className="text-nowrap align-middle">
                  {convertDate(tp.end)}
                </td>
                <td>{tp.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    ) : (
      <EmptyListMessage
        message={"Event Not Available!"}
        Div="div"
        size={"1.2rem"}
      />
    )}
  </ModalBody>
</Modal>

    </>
  );
};

export default Event;
