export const CREATE_STUDENT_REG_REQUEST = 'CREATE_STUDENT_REG_REQUEST';
export const CREATE_STUDENT_REG_SUCCESS = 'CREATE_STUDENT_REG_SUCCESS';
export const CREATE_STUDENT_REG_FAILURE = 'CREATE_STUDENT_REG_FAILURE';

export const GET_STUDENT_REG_REQUEST = 'GET_STUDENT_REG_REQUEST';
export const GET_STUDENT_REG_SUCCESS = 'GET_STUDENT_REG_SUCCESS';
export const GET_STUDENT_REG_FAILURE = 'GET_STUDENT_REG_FAILURE';

export const DELETE_STUDENT_REG_REQUEST = 'DELETE_STUDENT_REG_REQUEST';
export const DELETE_STUDENT_REG_SUCCESS = 'DELETE_STUDENT_REG_SUCCESS';
export const DELETE_STUDENT_REG_FAILURE = 'DELETE_STUDENT_REG_FAILURE';

export const UPDATE_STUDENT_REG_REQUEST = 'UPDATE_STUDENT_REG_REQUEST';
export const UPDATE_STUDENT_REG_SUCCESS = 'UPDATE_STUDENT_REG_SUCCESS';
export const UPDATE_STUDENT_REG_FAILURE = 'UPDATE_STUDENT_REG_FAILURE';



export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAILURE = 'GET_CATEGORY_FAILURE';


export const GET_CAST_REQUEST = 'GET_CAST_REQUEST';
export const GET_CAST_SUCCESS = 'GET_CAST_SUCCESS';
export const GET_CAST_FAILURE = 'GET_CAST_FAILURE';


