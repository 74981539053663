import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, Table } from "reactstrap";
import EmptyListMessage from "../../../CommonComponent/EmptyListMessage";
const EventList = (props) => {
  // all event list us shown here
  const { eventData, setIsEventId, onEdit, handleClear } = props;

  const [searchQuery, setSearchQuery] = useState("");
  const [idQuery, setIdQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
    setSearchResults([]);
    setNoResultsMessage("");
    setSearchQuery("");
  };


  const handleInputChangeSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };
  const handleIdInputChange = (e) => {
    const query = e.target.value;
    setIdQuery(query);
  };
  const handleSearch = () => {
    const filteredResults = eventData?.filter((item) =>
      item.title?.toLowerCase().includes(searchQuery?.toLowerCase())
    );
    setSearchResults(filteredResults);
    setSearchQuery("");

    if (filteredResults?.length === 0) {
      setNoResultsMessage("No Results Found.");
    } else {
      setNoResultsMessage("");
    }
  };

  const handleEventEdit = (data) => {
    handleClear();
    onEdit(data);
    setIsEventId(data);
    setModal(!modal);
  };
  return (
    <>
      <button
        className="btn btn-outline-secondary btn-sm mx-1"
        type="button"
        onClick={() => {
          // setSearch(stuList);
          toggle();
        }}
      >
        <i className="fa fa-search"> </i> Search
      </button>

      <Modal isOpen={modal} toggle={toggle} fullscreen className="mobel_box" backdrop="static" keyboard={false}>
        <div className="form-group row p-4">
          <div className="col-lg-3 col-md-3 ">
            <input
              type="text"
              className="form-control"
              name="name"
              placeholder="Title"
              value={searchQuery}
              onChange={handleInputChangeSearch}
              autoComplete="off"
            />
          </div>
          <div className="col-md-2 col-lg-2">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
          <div className="col-lg-3 col-md-3">
            {/* <input
              name="code"
              type="number"
              className="form-control"
              placeholder="Prospectus No"
              value={idQuery}
              onChange={handleIdInputChange}
              autoComplete="off"
            /> */}
          </div>

          <div className="col-lg-4 col-md-4 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-outline-danger btn-sm"
              onClick={toggle}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
        <ModalBody>
          {noResultsMessage ? (
            <p>{noResultsMessage}</p>
          ) : eventData.length > 0 ? (
            <Table size="sm" className="table-hover" bordered>
              <thead>
                <tr className="text-center table-secondary text-nowrap">
                  <th>Sr No.</th>
                  <th>Title</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {(searchResults.length > 0 ? searchResults : eventData).map(
                  (event, index) => (
                    <tr
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleEventEdit(event.id)}
                    >
                      <td className="text-center">{index + 1}</td>
                      <td className="">{event.title}</td>
                      <td>{event.description}</td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          ) : (
            <EmptyListMessage
              message={"Events And Activity Not Available !"}
              Div="div"
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default EventList;
