import React, { useState, useEffect } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import { useAlert } from "react-alert";
import { allUsers } from "../../../../actions/users/UsersAction";
import { UsersAPI } from "../../../../api/usersAPI";
import Loader from "../../../Loader/Loader";
import {
  addStaffUser,
  getStaffUser,
} from "../../../../actions/school_user/StaffUserMaster";
import { useParams } from "react-router-dom";

const AddUserMaster = (props) => {

  const {
    user_detail,
    staffdata,
    id,
    navigate,
    dispatch,
    school_id,
    staffUser,
  } = props;
  const [loading, setLoading] = useState(false);
  const [staffData, setStaffData] = useState({});
  const [optionListStaff, setOptionListStaff] = useState([]);
  console.log(optionListStaff);
  const [desOptionList, setDesOptionList] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [editStaffUser, setEditStaffUser] = useState(null);
  console.log(editStaffUser, "edit");
  // const {uid}=useParams()
  // console.log(uid)
  const [input, setInput] = useState({
    school_id: user_detail?.fk_school_id,
    staff_id: editStaffUser?.fk_staff_id || "",
    first_name: editStaffUser?.first_name || "",
    last_name: editStaffUser?.last_name || "",
    email: editStaffUser?.email || "",
    designation_id: editStaffUser?.fk_designation_id || "",
  });

  useEffect(() => {
    const StaffList = staffdata?.map((e) => {
      return {
        label: `${e.first_name} ${e.last_name}`,
        value: e.id,
        first_name: e.first_name,
        last_name: e.last_name,
        email: e.email,
      };
    }).sort((a, b) => a.label.localeCompare(b.label));
    setOptionListStaff(StaffList);
  }, [staffdata]);
  useEffect(() => {
    let staff = staffUser?.find((s) => s.id === Number(id));
    setEditStaffUser(staff);
  }, [staffUser, Number(id)]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput((values) => ({ ...values, [name]: value }));

    if (name === "email") {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailPattern.test(value)) {
        setEmailError("Please enter a valid email address.");
      } else {
        setEmailError("");
      }
    }
  };

  const hanldeStaff = (e) => {
    setInput((values) => ({
      ...values,
      staff_id: e.value,
      first_name: e.first_name,
      last_name: e.last_name,
      email: e.email,
    }));
  };

  const handleDesignation = (e) => {
    setInput((values) => ({
      ...values,
      designation_id: e.value,
    }));
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await UsersAPI.getSchoolDesignation();
      const desigOptions = res.data.designation_list.map((item) => ({
        value: item.id,
        label: item.value,
      })).sort((a, b) => a.label.localeCompare(b.label));
      setDesOptionList(desigOptions);
      setLoading(false);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        console.log("Unauthorized access 401");
        setLoading(false);
      } else {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    const body = {
      school_id: school_id,
    };
    try {
      await dispatch(addStaffUser(input));
      await dispatch(getStaffUser(body));
      setLoading(false);
      navigate("/school/user-master");
      setInput({
        school_id: user_detail?.fk_school_id,
        staff_id: "",
        first_name: "",
        last_name: "",
        email: "",
        designation_id: "",
      });
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className={id ? "tab-pane active" : "tab-pane"} id="Fees-add">
      {loading ? (
        <Loader />
      ) : (
        <div className="row clearfix">
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Basic Information</h3>
              </div>
              <form className="card-body">
                <div className="form-group row">
                  <label htmlFor="staff_id" className="col-md-3 col-form-label">
                    Select Staff<span className="text-danger">*</span>
                  </label>
                  <div className="col-md-9">
                    <Select
                      options={optionListStaff}
                      name="staff_id"
                      placeholder="Select Staff"
                      onChange={hanldeStaff}
                      value={
                        input?.staff_id
                          ? optionListStaff.find(
                              (s) => s.value === input.staff_id
                            )
                          : null
                      }
                      isSearchable={true}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    First Name <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter First name"
                      name="first_name"
                      value={input.first_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    Last Name <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Last name"
                      name="last_name"
                      value={input.last_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    Email
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-9">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Enter email"
                      value={input.email}
                      onChange={handleChange}
                      required
                    />
                    {emailError && (
                      <div className="text-danger">{emailError}</div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    Designation <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-9">
                    <Select
                      options={desOptionList}
                      name="designation_id"
                      placeholder="Select Designation"
                      onChange={handleDesignation}
                      isSearchable={false}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-9 offset-md-3">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-toggle="tab"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddUserMaster;
