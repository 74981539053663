import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

const CustomScrollbar = ({ style,  children }) => {
  // this is for perfect scroll bar this is hiden all device ( onhover it will appear )
  return (
    <PerfectScrollbar style={style} >
      {children}
    </PerfectScrollbar>
  );
};

export default CustomScrollbar;
