import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import "react-circular-progressbar/dist/styles.css";
import { FaFilePdf } from "react-icons/fa";
import useSessionRedirect from "../../Comman/SessionChange";
import { MEDIA_BASE_URL } from "../../../../Config/BaseUrl";
import { AnnualPlanAPI } from "../../../../api/TeacherDesk/AnnualPlan";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";
import { validateFile } from "../../../CommonComponent/FileValidationUtil";
import Loader from "../../../Loader/Loader";
import useLoading from "../../../CommonComponent/useLoading";

const AnnualPlaner = () => {
  // teacher desk annual planner 
  const token = localStorage.getItem("token");
  const session_id = useSessionRedirect();
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let school_id = school_user?.user_detail?.fk_school_id;
  let { school_emp_id } = school_user?.user_detail;
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [isAnnualPlan, setIsAnnualPlan] = useState([]);

  const [formValues, setFormValues] = useState({
    academic_id: session_id,
    school_id: school_id,
    document: null,
    insert_by: school_emp_id,
  });
  console.log(formValues?.document?.size, "data");
  const fileInputRef = useRef(null);

  const fetchAnnualPlan = async () => {
    let data = {
      academic_id: session_id,
      school_id: school_id,
    };
    startLoading();
    try {
      let response = await AnnualPlanAPI.getAnnualPlan(data);
      let responseData = response.data;
      if (responseData.status === 200) {
        setIsAnnualPlan(responseData.annual_planner);
      } 
    } catch (error) {
      console.error(error);
    }finally {
      stopLoading();
    }
  };

  const handleClear = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    setFormValues((values) => ({
      ...values,
      document: null,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    if (validateFile(file, fileInputRef)) {
      setFormValues((values) => ({
        ...values,
        document: file,
      }));
    }
  };
  const [validationError, setValidationError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  var maxFile = 5 * 1024 * 1024;
  const validateControl = async () => {
    let validationStatus = true;
    if (!formValues.document) {
      validationStatus = false;
      setErrorMsg("This field is required.");
    } else {
      let fileSize = formValues?.document?.size;
      if (fileSize > maxFile) {
        validationStatus = false;
        setErrorMsg("File size exceeds 5 MB.");
      }
    }
    if (!validationStatus) {
      setValidationError(true);
      setTimeout(() => {
        setValidationError(false);
        setErrorMsg("");
      }, 5000);
    }
    return validationStatus;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = await validateControl();
    if (isValid) {
      const data = new FormData();
      data.append("academic_id", formValues.academic_id);
      data.append("school_id", formValues.school_id);
      data.append("insert_by", formValues.insert_by);
      data.append("document", formValues.document);
      try {
        const response = await AnnualPlanAPI.addAnnualPlan(data);
        let responseData = response.data;
        console.log(responseData);

        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            allowOutsideClick: false,
          });
          fetchAnnualPlan();
          handleClear();
        } else if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onDelete = (id) => {
    let annual_planner_id = {
      annual_planner_id: id,
    };

    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await AnnualPlanAPI.deleteAnnualPlan(annual_planner_id);
        let responseData = response.data;
        if (responseData.status === 200) {
          fetchAnnualPlan();
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            imageAlt: "success image",
          });
        }
        if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            imageAlt: "error image",
          });
        }
      }
    });
  };

  useEffect(() => {
    fetchAnnualPlan();
  }, [session_id]);
  if (isLoading)  return <Loader />
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Annual Planer</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <form onSubmit={handleSubmit}>
                <div className="card">
                  <div className="card-body row">
                    <div className="col-md-7">
                      <div className="form-group row">
                        <label className="col-md-3 col-sm-12 col-form-label">
                          Upload File:<span className="text-danger">*</span>
                        </label>{" "}
                        <div className="col-md-9 col-sm-12">
                          <input
                            type="file"
                            className="form-control"
                            name="document"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            accept=".jpg, .jpeg, .png, .gif, .pdf"
                          />
                          {validationError &&
                            (!formValues.document ||
                              formValues.document.size > maxFile) && (
                              <ErrorDisplay errorMsg={errorMsg} />
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 text-right">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary mr-2"
                      >
                        <i className="fa fa-plus px-1"></i> Add
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-danger "
                        onClick={handleClear}
                      >
                        <i className="fa fa-times px-1"></i>
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

         
              <div className="card">
                <div className="card-body">
                  {isAnnualPlan && isAnnualPlan.length > 0 ? (
                    isAnnualPlan?.map((annual, index) => (
                      <div className="row" key={index}>
                        <div className="col-lg-10 col-md-10 col-sm-12 col-sm-order-2">
                          <object
                            data={`${MEDIA_BASE_URL}/${annual.document}`}
                            type="application/pdf"
                            width="100%"
                            height="500px"
                          ></object>
                          {/* <iframe
                            src={`https://docs.google.com/viewer?url=${MEDIA_BASE_URL}/${annual.document} &embedded=true`}
                            height="500px"
                            width="100%"
                          ></iframe> */}
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12 col-sm-order-1 text-right" >
                          <button
                            type="button"
                            className="btn btn-outline-danger mx-2"
                            data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                            onClick={() => onDelete(annual.id)}
                          >
                            <i className="fa fa-trash-o"></i>
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      style={{ height: "18rem" }}
                      className="align-center text-center"
                    >
                      <h3 className="no-list-message mt-5 ">
                        Annual Planner Not Available !
                      </h3>
                    </div>
                  )}
                </div>
              </div>
           
          </div>
        </div>
      </div>
    </>
  );
};

export default AnnualPlaner;
