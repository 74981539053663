import moment from "moment";
// all the prefirred date formats are  listed here in the order of preference


let currentDate = () => new Date().toISOString().split("T")[0];


const convertDateInMMDDYY = (data) => {
  if (data) {
    const parts = data.split("-");
    if (parts.length === 3) {
      const formattedDate = parts[2] + "-" + parts[1] + "-" + parts[0];
      return formattedDate;
    }
  }
  return data;
};
function convertDate(data) {
  const parsedDate = moment(data, "YYYY-MM-DD");
  const formattedDate = parsedDate.format("DD-MM-YYYY");
  return formattedDate;
}

function convertDate1(data) {
  const parts = data.split("-");
  const formattedDate = parts[2] + "-" + parts[1] + "-" + parts[0];
  return formattedDate;
}

function formatDate(data) {
  // "2024-01-04T10:51:00.876"
  const date = new Date(data);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
}

// function formatDate(dateString) {
//   const dateObj = new Date(dateString);
//   const day = dateObj.getDate();
//   const month = dateObj.getMonth() + 1; // add 1 because getMonth() returns a zero-based index
//   const year = dateObj.getFullYear();
//   // pad single digit numbers with a leading zero
//   const formattedDay = day.toString().padStart(2, "0");
//   const formattedMonth = month.toString().padStart(2, "0");
//   // return the formatted date string
//   return `${formattedDay}-${formattedMonth}-${year}`;
// }

// function convertTime(timestamp) {
//   const date = new Date(timestamp);
//   const hours = date.getHours();
//   const minutes = date.getMinutes();
//   const time = `${hours % 12 || 12}:${minutes < 10 ? "0" : ""}${minutes} ${
//     hours >= 12 ? "PM" : "AM"
//   }`;
//   return time;
// }
function convertDateIntoString(inputDateString) {
  // const dateObject = new Date(inputDateString);
  // const options = { day: "numeric", month: "short", year: "numeric" };
  // const formattedDateString = dateObject.toLocaleDateString("en-US", options);
  // return formattedDateString;
  const dateObject = moment(inputDateString);
  const formattedDateString = dateObject.format("DD MMM YYYY");
  return formattedDateString;
}
export { convertDateInMMDDYY, convertDate ,formatDate,currentDate,convertDateIntoString};



