import React, { useState, useEffect } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../../../actions/TabAction/TabAction";
import {
  createStaff,
  deleteStaff,
  getStaffList,
} from "../../../actions/school_user/StaffMaster";
import Loader from "../../Loader/Loader";
import { UsersAPI } from "../../../api/usersAPI";
import useSessionRedirect from "../Comman/SessionChange";
import { StaffMasterAPI } from "../../../api/Administration/StaffMaster";
import useLoading from "../../CommonComponent/useLoading";
import StaffTable from "./StaffTable";
import EmptyListMessage from "../../CommonComponent/EmptyListMessage";
import ImageUpload from "../studentRegistration/ImageUpload";
import { bloodGroupList, genderOptions } from "../../../data/optionData";

const StaffMaster = () => {
  // this file is for adding  staff master
  const token = localStorage.getItem("token");
  const session_id = useSessionRedirect();
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let school_id = school_user?.user_detail?.fk_school_id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, staffdata } = useSelector(
    (state) => state.staffMaster
  );

  const activeTab = useSelector((state) => state.activeTab);
  const [ShowImg, setShowImg] = useState("");
  const [files1, setFiles1] = useState(null);
  const [desOptionList, setDesOptionList] = useState([]);
  const { isLoading, startLoading, stopLoading } = useLoading();

  useEffect(() => {
    dispatch(setActiveTab("Staff-all"));
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(getStaffList(token, school_id));
    }
  }, [dispatch, token]);



  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      fk_school: school_id || "",
      first_name: "",
      last_name: "",
      emp_no: "",
      mobile_no: "",
      date_of_birth: "",
      email: "",
      address: "",
      adhar_no: "",
      pan_no: "",
      qualification: "",
      blood_group: "",
      fk_designation: "",
      profile: "",
      gender: "",
      login_user: false,
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required("Firstname is required"),
      last_name: Yup.string().required("Lastname is required"),
      emp_no: Yup.string().notRequired("Employee number is required"),
      // .matches(/^\d{6}$/, "Employee number should be a 6-digit number"),
      email: Yup.string()
        .required("Email is required")
        .email("Invalid email address"),
      address: Yup.string()
        .required("Address is required")
        .min(1, "Address must be at least 10 characters"),
      date_of_birth: Yup.date()
        .max(new Date(), "Future date are not allowed")
        .required("Date of birth is required"),
      mobile_no: Yup.string()
        .matches(/^[0-9]{10}$/, "Invalid mobile number")
        .required("Mobile No is required"),
      adhar_no: Yup.string()
        .matches(/^[0-9]{12}$/, "Invalid Aadhaar number")
        .notRequired("Aadhaar number is required"),
      pan_no: Yup.string()
        .matches(/^([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}$/, "Invalid PAN number")
        .notRequired("PAN number is required"),
      qualification: Yup.string().required("Qualification is required"),
      blood_group: Yup.string().required("Blood Group is required"),
      fk_designation: Yup.string().required("Designation is required"),
      gender: Yup.string().required("Gender is required"),
      // profile: Yup.mixed()
      // .required('Profile image is required')
      // .test(
      //   'fileFormat',
      //   'Unsupported file format',
      //   (value) => value && ['image/jpeg', 'image/png', 'image/gif'].includes(value.type)
      // ),
    }),

    onSubmit: async (values, { resetForm }) => {
      const data = new FormData();
      data.append("fk_school", values.fk_school);
      data.append("first_name", values.first_name);
      data.append("last_name", values.last_name);
      data.append("gender", values.gender);
      data.append("login_user", values.login_user ? "True" : "False");
      data.append("emp_no", values.emp_no);
      data.append("mobile_no", values.mobile_no);
      data.append("date_of_birth", values.date_of_birth);
      data.append("email", values.email);
      data.append("address", values.address);
      data.append("fk_designation", values.fk_designation);
      data.append("adhar_no", values.adhar_no);
      data.append("pan_no", values.pan_no);
      data.append("qualification", values.qualification);
      data.append("blood_group", values.blood_group);
      data.append("profile", values.profile);
      const response = await dispatch(createStaff(token, data));
      if (response.status === 200) {
        Swal.fire({
          text: response.msg,
          icon: "success",
          imageAlt: "success image",
          allowOutsideClick: false,
        });
        setShowImg("")
        resetForm();
        await dispatch(getStaffList(token, school_id));
        await dispatch(setActiveTab("Staff-all"));
      }
      if (response.status === 403) {
        Swal.fire({
          text: response.msg,
          icon: "error",
          imageAlt: "error image",
          allowOutsideClick: false,
        });
      }
    },
  });

  const formClear = () => {
    validation.resetForm();
  };


 

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteStaff(token, id))
          .then(() => {
            dispatch(getStaffList(token, school_id));
          })
          .catch((err) => console.error(err));
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await UsersAPI.getSchoolDesignation();
        const desigOptions = res.data.designation_list
          .map((item) => ({
            value: item.id,
            label: item.value,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setDesOptionList(desigOptions);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  let handleCancel = (event) => {
    event.preventDefault();
    dispatch(setActiveTab("Staff-all"));
  };

  let handleResetPassword = async (id) => {
    let staff_id = {
      staff_id: id,
    };
    Swal.fire({
      title: "CONFIRMATION",
      text: "Do you want to Reset the Password ?",
      icon: "question",
      showDenyButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes",
      denyButtonText: `No`,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        startLoading();
        try {
          const response = await StaffMasterAPI.resetPassword(staff_id);
          const responseData = response.data;
          if (responseData.status === 200) {
            Swal.fire({
              text: responseData.msg,
              icon: "success",
              allowOutsideClick: false,
            });
          } else if (responseData.status === 500) {
            Swal.fire({
              text: responseData.msg,
              icon: "warning",
              allowOutsideClick: false,
            });
          } else if (responseData.status === 403) {
            Swal.fire({
              text: responseData.msg,
              icon: "error",
              allowOutsideClick: false,
            });
          }
        } catch (error) {
          console.error(error);
        } finally {
          stopLoading();
        }
      } else if (result.isDenied) {
        stopLoading();
      }
    });
  };
  if (isLoading || loading) return <Loader />;
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Staff Master</h1>
            </div>

            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeTab?.activeTab === "Staff-all" ? "active" : ""
                  }`}
                  data-toggle="tab"
                  href="#Staff-all"
                >
                  Staff List
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeTab?.activeTab === "Staff-add" ? "active" : ""
                  }`}
                  data-toggle="tab"
                  href="#Staff-add"
                  onClick={() => dispatch(setActiveTab("Staff-add"))}
                >
                  Add Staff
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="tab-content">
            <div
              className={`tab-pane ${
                activeTab?.activeTab === "Staff-all" ? "active" : ""
              }`}
              id="Staff-all"
            >
              {staffdata &&
              staffdata.payload &&
              staffdata.payload.length > 0 ? (
                <StaffTable
                  data={staffdata.payload}
                  onDelete={onDelete}
                  handleResetPassword={handleResetPassword}
                />
              ) : (
                <EmptyListMessage
                  message={"Staff List Not Available !"}
                  Div="div"
                />
              )}

              {/* <div className="table-responsive card">
                <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
                  <thead>
                    <tr className="text-center">
                      <th>Sr.No.</th>
                      <th>logo</th>
                      <th>Emp_No</th>
                      <th>Name</th>
                      <th>Contact No.</th>
                      <th>Email</th>
                      <th>Qualification</th>
                      <th>User Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {staffdata?.payload?.map((school, index) => (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td className="w60">
                          {school.profile ? (
                            <img
                              className="avatar"
                              src={school.profile}
                              alt="logo"
                            />
                          ) : (
                            <img alt="logo" src={pic1} className="avatar" />
                          )}
                        </td>
                        <td>{school.emp_no}</td>
                        <td>
                          <span className="font-16">{`${school.first_name} ${school.last_name}`}</span>
                        </td>
                        <td>{school.mobile_no}</td>
                        <td>{school.email}</td>
                        <td className="text-center">{school.qualification}</td>
                        <td className="text-center">{school.user_type}</td>

                        <td className="d-flex justify-content-between align-items-center">
                          <NavLink
                            to="#"
                            className="card-options-remove"
                            data-toggle="card-remove"
                            onClick={() => onDelete(school.id)}
                          >
                            <i className="fa fa-trash-o text-danger"></i>
                          </NavLink>
                          <NavLink
                            className="text-muted"
                            to={`/school/staff-master/${school.id}`}
                          >
                            <i className="fa fa-edit"></i>
                          </NavLink>
                          {school.user_type === "User" && (
                            <NavLink
                              className=""
                              onClick={() => handleResetPassword(school.id)}
                            >
                              <img src={img} alt="Edit" width={16} />
                            </NavLink>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div> */}
            </div>

            <div
              className={`tab-pane ${
                activeTab?.activeTab === "Staff-add" ? "active" : ""
              }`}
              id="Staff-add"
            >
              <div className="row clearfix">
                <div className="col-lg-12 col-lg-12 col-sm-12">
                  <div className="card">
                    <form
                      className="card-body"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-9">
                          <div className="form-group row">
                            <label className="col-lg-2 col-form-label ">
                              First Name<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className={`form-control input-height ${
                                  validation.touched.first_name &&
                                  validation.errors.first_name
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter First Name"
                                name="first_name"
                                value={validation.values.first_name}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                autoComplete="off"
                              />
                              {validation.touched.first_name &&
                              validation.errors.first_name ? (
                                <div className="invalid-feedback">
                                  {validation.errors.first_name}
                                </div>
                              ) : null}
                            </div>
                            <label className="col-lg-2 col-form-label ">
                              Last Name<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className={`form-control input-height ${
                                  validation.touched.last_name &&
                                  validation.errors.last_name
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Last Name"
                                name="last_name"
                                value={validation.values.last_name}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              />
                              {validation.touched.last_name &&
                              validation.errors.last_name ? (
                                <div className="invalid-feedback">
                                  {validation.errors.last_name}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-2 col-form-label">
                              Employee No<span className="text-danger"></span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className={`form-control input-height ${
                                  validation.touched.emp_no &&
                                  validation.errors.emp_no
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Employee Number"
                                name="emp_no"
                                value={validation.values.emp_no}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                               
                              />
                              {validation.touched.emp_no &&
                              validation.errors.emp_no ? (
                                <div className="invalid-feedback">
                                  {validation.errors.emp_no}
                                </div>
                              ) : null}
                            </div>
                            <label className="col-lg-2 col-form-label">
                              Mobile No <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className={`form-control input-height ${
                                  validation.touched.mobile_no &&
                                  validation.errors.mobile_no
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Mobile Number"
                                name="mobile_no"
                                value={validation.values.mobile_no}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    /^\d*$/.test(inputValue) &&
                                    inputValue.length <= 10
                                  ) {
                                    validation.handleChange(e);
                                  }
                                }}
                                onBlur={validation.handleBlur}
                              />
                              {validation.touched.mobile_no &&
                              validation.errors.mobile_no ? (
                                <div className="invalid-feedback">
                                  {validation.errors.mobile_no}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-2 col-form-label">
                              DOB<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="date"
                                className={`form-control input-height ${
                                  validation.touched.date_of_birth &&
                                  validation.errors.date_of_birth
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="date_of_birth"
                                value={validation.values.date_of_birth}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              />
                              {validation.touched.date_of_birth &&
                              validation.errors.date_of_birth ? (
                                <div className="error-message">
                                  {validation.errors.date_of_birth}
                                </div>
                              ) : null}
                            </div>
                            <label className="col-lg-2 col-form-label">
                              Gender<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <Select
                                name="gender"
                                options={genderOptions}
                                placeholder="Select Gender"
                                value={genderOptions?.find(
                                  (option) =>
                                    option.value === validation.values.gender
                                )}
                                onChange={(selectedOption) =>
                                  validation.setFieldValue(
                                    "gender",
                                    selectedOption?.value
                                  )
                                }
                                onBlur={validation.handleBlur}
                                className={`${
                                  validation.touched.gender &&
                                  validation.errors.gender
                                    ? "border-color"
                                    : ""
                                }`}
                                isSearchable={true}
                              />
                              {validation.touched.gender &&
                                validation.errors.gender && (
                                  <div className="error-message">
                                    {validation.errors.gender}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-2 col-form-label ">
                              Email<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-10">
                              <input
                                type="email"
                                className={`form-control input-height ${
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Email"
                                name="email"
                                value={validation.values.email}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <div className="invalid-feedback">
                                  {validation.errors.email}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-lg-2 col-form-label">
                              Qualification
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className={`form-control input-height ${
                                  validation.touched.qualification &&
                                  validation.errors.qualification
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Qualification"
                                name="qualification"
                                value={validation.values.qualification}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              />
                              {validation.touched.qualification &&
                              validation.errors.qualification ? (
                                <div className="invalid-feedback">
                                  {validation.errors.qualification}
                                </div>
                              ) : null}
                            </div>
                            <label className="col-lg-2 col-form-label">
                              Blood Group<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <Select
                                name="status"
                                options={bloodGroupList}
                                placeholder="Select Blood Group "
                                onChange={(option) =>
                                  validation.setFieldValue(
                                    "blood_group",
                                    option.label
                                  )
                                }
                                onBlur={validation.handleBlur("blood_group")}
                                value={
                                  validation.values.blood_group
                                    ? bloodGroupList.find(
                                        (option) =>
                                          option.label ===
                                          validation.values.blood_group
                                      )
                                    : null
                                }
                                isSearchable={true}
                                className={`${
                                  validation.touched.blood_group &&
                                  validation.errors.blood_group
                                    ? "border-color"
                                    : ""
                                }`}
                              />
                              {validation.touched.blood_group &&
                              validation.errors.blood_group ? (
                                <div className="error-message">
                                  {validation.errors.blood_group}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-lg-2 col-form-label">
                              Aadhaar No<span className="text-danger"></span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className={`form-control input-height ${
                                  validation.touched.adhar_no &&
                                  validation.errors.adhar_no
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Aadhaar Number"
                                name="adhar_no"
                                value={validation.values.adhar_no}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    /^\d*$/.test(inputValue) &&
                                    inputValue.length <= 12
                                  ) {
                                    validation.handleChange(e);
                                  }
                                }}
                                onBlur={validation.handleBlur}
                                autoComplete="off"
                                maxLength={12}
                              />
                              {validation.touched.adhar_no &&
                              validation.errors.adhar_no ? (
                                <div className="invalid-feedback">
                                  {validation.errors.adhar_no}
                                </div>
                              ) : null}
                            </div>
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustomUsername"
                            >
                              PAN No<span className="text-danger"></span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className={`form-control input-height ${
                                  validation.touched.pan_no &&
                                  validation.errors.pan_no
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter PAN number"
                                name="pan_no"
                                value={validation.values.pan_no.toUpperCase()}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                autoComplete="off"
                                maxLength={10}
                              />
                              {validation.touched.pan_no &&
                              validation.errors.pan_no ? (
                                <div className="invalid-feedback">
                                  {validation.errors.pan_no}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-2 col-form-label">
                              Designation <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-10">
                              <Select
                                options={desOptionList}
                                name="fk_designation"
                                placeholder="Select Designation"
                                onChange={(option) =>
                                  validation.setFieldValue(
                                    "fk_designation",
                                    option.value
                                  )
                                }
                                value={
                                  validation.values.fk_designation
                                    ? desOptionList.find(
                                        (option) =>
                                          option.value ===
                                          validation.values.fk_designation
                                      )
                                    : null
                                }
                                isSearchable={true}
                                className={`${
                                  validation.touched.fk_designation &&
                                  validation.errors.fk_designation
                                    ? "border-color"
                                    : ""
                                }`}
                              />
                              {validation.touched.fk_designation &&
                              validation.errors.fk_designation ? (
                                <div className="error-message">
                                  {validation.errors.fk_designation}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-2 col-form-label">
                              Address<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-10">
                              <input
                                type="text"
                                className={`form-control input-height ${
                                  validation.touched.address &&
                                  validation.errors.address
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="address"
                                placeholder="Enter Address"
                                value={validation.values.address}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              />
                              {validation.touched.address &&
                              validation.errors.address ? (
                                <div className="invalid-feedback">
                                  {validation.errors.address}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">
                              Staff As Login User{" "}
                              <span className="text-danger"></span>
                            </label>

                            <div className="col-lg-9 d-flex  align-items-center">
                              <label className="custom-switch pr-2">
                                <input
                                  type="checkbox"
                                  className="custom-switch-input"
                                  name="login_user"
                                  checked={validation.values.login_user}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                />
                                <span className="custom-switch-indicator"></span>
                              </label>
                              {validation.values.login_user ? (
                                <b>Yes</b>
                              ) : (
                                <b>No</b>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <ImageUpload
                            formik={validation}
                            fieldName="profile"
                            showImg={ShowImg}
                            setShowImg={setShowImg}
                          />
                          {/* <div className="d-flex justify-content-center mb-5">
                            {ShowImg ? (
                              <img
                                alt="Cropped Img"
                                src={ShowImg}
                                className="crop_img"
                                width={100}
                              />
                            ) : (
                              <img
                                width={100}
                                src={pic1}
                                className="crop_img"
                                alt="..."
                              />
                            )}
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-10 mx-auto">
                              <input
                                style={{ width: "100%" }}
                                type="file"
                                className="dropify mx-auto"
                                name="profile"
                                accept="image/*"
                                onChange={handleFileRead}
                              />
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="col-sm-12 justify-content-center align-item-center my-3">
                        <button
                          type="submit"
                          className="mr-1 btn btn-primary btn-sm"
                        >
                          {" "}
                          <i className="fa fa-plus pr-1"></i>
                          Save
                        </button>
                        <button
                          onClick={handleCancel}
                          className="btn btn-outline-danger btn-sm"
                        >
                          <i className="fa fa-times pr-1"></i>
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StaffMaster;

// validationSchema: Yup.object().shape({
//   first_name: Yup.string()
//     .required("First name is required")
//     .min(2, "First name must be at least 2 characters")
//     .max(50, "First name can't exceed 50 characters"),
//   last_name: Yup.string()
//     .required("Last name is required")
//     .min(2, "Last name must be at least 2 characters")
//     .max(50, "Last name can't exceed 50 characters"),
//   emp_no: Yup.string()
//     .required("Employee number is required")
//     .matches(/^\d{6}$/, "Employee number should be a 6-digit number"),
//   mobile_no: Yup.string()
//     .required("Mobile number is required")
//     .matches(/^\d{10}$/, "Mobile number should be a 10-digit number"),
//   date_of_birth: Yup.date()
//     .required("Date of birth is required")
//     .max(new Date(), "Date of birth cannot be in the future"),
//   email: Yup.string()
//     .required("Email is required")
//     .email("Invalid email address"),
//   address: Yup.string()
//     .required("Address is required")
//     .min(10, "Address must be at least 10 characters"),
//   adhar_no: Yup.string()
//     .matches(/^\d{12}$/, "Aadhar number should be a 12-digit number"),
//   pan_no: Yup.string()
//     .matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, "Invalid PAN number"),
//   qualification: Yup.string(),
//   blood_group: Yup.string(),
//   fk_designation: Yup.string().required("Designation is required"),
// })
