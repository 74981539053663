import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import Switch from "react-switch";
import useLocalStorageData from "../../Comman/LocalStorageData";
import useSessionRedirect from "../../Comman/SessionChange";
import useLoading from "../../../CommonComponent/useLoading";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loader from "../../../Loader/Loader";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";
import { ExamsAPI } from "../../../../api/Examination/Exam";
import ExamTable from "./ExamTable";
import {
  ClearButton,
  SaveButton,
} from "../../../CommonComponent/ReusableButton";

const ExamButton = ({ isEdit, onClick }) => {
  // this code is for exam generation
  return (
    <div className="mt-4 text-right mb-4">
      <SaveButton isEdit={isEdit} className={"mx-1"} />
      <ClearButton onClick={onClick} className={"mx-1"} />
    </div>
  );
};

const Exam = () => {
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const session_id = useSessionRedirect();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [isEdit, setIsEdit] = useState(false);
  const [isExam, setIsExam] = useState([]);
  const [isAssessment, setIsAssessment] = useState([]);
  const [assessmentData, setAssessmentData] = useState([]);
  const [examData, setExamData] = useState([]);

  let examOption = examData?.map((c) => {
    return {
      label: `${c.exam_name} `,
      value: c.id,
    };
  });

  const fetchExamsData = async () => {
    startLoading();
    try {
      let body = {
        school_id: school_id,
        academic_id: session_id,
      };
      let response = await ExamsAPI.getExam(body);
      let responseData = response.data;
      if (responseData.status === 200) {
        setExamData(responseData.exam);
      }
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };
  const fetchAssessmentData = async () => {
    startLoading();
    try {
      let body = {
        school_id: school_id,
        academic_id: session_id,
      };
      let response = await ExamsAPI.getAssessment(body);
      let responseData = response.data;
      if (responseData.status === 200) {
        setAssessmentData(responseData.assessment);
      }
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };
  useEffect(() => {
    fetchExamsData();
    fetchAssessmentData();
  }, [session_id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      academic_id: session_id,
      school_id: school_id,
      insert_by: school_emp_id,
      exam_id: isExam?.id ?? "",
      exam_name: isExam?.exam_name ?? "",
      appear_for_grade: isExam?.appear_for_grade ?? false,
    },
    validationSchema: Yup.object().shape({
      exam_name: Yup.string().required("This field is required."),
    }),
    onSubmit: async (values) => {
      try {
        const data = new FormData();
        data.append("academic_id", values.academic_id);
        data.append("school_id", values.school_id);
        data.append("insert_by", values.insert_by);
        data.append("exam_id", values.exam_id);
        data.append("exam_name", values.exam_name);
        data.append(
          "appear_for_grade",
          values.appear_for_grade ? "True" : "False"
        );
        const response = await ExamsAPI.createExam(data);
        let responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            allowOutsideClick: false,
          });
          fetchExamsData();
          handleClear();
        } else if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  });
  const assFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      academic_id: session_id,
      school_id: school_id,
      insert_by: school_emp_id,
      assessment_id: isAssessment.id ?? "",
      assessment: isAssessment.assessment ?? "",
      exam_id: isAssessment.fk_exam ?? "",
    },
    validationSchema: Yup.object().shape({
      assessment: Yup.string().required("This field is required."),
      exam_id: Yup.string().required("Exam is required."),
    }),
    onSubmit: async (values) => {
      try {
        const data = new FormData();
        data.append("academic_id", values.academic_id);
        data.append("school_id", values.school_id);
        data.append("insert_by", values.insert_by);
        data.append("exam_id", values.exam_id);
        data.append("assessment_id", values.assessment_id);
        data.append("assessment", values.assessment);
        const response = await ExamsAPI.createAssessment(data);
        let responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            allowOutsideClick: false,
          });
          fetchAssessmentData();
          handleClear();
        } else if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const handleClear = () => {
    formik.resetForm();
    assFormik.resetForm();
    setIsEdit(false);
    setIsExam([]);
    setIsAssessment([]);
  };
  const onEdit = (exam) => {
    setIsExam(exam);
    setIsEdit(true);
  };
  const onEditAssessment = (exam) => {
    setIsAssessment(exam);
    setIsEdit(true);
  };
  const onDelete = (id) => {
    let delete_id = {
      exam_id: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await ExamsAPI.deleteExam(delete_id);
        let responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            imageAlt: "success image",
          });
          fetchExamsData();
          handleClear();
        }
        if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            imageAlt: "error image",
          });
        }
      }
    });
  };
  const onDeleteAssessment = (id) => {
    let delete_id = {
      assessment_id: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await ExamsAPI.deleteAssessment(delete_id);
        let responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            imageAlt: "success image",
          });
          fetchAssessmentData();
          handleClear();
        }
        if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            imageAlt: "error image",
          });
        }
      }
    });
  };

  return (
    <div className="section-body">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center ">
          <div className="header-action">
            <h1 className="page-title">Exams</h1>
          </div>
          <div className=" mt-2"></div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="card">
            <div className="card-body">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#Exam"
                    onClick={() => handleClear()}
                  >
                    Exam
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#Assessment"
                    onClick={() => handleClear()}
                  >
                    Assessment
                  </a>
                </li>
              </ul>
              <div className="tab-content mt-4">
                <div className="tab-pane active" id="Exam">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            formik.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="form-group row">
                            <label className="col-md-4 col-form-label">
                              Exam Name
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-8">
                              <input
                                type="text"
                                className={`form-control`}
                                name="exam_name"
                                placeholder="Enter Exam Name"
                                value={formik.values.exam_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="off"
                              />
                              {formik.touched.exam_name &&
                              formik.errors.exam_name ? (
                                <ErrorDisplay
                                  errorMsg={formik.errors.exam_name}
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group row ">
                            <label className="col-md-4 col-form-label">
                              Appear For Grade Card
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-8 d-flex  align-items-center">
                              <Switch
                                onChange={(checked) =>
                                  formik.setFieldValue(
                                    "appear_for_grade",
                                    checked
                                  )
                                }
                                checked={formik.values.appear_for_grade}
                                onColor="#42a5f5"
                                offColor="#888"
                                className="react-switch"
                                handleDiameter={24}
                                // borderRadius={6}
                                // checkedIcon={<span className="switch-label">Yes</span>}
                                // uncheckedIcon={<span className="switch-label">No</span>}
                                checkedIcon={
                                  <div className="switch-label">
                                    <span className="switch-text">Yes</span>
                                  </div>
                                }
                                uncheckedIcon={
                                  <div className="switch-label">
                                    <span className="switch-text">No</span>
                                  </div>
                                }
                              />
                            </div>
                          </div>
                          <ExamButton
                            isEdit={isEdit}
                            onClick={() => handleClear()}
                          />
                        </form>
                      </div>
                      <div className="table-responsive col-lg-6 col-md-12 col-sm-12">
                        <ExamTable
                          data={examData}
                          headers={["Exam Name", "Appear For Grade"]}
                          onDelete={onDelete}
                          onEdit={onEdit}
                          tableName={"Exam"}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="tab-pane" id="Assessment">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            assFormik.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="form-group row">
                            <label className="col-md-3 col-form-label">
                              Assessment
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <input
                                type="text"
                                className="form-control"
                                name="assessment"
                                placeholder="Enter Assessment Name"
                                value={assFormik.values.assessment}
                                onChange={assFormik.handleChange}
                                onBlur={assFormik.handleBlur}
                                autoComplete="off"
                              />
                              {assFormik.touched.assessment &&
                              assFormik.errors.assessment ? (
                                <ErrorDisplay
                                  errorMsg={assFormik.errors.assessment}
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 col-form-label">
                              Exam <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <Select
                                name="exam_id"
                                placeholder="Select Exam"
                                options={examOption}
                                value={
                                  assFormik.values.exam_id
                                    ? examOption.find(
                                        (e) =>
                                          e.value ===
                                          Number(assFormik.values.exam_id)
                                      )
                                    : null
                                }
                                onChange={(select) => {
                                  assFormik.setFieldValue(
                                    "exam_id",
                                    select.value
                                  );
                                }}
                                onBlur={assFormik.handleBlur}
                                isSearchable={true}
                              />
                              {assFormik.touched.exam_id &&
                              assFormik.errors.exam_id ? (
                                <ErrorDisplay
                                  errorMsg={assFormik.errors.exam_id}
                                />
                              ) : null}
                            </div>
                          </div>
                          <ExamButton
                            isEdit={isEdit}
                            onClick={() => handleClear()}
                          />
                        </form>
                      </div>
                      <div className="table-responsive col-lg-6 col-md-12 col-sm-12">
                        <ExamTable
                          data={assessmentData}
                          headers={["Assessment Name", "Exam Name"]}
                          onDelete={onDeleteAssessment}
                          onEdit={onEditAssessment}
                          tableName={"Assessment"}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Exam;
