import React from "react";

const Heading = ({ pageTitle, children }) => {
  // this is the heading tag used in all the pages where user see the heading 
  return (
    <div className="d-flex justify-content-between align-items-center ">
      <div className="header-action">
        <h1 className="page-title">{pageTitle}</h1>
      </div>
      <div className=" mt-2">{children}</div>
    </div>
  );
};
const HeadingBreadcrumb = ({ pageTitle, children }) => {
  return (
    <div className="d-flex justify-content-between align-items-center ">
      <div className="header-action">
        <h1 className="page-title">{pageTitle}</h1>
      </div>
      <ul className="nav nav-tabs page-header-tab">{children} </ul>
    </div>
  );
};

export { Heading, HeadingBreadcrumb };
