import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import {SmallSelectField as SelectField} from "../../CommonComponent/SelectField";
import BarChart1 from "./BarChart";
import Event from "./Event";
import HolidayCarousel from "./Holiday";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "../../../actions/Dashboard/Dashboard";
import useLocalStorageData from "../Comman/LocalStorageData";
import useLoading from "../../CommonComponent/useLoading";
import { SessionContext } from "../../../context/sessionContext";
import { useClass } from "../../../context/attendanceContext";
import EmptyListMessage from "../../CommonComponent/EmptyListMessage";
import { DayList } from "../../../data/optionData";
import { SmallSelectField as SelectField1 } from "../../CommonComponent/SelectField1";
import {
  Notice,
  PresidantMessage,
  StudentBirthdayList,
  TestScheduleList,
  TopPerformers,
  Slideshow
} from "./CustomCard";
import Loader from "../../Loader/Loader";
import TimeTableList from "./TimeTableList";
import { NavLink } from "react-router-dom";

const TeacherDashboard = () => {
// this code is for Teacher Dashboard Where all the components are added here
  const { school_id, token, school_emp_id, schoolUser } = useLocalStorageData();
  const { user_type, name, user_detail } = schoolUser;
  const { user_type_dashboard, staff_id } = user_detail;
  // const session_id = useSessionRedirect();
  const { Session } = useContext(SessionContext);
  const session_id = Session || sessionStorage.getItem("SessionId");
  const { isLoading, startLoading, stopLoading } = useLoading();
  const { dashboard, loading } = useSelector((state) => state.Dashboard);
  const dispatch = useDispatch();
  const { allClassList, classListOption } = useClass();
  const [selectClass, setSelectClass] = useState(null);
  const [selectClassSection, setSelectClassSection] = useState(null);
  const [selectDay, setSelectDay] = useState("Monday");
  const [selectedTeacher, setSelectedTeacher] = useState("");
 
  const {
    student_count,
    other_staffcount,
    teacher_count,
    holiday,
    staff_birthday,
    fees_summary_list,
    attendance_list,
    birth_daylist,
    upcomming_list,
    time_tabtable_list,
    circulars,
    eventactivity,
    top_performance,
    banner_images,
    allpresent_count,
    allabsent_count,
    school_count,
    addmitated_student,
    organization_count,
    staff_count,
    school_profile_list,
    president_message,
    president_image,
    president_introduction,
    school_banner,
  } = dashboard?.payload || [];

  useEffect(() => {
    let body = {
      school_id: school_id,
      academic_id: session_id,
      class_id: selectClass,
      section_id: selectClassSection,
      staff_id: staff_id,
      day: selectDay,
      user_type: user_type_dashboard,
    };
    dispatch(getDashboardData(body));
  }, [
    school_id,
    session_id,
    user_type_dashboard,
    selectClass,
    selectClassSection,
    staff_id,
    selectDay,
  ]);

console.log(attendance_list,"attendance_list")
  
//this code is for attendance
let feeSummary = attendance_list?.reduce(
  (acc, current) => {
    acc.present.push(current.present);
    acc.absent.push(current.absent);
    acc.attendance_date.push(current.attendance_date);
    acc.is_holiday.push(current.is_holiday);

   
    return acc;
  },
  { present: [], absent: [], attendance_date: []  , is_holiday: [] }
);

//this class is for attendance
  useEffect(() => {
    if (classListOption?.length > 0) {
      setSelectClassSection(
        selectClassSection ? selectClassSection : classListOption[0].value
      );
    }
    if (allClassList?.length > 0) {
      setSelectClass(selectClass ? selectClass : allClassList[0].value);
    }
  }, [classListOption, allClassList]);

  //   if (loading) return <Loader />;
  return (
    <div className="section-body">
      <div className="container-fluid">
        <div className="row mt-2">
        <div className="col-lg-4  col-sm-12">
            <ul className="metismenu">
              <li>
                <NavLink
                  to="/school/student-attendance"
                  style={{ fontWeight: "bold" }}
                >
                  <i className={`text-info fa fa-users`}></i>
                  Mark Attendance
                  <hr className="m-1" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/school/home-work" style={{ fontWeight: "bold" }}>
                  <i className={`text-info fa fa-book`}></i>
                  Home Work
                  <hr className="m-1" />
                </NavLink>
              </li>
            </ul>
            <Card >
              <CardBody>
             <div className="form-group">
              <Event eventactivity={eventactivity} />
              </div>
                <h3 className="card-title">
                  Today's Birthday
                  <i className="fa fa-user-circle-o px-2"></i>(Student's)
                  <hr className="m-1" />
                </h3>

                <StudentBirthdayList data={birth_daylist} />

                <h5 className="card-title">
                  <i className="fa fa-bullhorn"></i> upcoming Holiday
                  <hr className="m-1" />
                </h5>
                {holiday && holiday.length > 0 ? (
                  <HolidayCarousel holiday={holiday} />
                ) : (
                  <EmptyListMessage
                    message={"Holiday Not Available !"}
                    Div="div"
                    size={"1.2rem"}
                  />
                )}

              
              </CardBody>
            </Card>
          </div>
          <div className="col-lg-8  col-sm-12">
            <Card>
              <CardBody>
                <>
                  <Row className="mb-2">
                    <Col lg={8} md={6} sm={6}>
                      <h3 className="card-title"> Attendance</h3>
                    </Col>
                    <Col lg={4} md={6} sm={6} className="dash-select">
                      <SelectField1
                        placeholder={"Select Class"}
                        options={classListOption}
                        value={selectClassSection}
                        onChange={(select) =>
                          setSelectClassSection(select.value)
                        }
                      />
                    </Col>
                  </Row>
                  <div>
                    <BarChart1
                      dataColors='["#59e0c5","#ffcb80" ,"#808080"]'
                      balance={feeSummary?.absent}
                      paid={feeSummary?.present}
                      heads_name={feeSummary?.attendance_date}
                      is_holiday={feeSummary?.is_holiday || []}
                      chartFor={"Attendance"}
                    />
                  </div>
                </>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>Notice</CardTitle>
                <Notice circulars={circulars} />
              </CardBody>
            </Card>
          </div>
          
        </div>
        <Row>
        <Col lg={6} >  
            <Card style={{minHeight:"96%"}}>
              <CardBody>
                <CardTitle>President Message</CardTitle>
                <PresidantMessage
                  presidentData={{
                    president_message,
                    president_image,
                    president_introduction,
                  }}
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg={6} >
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h3 className="card-title"> Top Performers </h3>
                  <div className="dash-select">
                    <SelectField1
                      placeholder={"Select Class"}
                      options={allClassList}
                      value={selectClass}
                      onChange={(select) => setSelectClass(select.value)}
                    />
                  </div>
                </div>
                <TopPerformers top_performance={top_performance} />
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <CardTitle>Upcoming Online Test</CardTitle>
                <TestScheduleList data={upcomming_list} />
              </CardBody>
            </Card>
          </Col>
         
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Card>
              <CardBody>
                <Row className="mb-2">
                  <Col lg={6} md={6} sm={6}>
                    <h3 className="card-title"> Time-Table Summary </h3>
                  </Col>
                  <Col lg={3} md={3} sm={6}></Col>
                  <Col lg={3} md={3} sm={6} className="dash-select">
                    {" "}
                    <SelectField1
                      placeholder={"Select Day"}
                      options={DayList}
                      value={selectDay}
                      onChange={(select) => setSelectDay(select.value)}
                    />{" "}
                  </Col>
                </Row>
                <TimeTableList time_tabtable_list={time_tabtable_list} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {school_banner && school_banner.length > 0 && (
         <Row>
         <Col lg={12} md={12} sm={12}>
           <div >
             <div className="hh">
               <Slideshow images={school_banner} />
             </div>
           </div>
         </Col>
       </Row>
      )}
      </div>
    </div>
  );
};

export default TeacherDashboard;
