import React from "react";

const Footer = ({ nonAuth }) => {
  // all the pages has this footer 
  return (
    <>
      <div className="section-body">
        <footer className="footer" style={{ color: nonAuth ? "white" : "" }}>
          <hr />
          <div className="Container-fluid ">
            <div className="row forRight ">
              <div className="  justify-content-sm-center">
                <p className="ml-3">Copyright © 2023 - 2024</p>
              </div>
              <div className="mr-3">
                <span className="text-capitalize">
                  For Any Technical Issue Contact us on
                </span>{" "}
                (<i className="fa fa-phone mx-1" aria-hidden="true"></i>
                +917893171408{" "}
                <i className="fa fa-envelope mx-1" aria-hidden="true"></i>
                info@campuslink.pro)
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
