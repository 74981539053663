import React, { useEffect, useState } from "react";
import Profile from "../../../Logo/Profile.png";
import pic1 from "../../../assets/images/dummy.png";
import useSessionRedirect from "../Comman/SessionChange";
import useLocalStorageData from "../Comman/LocalStorageData";
import StudentsListAPI from "../../../api/Admission/StudentListAPI";
import { convertDateInMMDDYY } from "../../CommonComponent/DateFormatUtil";
import { MEDIA_BASE_URL } from "../../../Config/BaseUrl";
import { FaFilePdf } from "react-icons/fa";
import FeeDetailsTable from "./FeeDetailsTable";
import { Navigate, redirect, useNavigate, useParams } from "react-router-dom";
import useLoading from "../../CommonComponent/useLoading";
import Loader from "../../Loader/Loader";
import GradeCardPrint from "../Examination/GradeCard/GradeCardPrint";
import EmptyListMessage from "../../CommonComponent/EmptyListMessage";
import { useLocation } from "react-router-dom";
const StudentProfile = () => {
  // this is the student profile where all the details are mentioned
    // "location": "studentProfile" in header of school dashboard "dropdwon"

  const { school_id, token, schoolUser } = useLocalStorageData();  
  const { isLoading, startLoading, stopLoading } = useLoading(); 
  const session_id = useSessionRedirect();
  // let { sid } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { studentId } = location.state || { studentId: null };

  if(!studentId){
    navigate("/school/dashboard")
  }
  let sid = studentId;
  console.log(sid)
  const { id, user_type, name, user_detail } = schoolUser;
  const {
    school_name,
    organization_name,
    school_address,
    school_contact_email,
    school_contact_number,
    school_pincode,
    udise_no,
    school_logo,
    school_emp_id,
  } = user_detail;

  const [studentProfile, setStudentProfile] = useState(null);
  const {
    profile,
    std_name,
    class_name,
    session,
    concession,
    status,
    student_code,
  } = studentProfile?.student_info || {};
  const {
    registration_date,
    dob,
    gender,
    nationality,
    religion,
    category,
    caste,
    adhar_no,
    sub_caste,
    application_no,
    mother_tongue,
    blood_group,
    birth_place,
    country,
    state,
    addimision_date,
  } = studentProfile?.personal_details || {};
  const { address, fk_state, fk_city, contact_no, email, district } =
    studentProfile?.contact_details || {};
  const {
    father_name,
    father_profile,
    f_res_address,
    f_office_address,
    f_education,
    f_contact_no,
    f_email,
    f_occupation,
    f_annual_income,
    f_adhar_no,
    f_pan_no,
    mother_name,
    mother_profile,
    m_res_address,
    m_office_address,
    m_education,
    m_contact_no,
    m_email,
    m_occupation,
    m_annual_income,
    m_adhar_no,
    m_pan_no,
    apar_id,
    mark_of_identity,
    child_id,
  } = studentProfile?.parent_details || {};

  const {
    total_fee,
    paid_fee,
    balance_fee,
    fee_receipt,
    totalpaid_specialfee,
    special_feedetail,
  } = studentProfile?.payment_details || {};
  // const { rank } = studentProfile?.examInfo_list[0] || {};

  useEffect(() => {
    const fetchData = async () => {
      let body = {
        student_id: Number(sid),
      };
      startLoading();
      try {
        const response = await StudentsListAPI.getStudentProfile(body);
        setStudentProfile(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        stopLoading();
      }
    };
    fetchData();
  }, [sid]);
  const [modal, setModal] = useState(false);
  const [feeType, setFeeType] = useState("");
  const [currentFeeData, setCurrentFeeData] = useState([]);
  console.log(currentFeeData)
// useEffect(()=>{
//   if(!currentFeeData){
//     setCurrentFeeData([])
//   }
// },[])
  const toggle = (type, data) => {
    setFeeType(type);
    setCurrentFeeData(data);
    setModal(!modal);
  };

  if (isLoading) return <Loader />;
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Student Profile</h1>
              <ol className="breadcrumb page-breadcrumb"> 
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="tab-pane active" id="Personal-detail">
              <div className="row clearfix">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title text-center">{school_name}</h3>
                      <hr />
                      <div className="row">
                        <div className="col-md-4 col-sm-12">
                          <div className="d-flex justify-content-center ">
                            {profile !== "NA" ? (
                              <img
                                width={100}
                                src={`${MEDIA_BASE_URL}${profile}`}
                                className="crop_img"
                                alt="..."
                              />
                            ) : (
                              <img
                                width={100}
                                src={pic1}
                                className="crop_img"
                                alt="..."
                                style={{
                               
                                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)"
                                }}
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-md-8 col-sm-12">
                          <div className="table-responsive">
                            <table className="table table-sm table-borderless">
                              <tbody className="my-table text-capitalize">
                                <tr>
                                  <th scope="row">Student Name</th>
                                  <td className="">{std_name}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Student Code </th>
                                  <td>{student_code}</td>
                                </tr>
                                <tr>
                                  <th scope="row">APAAR Id </th>
                                  <td>{apar_id}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Child ID</th>
                                  <td>{child_id}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Class </th>
                                  <td>{class_name}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Session </th>
                                  <td>{session}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Concession </th>
                                  <td>{concession}</td>
                                </tr>

                                <tr>
                                  <th>Status</th>
                                  <td style={{}}>
                                    <b>{status}</b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          <h3 className="card-title">Contact Detail</h3>
                          <table className="table table-sm table-borderless my-table">
                            <tbody>
                              <tr>
                                <th scope="row" className="">
                                  Address
                                </th>
                                <td style={{ width: "70%" }}>{address}</td>
                              </tr>
                              <tr>
                                <th scope="row">Contact No</th>
                                <td>{contact_no}</td>
                              </tr>
                              <tr>
                                <th scope="row">Email </th>
                                <td>{email}</td>
                              </tr>
                              <tr>
                                <th scope="row">City </th>
                                <td>{fk_city}</td>
                              </tr>
                              <tr>
                                <th scope="row">District </th>
                                <td>{district}</td>
                              </tr>
                              <tr>
                                <th scope="row">State </th>
                                <td>{fk_state}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">Personal Detail</h3> <hr />
                      <div className="table-responsive">
                        <table className="table table-sm table-borderless text-nowrap my-table">
                          <tbody>
                            <tr>
                              <th scope="row" className="">
                                Admission Date
                              </th>
                              <td style={{ width: "70%" }}>
                                {convertDateInMMDDYY(addimision_date)}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Date of Birth</th>
                              <td>{convertDateInMMDDYY(dob)}</td>
                            </tr>
                            <tr>
                              <th scope="row">Nationality </th>
                              <td>{nationality}</td>
                            </tr>
                            <tr>
                              <th scope="row">Category </th>
                              <td>{category}</td>
                            </tr>
                            <tr>
                              <th scope="row">Aadhaar Card No </th>
                              <td>{adhar_no}</td>
                            </tr>
                            <tr>
                              <th scope="row">Application No. </th>
                              <td>{application_no}</td>
                            </tr>
                            <tr>
                              <th scope="row">Registration Date. </th>
                              <td>{convertDateInMMDDYY(registration_date)}</td>
                            </tr>
                            <tr>
                              <th scope="row">Gender </th>
                              <td>{gender}</td>
                            </tr>
                            <tr>
                              <th scope="row">Religion</th>
                              <td>{religion}</td>
                            </tr>
                            <tr>
                              <th scope="row">Caste </th>
                              <td>{caste}</td>
                            </tr>
                            <tr>
                              <th scope="row">Sub Caste </th>
                              <td>{sub_caste}</td>
                            </tr>
                            <tr>
                              <th scope="row">Mother Tongue </th>
                              <td>{mother_tongue}</td>
                            </tr>
                            <tr>
                              <th scope="row">Blood Group </th>
                              <td>{blood_group}</td>
                            </tr>
                            <tr>
                              <th scope="row">Birth Place </th>
                              <td>{birth_place}</td>
                            </tr>
                            <tr>
                              <th scope="row">Country </th>
                              <td>{country}</td>
                            </tr>
                            <tr>
                              <th scope="row">State </th>
                              <td>{state}</td>
                            </tr>
                            <tr>
                              <th scope="row">Mark of identity </th>
                              <td>{mark_of_identity}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row clearfix">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="card">
                    <div className="card-header p-2">
                      <h3 className="card-title">Payment Details</h3>
                    </div>
                    <div className="card-body p-0">
                      <div className="table-responsive card">
                        <table className="table table-hover table-vcenter table-borderless table-striped text-nowrap table-sm">
                          <thead></thead>
                          <tbody>
                            <tr>
                              <th scope="row">Regular Fee</th>
                              <th></th>
                              <th>
                                <button
                                  className="btn btn-outline-secondary btn-sm"
                                  type="button"
                                  onClick={() =>
                                    toggle("Regular", fee_receipt || [])
                                  }
                                >
                                  Click to  View History
                                </button>
                              </th>
                            </tr>
                            <tr>
                              <td>Total Fee : {total_fee}</td>
                              <td>Paid Fee : {paid_fee}</td>
                              <td>Balance Fee: {balance_fee}</td>
                            </tr>
                            <tr>
                              <th scope="row">
                                <b>Special Fee</b>
                              </th>
                              <th></th>
                              <th>
                                <button
                                  className="btn btn-outline-secondary btn-sm"
                                  type="button"
                                  onClick={() =>
                                    toggle("Special", special_feedetail || [])
                                  }
                                >
                                  Click to View History
                                </button>
                              </th>
                            </tr>
                            <tr>
                              <td>Paid Fee : {totalpaid_specialfee}</td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="card">
                    <div className="card-header p-2">
                      <h3 className="card-title">Exam Information</h3>
                    </div>
                    <div className="card-body p-0">
                      <div className="table-responsive card">
                        {studentProfile?.examInfo_list?.length > 0 ? (
                          <table className="table table-hover table-vcenter table-borderless table-striped text-nowrap">
                            <thead>
                              <tr className="text-center">
                                <th scope="row">Rank </th>
                                <th>Exam Name </th>
                                <th>Percentage</th>
                                <th> View Grade Card</th>
                              </tr>
                            </thead>
                            <tbody>
                             
                              {studentProfile?.examInfo_list?.map(
                                (examInfo, index) => {
                                  const [examName, gradeData] =
                                    Object.entries(examInfo)[1];
                                  let percentage = gradeData.flatMap(
                                    (item) =>
                                      item.GradeCard_list[0]
                                        ?.obtain_percentage || 0
                                  );
                                  const stu_Ranking = examInfo.rank;
                                 
                                  return (
                                    <tr key={index} className="text-center">
                                      <td> {stu_Ranking}</td>
                                      <td> {examName}</td>
                                      <td> {percentage}%</td>
                                      <td>
                                        <GradeCardPrint
                                          gradeData={gradeData}
                                          title="Student Profile"
                                          stu_Ranking={stu_Ranking}
                                        />
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        ) : (
                          <EmptyListMessage
                            message={"Exam Information Not Available !"}
                            Div="div"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">Parents Details</h3>
                      <hr />
                      <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <table class="table table-sm table-borderless my-table">
                            <tbody>
                              <tr>
                                <th scope="row" className="">
                                  Father Name
                                </th>
                                <td style={{ width: "" }}>{father_name}</td>
                              </tr>
                              <tr>
                                <th scope="row">Contact No</th>
                                <td>{f_contact_no}</td>
                              </tr>
                              <tr>
                                <th scope="row">Father Email</th>
                                <td>{f_email}</td>
                              </tr>
                              <tr>
                                <th scope="row">Occupation</th>
                                <td>{f_occupation}</td>
                              </tr>
                              <tr>
                                <th scope="row">Annual Income</th>
                                <td>{f_annual_income}</td>
                              </tr>
                              <tr>
                                <th scope="row">Education </th>
                                <td>{f_education}</td>
                              </tr>
                              <tr>
                                <th scope="row">Aadhaar Card No. </th>
                                <td>{f_adhar_no}</td>
                              </tr>
                              <tr>
                                <th scope="row">PAN No. </th>
                                <td className="text-uppercase">{f_pan_no}</td>
                              </tr>
                              <tr>
                                <th scope="row">Res Address. </th>
                                <td className="text-uppercase">
                                  {f_res_address}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Office Address </th>
                                <td className="text-uppercase">
                                  {f_office_address}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <table class="table table-sm table-borderless my-table">
                            <tbody>
                              <tr>
                                <th scope="row" className="">
                                  Mother Name
                                </th>
                                <td style={{ width: "" }}>{mother_name}</td>
                              </tr>
                              <tr>
                                <th scope="row">Contact No</th>
                                <td>{m_contact_no}</td>
                              </tr>
                              <tr>
                                <th scope="row">Mother Email</th>
                                <td>{m_email}</td>
                              </tr>
                              <tr>
                                <th scope="row">Occupation</th>
                                <td>{m_occupation}</td>
                              </tr>
                              <tr>
                                <th scope="row">Annual Income</th>
                                <td>{m_annual_income}</td>
                              </tr>
                              <tr>
                                <th scope="row">Education </th>
                                <td>{m_education}</td>
                              </tr>
                              <tr>
                                <th scope="row">Aadhaar Card No. </th>
                                <td>{m_adhar_no}</td>
                              </tr>
                              <tr>
                                <th scope="row">PAN No. </th>
                                <td className="text-uppercase">{m_pan_no}</td>
                              </tr>
                              <tr>
                                <th scope="row">Res Address. </th>
                                <td className="text-uppercase">
                                  {m_res_address}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Office Address </th>
                                <td className="text-uppercase">
                                  {m_office_address}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-11 col-md-12 col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">Document Detail</h3>
                      <hr />
                      <div className="col-md-8 col-sm-12">
                        <div className="table-responsive">
                          {studentProfile?.document?.length > 0 ? (
                            <table className="table table-sm table-borderless">
                              <thead>
                                <tr className="text-center text-nowrap">
                                  <th>Sr No.</th>
                                  <th>Document Name</th>
                                  <th>Document</th>
                                  <th>Class Name</th>
                                </tr>
                              </thead>
                              {studentProfile?.document?.map((d, index) => (
                                <tr className="text-center">
                                  <td>{index + 1}</td>
                                  <td className="text-left">
                                    {d.fk_document__value}
                                  </td>
                                  <td>
                                    {d?.file ? (
                                      <a
                                        href={`${MEDIA_BASE_URL}${d.file}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <FaFilePdf
                                          style={{
                                            fontSize: "1.5rem",
                                          }}
                                          className="text-danger"
                                        />
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>{class_name}</td>
                                </tr>
                              ))}
                            </table>
                          ) : (
                            <EmptyListMessage
                              message={"Document Not Available !"}
                              Div="div"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tab-pane" id="Parents-detail"></div>
            <div className="tab-pane" id="Documents-details"></div>
          </div>
        </div>
      </div>
      <FeeDetailsTable
        isOpen={modal}
        toggle={() => toggle("", [])}
        feeData={currentFeeData}
        title={`${feeType} Fee`}
      />
    </>
  );
};

export default StudentProfile;
