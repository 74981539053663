import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Select from "react-select";
import Swal from "sweetalert2";
import { useAlert } from "react-alert";
import { UsersAPI } from "../../../api/usersAPI";
import Loader from "../../Loader/Loader";
import * as Yup from "yup";

const EditUsers = () => {
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let super_admin_id = school_user?.id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const id = state;

  const [loader, setLoader] = useState(false);
  const [userdata, setUserdata] = useState([]);
  const [optionList, setOptionList] = useState([]);
  const [desOptionList, setDesOptionList] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);

  const [editedData, setEditedData] = useState({
    fk_school_id: 1 || "",
    first_name: "",
    last_name: "",
    email: "",
    is_admin: "",
    fk_designation_id: "",
    fk_user_id: "",
    super_admin_id,
  });

  const fetchData = async () => {
    try {
      const res = await UsersAPI.getSchoolDesignation(id);
      const mappedOptions = res.data.school_list.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setOptionList(mappedOptions);
      setLoader(false);

      const desigOptions = res.data.designation_list.map((item) => ({
        value: item.id,
        label: item.value,
      }));
      setDesOptionList(desigOptions);
    } catch (err) {
      if (err.response && err.response.status === 401) {
      } else {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, userdata]);

  useEffect(() => {
    const fetchData = async () => {
      if (userdata?.length === 0) {
        setLoader(<Loader />);
      }
      try {
        const response = await UsersAPI.getAllUsers();
        let selectedUser = response.data.payload.find((user) =>
          user.user_data.some((data) => data.id === Number(id))
        );
        let myuser = selectedUser?.user_data.find(
          (data) => data.id === Number(id)
        );
        setEditedData(myuser);
        setLoader(false);
      } catch (err) {
        if (err.response && err.response.status === 401) {
        } else {
          console.error(err);
        }
      }
    };
    fetchData();
  }, [id, userdata]);

  // const validationSchema = Yup.object().shape({
  //   school_id: Yup.string().required('School Name is required'),
  //   first_name: Yup.string().required('First Name is required'),
  //   last_name: Yup.string().required('Last Name is required'),
  //   email: Yup.string().email('Invalid email').required('Email is required'),
  //   designation_id: Yup.string().required('Designation is required'),
  //   is_admin: Yup.boolean(),
  // });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      school_id: editedData?.fk_school_id || "",
      first_name: editedData?.first_name || "",
      last_name: editedData?.last_name || "",
      email: editedData?.email || "",
      designation_id: editedData?.fk_designation_id || "",
      is_admin: editedData?.is_admin,
    },

    onSubmit: async (values) => {
      try {
        const editData = {
          ...values,
          user_id: id,
          super_admin_id,
        };
        const res = await UsersAPI.updateUsers(editData);
        if (res.data.status === 200) {
          Swal.fire({
            text: res.data.msg,
            icon: "success",
            imageAlt: "success image",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              setEditedData({});
              navigate("/admin/users");
            }
          });
        }
        if (res.data.status === 403) {
          Swal.fire({
            text: res.data.msg,
            icon: "error",
            imageAlt: "success image",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });

  if (loader || !editedData) {
    return <Loader />;
  }

  const hanldeDropdown = (selectedOptions) => {
    const selectedSchoolId = selectedOptions.value;
    setEditedData({ ...editedData, school_id: selectedSchoolId });
    formik.setFieldValue("school_id", selectedOptions.value);
  };

  const handleDesignation = (selectedOptions) => {
    const selectedDesignationId = selectedOptions.value;
    setEditedData({ ...editedData, designation_id: selectedDesignationId });
    formik.setFieldValue("designation_id", selectedOptions.value);
  };

  const handleChecked = (e) => {
    const isAdmin = e.target.checked;
    setEditedData({ ...editedData, is_admin: isAdmin });
    formik.setFieldValue("is_admin", e.target.checked);
  };

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Users</h1>
            </div>
            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  data-toggle="tab"
                  as={NavLink}
                  to="/admin/users"
                >
                  User List
                </NavLink>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#Staff-edit"
                >
                  Edit User
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">User Information</h3>
                </div>

                <form className="card-body" onSubmit={formik.handleSubmit}>
                  <div className="form-group row">
                    <label
                      htmlFor="school_id"
                      className="col-md-3 col-form-label"
                    >
                      School Name<span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <Select
                        value={optionList.find(
                          (opt) => opt.value === formik.values.school_id
                        )}
                        placeholder="Select School Name"
                        options={optionList}
                        name="school_id"
                        onChange={hanldeDropdown}
                        isSearchable={true}
                      />
                      {formik.touched.school_id && formik.errors.school_id && (
                        <div className="text-danger">
                          {formik.errors.school_id}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="first_name"
                      className="col-md-3 col-form-label"
                    >
                      First Name<span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter First name"
                        name="first_name"
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required
                      />
                      {formik.touched.first_name &&
                        formik.errors.first_name && (
                          <div className="text-danger">
                            {formik.errors.first_name}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="last_name"
                      className="col-md-3 col-form-label"
                    >
                      Last Name<span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Last name"
                        name="last_name"
                        value={formik.values.last_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required
                      />
                      {formik.touched.last_name && formik.errors.last_name && (
                        <div className="text-danger">
                          {formik.errors.last_name}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="email" className="col-md-3 col-form-label">
                      Email<span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className="text-danger">{formik.errors.email}</div>
                      )}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="fk_designation_id"
                      className="col-md-3 col-form-label"
                    >
                      Designation<span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <Select
                        name="designation_id"
                        options={desOptionList}
                        placeholder="Select Designation"
                        value={desOptionList.find(
                          (opt) => opt.value === formik.values.designation_id
                        )}
                        onChange={handleDesignation}
                        isSearchable={true}
                      />
                      {formik.touched.designation_id &&
                        formik.errors.designation_id && (
                          <div className="text-danger">
                            {formik.errors.designation_id}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="form-group row d-none">
                    <label
                      htmlFor="is_admin"
                      className="col-md-3 col-form-label"
                    >
                      Admin Access<span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <label className="custom-switch">
                        <input
                          type="checkbox"
                          className="custom-switch-input"
                          name="is_admin"
                          checked={editedData.is_admin}
                          onChange={handleChecked}
                        />
                        <span className="custom-switch-indicator"></span>
                      </label>
                      {formik.touched.is_admin && formik.errors.is_admin && (
                        <div className="text-danger">
                          {formik.errors.is_admin}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-md-9 offset-md-3">
                      <button type="submit" className="btn btn-primary mr-3">
                        Update
                      </button>
                      {/* <button
                        type="submit"
                        className="btn btn-outline-danger"
                        data-toggle="tab"
                        href="#Fees-all"
                        onClick={() => onDelete(id, editedData.fk_user_id)}
                      >
                        Delete
                      </button> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUsers;

// Function to find user data by ID
// function findUserDataById(id) {
//   // Iterate through each school
//   for (const school of dataArray) {
//     // Find user data with the given ID within the school
//     const userData = school.user_data.find(user => user.id === id);

//     // If user data is found, return it
//     if (userData) {
//       return userData;
//     }
//   }

//   // Return null if user data with the given ID is not found
//   return null;
// }

// // Find user data with ID 254
// const userDataWithId254 = findUserDataById(254);

// // Log the result
// console.log(userDataWithId254);
