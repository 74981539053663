import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { clearError, login, forgotPWD } from "../../actions/AuthAction";
import { useAlert } from "react-alert";
import { AuthAPI } from "../../api/authAPI";
import CustomPage from "./CustomPage";

import campuslink_logo from "../../Logo/campuslink_logo.png"

const ForgotPassword = () => {
  // this page is for forgot password
  const dispatch = useDispatch();
  const alert = useAlert();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  let navigate = useNavigate();

 

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      // firebase_token: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      forgot_PWD(values);
    },
  });

  const forgot_PWD = (value) => {
    AuthAPI.forgotPassword(value)
      .then((res) => {
        if (res.data.status === 200) {
          navigate("/verify-otp", { state: { email: value.email } });
        } else {
        }
        if (res.data.status === 403 || res.data.status === 503) {
          alert.error(res.data.msg);
        }
      })
      .catch(function (error) {});
  };

  return (
    <CustomPage>

      <div className="container-fluid mt-3" >
       <img
                src={campuslink_logo}
                alt=""
                style={{ width: "30%", margin: "auto", display: "block"   }}
              /> 
      <form style={{ marginTop: "15px" }}
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <h4
          className="text-center"
          style={{ color: "#19bb00", fontWeight: 700 }}
        >
          <span style={{ fontSize: 18, color: "#0E487A" }}>
            FORGOT PASSWORD
          </span>
        </h4>
        <div className="mt-4">
          <i
            style={{
              fontSize: 18,
              position: "absolute",
              zIndex: 1,
              marginTop: 16,
              marginLeft: 30,
            }}
            className="fa-solid fa-user"
          />
          <input
            style={{ padding: "12px 12px 12px 60px", borderRadius: 50 }}
            type="email"
            className="form-control"
            name="email"
            value={validation.values.email || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            placeholder="Enter Your Registered Email Id"
            autoComplete="off"
            required
          />
        </div>
        <div className="text-center mx-auto mt-4">
          <button
            type="submit"
            style={{
              borderRadius: 50,
              backgroundColor: "#19bb00",
              color: "#fff",
              fontWeight: 600,
            }}
            className="btn  btn-block btn-lg mb-3"
            title=""
          >
            Get OTP
          </button>
        </div>

        <NavLink
          to="/login"
          className="mt-2 d-flex justify-content-center float-right"
          style={{ textDecoration: "none" }}
        >
          Back to{" "}
          <span style={{ fontWeight: 600 }} className="px-1">
            {" "}
            Sign In ?
          </span>
        </NavLink>
      </form>{" "}
      </div>
    </CustomPage>
  );
};

export default ForgotPassword;
{
  /* <div className="card-title my-2 login_font">
                  FORGOT PASSWORD
                </div> */
}
