import React, { useState, useEffect } from 'react';

const useImageSize = (imageUrl) => {
  //this Function is use For get image height and width
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [loading1, setLoading1] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!imageUrl) return;

    const image = new Image();
    image.src = imageUrl;

    image.onload = () => {
      setDimensions({ width: image.width, height: image.height });
      setLoading1(false);
    };

    image.onerror = (error) => {
      setError(error);
      setLoading1(false);
    };

    return () => {
      image.onload = null;
      image.onerror = null;
    };
  }, [imageUrl]);

  return { dimensions, loading1, error };
};

export default useImageSize;
