import React, { useEffect, useState } from "react";
import { Heading } from "../../../CommonComponent/heading";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { CiSearch } from "react-icons/ci";
import Select from "react-select";
import {
  ClearButton,
  SaveButton,
  ViewButton,
} from "../../../CommonComponent/ReusableButton";

import ActualMap from "../Map/ActualMap";

import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useSubmit } from "react-router-dom";
import useLocalStorageData from "../../Comman/LocalStorageData";
import { RouteconfigurationAPI } from "../../../../api/Transport/RouteConfiguration";
import { NavLink, useParams, useNavigate, useLocation } from "react-router-dom";
import MapHelper from "../Map/MapHelper";

const PickupPoint = ({ routeid }) => {
  // user can add picakup point here
  const location = useLocation();
  const id = location.state?.id;
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const [open, setOpen] = useState(false);
  const [routeData, setRouteData] = useState([]);
  const [focusAfterClose, setFocusAfterClose] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [pickupPoint, setPickupPoint] = useState([]);
  const [mapAddress, setMapAddress] = useState("");
  const [latLng,setlatlng] = useState("")



  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  // console.log(mapAddress,"map address")

  useEffect(() => {
    getRouteConfiguration(id);
  }, []);


  const getRouteConfiguration = async (id) => {
    try {
      let data = {
        school_id: school_id,
      };
      let response = await RouteconfigurationAPI.getRouteconfigurations(data);
      let responseData = response.data;
      const filteredRoute = responseData.route_configuration.filter(
        (route) => route.id === id
      );
      setRouteData(filteredRoute); 
    } catch (err) {
      console.error(err);
    }
  };


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      school_id: school_id,
      route_id: id,
      pickup_id: "",
      pickup_point: "",
      distance: "",
      fee_amount: "",
      pickup_time: "",
      drop_time: "",
      is_active: "",
      insert_by: school_emp_id,
     

    },
    validationSchema: Yup.object().shape({
      pickup_point: Yup.string().required(" Pickup Point is required"),
      distance: Yup.string().required(" Distance is required"),
      fee_amount: Yup.string().required("Fee Amount is required"),
    }),
    onSubmit: async (values) => {
      let data = {
        ...values,
        is_active: isChecked ? "True" : "False",
        lat:latLng.lat ||"",
        lng:latLng.lng || "",
        address: mapAddress,
      };
      console.log(data)
      const response = await RouteconfigurationAPI.addUpdatePickupdropupApi(
        data
      );
      const responseData = response.data;
      if (responseData.status === 200) {
        Swal.fire({
          text: responseData.msg,
          icon: "success",
        });
        handelClear();
        getRouteConfiguration(id);
      } else {
        Swal.fire({
          text: responseData.msg,
          icon: "error",
        });
      }
    },
  });




  const deleteRoute = async (ids) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let data = {
            pickup_id: ids,
          };
          let response = await RouteconfigurationAPI.deletePickupPoint(
            data
          );
          let responseData = response.data;
          if (responseData.status === 200) {
           
            Swal.fire({
              title: "Success",
              text: "Route deleted successfully",
              icon: "success",
            }).then(() => {
              // After user clicks OK, refresh the route data
              getRouteConfiguration(id);
            })
          }
        } catch (err) {
          console.error(err);
          Swal.fire({
            title: "Error",
            text: "Failed to delete route",
            icon: "error",
          });
        }
      }
    });
  };
  const handelClear = ()=>{
    formik.resetForm();
    setPickupPoint([]);
    setIsChecked(false);
    setMapAddress("")
    setlatlng("")
  }


  const handelClick = () => {
   
  };
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <Heading pageTitle={"Add Pickup Point"}>
            <div className="row m-3">  
            <Link className="btn btn-outline-info" to='/school/route-configuration'>Go Back</Link>

            </div>
          </Heading> 
          <div className="col-lg-12   mt-4">
            <div className="card">
              <div className="card-body">
                
                <form
                  className="card-body form-horizontal"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="col-lg-12 ">
                    <div className=" form-group row">
                      <label className="col-3 col-form-label text-left ">
                        Pickup Point <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-9">
                        <input
                          type="text"
                          placeholder="Pickup point"
                          className="form-control custom-input"
                          name="pickup_point"
                          value={formik.values.pickup_point}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.pickup_point &&
                          formik.errors.pickup_point && (
                            <div className="text-danger">
                              {formik.errors.pickup_point}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="form-group  row">
                      <label className="col-3  col-form-label text-left">
                        Distance(Kms) <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="text"
                          placeholder="Distance"
                          className="form-control custom-input"
                          name="distance"
                          value={formik.values.distance}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.distance && formik.errors.distance && (
                          <div className="text-danger">
                            {formik.errors.distance}
                          </div>
                        )}
                      </div>
                      <label className="col-lg-3 col-form-label text-left">
                        Fee Amount <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="text"
                          placeholder="Fee amount"
                          className="form-control custom-input"
                          name="fee_amount"
                          value={formik.values.fee_amount}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.fee_amount &&
                          formik.errors.fee_amount && (
                            <div className="text-danger">
                              {formik.errors.fee_amount}
                            </div>
                          )}
                      </div>
                      </div>

                      {/* <div className="form-group  row">
                        <label className="col-lg-3 col-form-label text-left">
                          From Previous Stop
                        </label>
                        <div className="col-lg-3">
                          <input
                            type="text"
                            placeholder="From Previous Stop"
                            className="form-control custom-input"
                            name="from_previous_stop"
                            value={formik.values.from_previous_stop}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div> */}
                      <div className="form-group  row">
                        <label className="col-lg-3 col-form-label text-left">
                          Address
                        </label>
                        <div className="col-lg-3">
                          <textarea
                            type="text"
                            placeholder="Address"
                            className="form-control custom-input"
                            name="address"
                            value={mapAddress}
                            disabled={true}
                          />
                        </div>
                        <MapHelper setMapAddress={setMapAddress} setlatlng={setlatlng} lato={'21.123753654600115'} lngo={'79.08392486362759'}/>
                      </div>
                      <div className="form-group row">
                        <label className="  col-lg-3 col-form-label text-left">
                          Pickup Time
                        </label>
                        <div className="col-lg-3">
                          <input
                            type="text"
                            placeholder="HH:MM:SS"
                            className="form-control custom-input"
                            name="pickup_time"
                            value={formik.values.pickup_time}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        <label className=" col-lg-3 col-form-label text-left">
                          Drop Time
                        </label>
                        <div className="col-lg-3">
                          <input
                            type="text"
                            placeholder="HH:MM:SS"
                            className="form-control custom-input"
                            name="drop_time"
                            value={formik.values.drop_time}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                      <div class="form-check col-lg-4 text-lg-right  ">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          Active
                        </label>
                      </div>
                   
                  </div>
                  <div className="justify-content-center align-items-center">
                    <button
                      className="btn btn-primary btn-sm mx-1 m-1"
                      type="submit"
                    >
                      <i className="fa fa-plus px-1"></i>
                      Save
                    </button>
                    <ClearButton className={"mx-1 m-1"}  onClick={handelClear}/>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-body">
        <div className="container-fluid">
          <div className="card">
            <div className="table-responsive ">
              <table
                className="table table-striped table-bordered table-hover table-sm  "
                id="table-to-xls"
              >
                <thead>
                  <tr className="text-center text-nowrap">
                  <th>Sr No</th>
                    <th>Route Name</th>
                    <th>Bus Number</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Driver</th>
                    <th>Attendant</th>
                    <th>Active</th>
                 
                  </tr>
                </thead>
                <tbody>
                  {routeData &&
                    routeData.map((item, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{index +1}</td>
                          <td>{item.route_name}</td>
                          <td>{item.bus_no}</td>
                          <td>{item.start_time}</td>
                          <td>{item.end_time}</td>
                          <td>{item.driver_attendant_name}</td>
                          <td>{item.attendant_name}</td>
                          <td>{item.is_active ? "Yes" : "No"}</td>
                        
                        </tr>

                        <tr>
                          <td colSpan="8">
                            <table className="table table-bordered">
                              <thead>
                                <tr >
                                  <th>Sr No</th>
                                  <th >Pickup Point</th>
                                  <th>Distance</th>
                                  <th>Fee Amount</th>
                                  {/* <th>From Previous Stop</th> */}
                                  <th>Pickup Time</th>
                                  <th>Drop Time</th>
                                  <th>Active</th>
                                  <td>Action</td>
                                </tr>
                              </thead>
                              <tbody>
                                {item.pickup_point &&
                                  item.pickup_point.map((pickup, idx) => (
                                    <tr key={idx} className="text-center "> 
                                      <td className="align-middle">{idx + 1}</td>
                                      <td className="align-middle">{pickup.pickup_point}</td>
                                      <td className="align-middle">{pickup.distance}</td>
                                      <td className="align-middle">{pickup.fee_amount}</td>
                                      <td className="align-middle">{pickup.pickup_time}</td>
                                      <td className="align-middle">{pickup.drop_time}</td>
                                      <td className="align-middle">{pickup.is_active ? "Yes" : "No"}</td>
                                      <td className="d-flex">
                                        <Link
                                          className="btn btn-sm m-1"
                                          to="/school/edit-pickup-point"
                                          state={{ pickup: pickup.id  ,id:item.id}}
                                        >
                                          <i className="fa fa-edit"></i>
                                        </Link>{" "}
                                        <button className="btn">
                                          {" "}
                                          <i className="fa fa-trash-o text-danger"  onClick={() => deleteRoute(pickup.id)}></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PickupPoint;
