// YourComponent.js
import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../../Config/BaseUrl";
import "./d.css";
import ImageComponent from "./ImageComponent";
import { useFormik } from "formik"; // Importing the useFormik hook
import { MEDIA_BASE_URL } from "../../../Config/BaseUrl";

const PresiedentMessage = ({ token, schoolId }) => {
  // this code is for the quill editor
  const [editorHtml, setEditorHtml] = useState("");
  const [presidentIntroduction, setPresidentIntroduction] = useState("");
  const [ShowImg, setShowImg] = useState("");

  const [image, setImage] = useState("");

  const sanitizedEditorHtml = editorHtml.replace(/<p><br><\/p>/g, "").trim();
  const sanitizedPresidentIntroduction = presidentIntroduction
    .replace(/<p><br><\/p>/g, "")
    .trim();

  const [disableds, setDisableds] = useState(true);

  const formik = useFormik({ initialValues: {}, onSubmit: () => {} });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}get_Persident_message/`,
          { school_id: schoolId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setEditorHtml(response.data.president_message || "");
        setPresidentIntroduction(response.data.president_introduction || "");
        if (response.data.president_image) {
          setShowImg(`${MEDIA_BASE_URL}${response.data.president_image}` || "");
          setDisableds(false)
        }
        
       
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [token, schoolId]);


const handleDeleteImage = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this President message!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {

        const formData = new FormData();
    formData.append("school_id", schoolId);
    formData.append("message", "");
    formData.append("president_introduction", "");
    formData.append("is_image_remove", "True");
    formData.append("msg", "President message deleted successfully");
        try {

          const response = await axios.post(
            `${BASE_URL}save_Persident_message/`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
              params: {
                school_id: schoolId,
              },
            }
          );

          if (response.status === 200) {
           
            handleClear()
            Swal.fire('Deleted!', 'President message deleted successfully.', 'success');
          } else {
            
            Swal.fire('Error!', 'Failed to delete President message.', 'error');
          }
        } catch (error) {
          console.error('Error deleting image:', error);
          Swal.fire('Error!', 'Failed to delete President message.', 'error');
        }
      }
    });
  };



  const handleSubmit = async () => {
    if (!sanitizedEditorHtml || !sanitizedPresidentIntroduction || !ShowImg) {
      Swal.fire({
        text: "Please fill in all fields.",
        icon: "error",
        allowOutsideClick: false,
      });
      return;
    }

    const formData = new FormData();
    formData.append("school_id", schoolId);
    formData.append("message", editorHtml);
    formData.append("president_introduction", presidentIntroduction);
    if (ShowImg) {
      formData.append("president_image", image);
    } else {
      formData.append("president_image", "");
    }
    formData.append("msg", "President message Saved successfully")
    //     console.log("Printing FormData:");
    // formData.forEach((value, key) => {
    //   console.log(key, value);
    // });

    try {
      const response = await axios.post(
        `${BASE_URL}save_Persident_message/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          params: {
            school_id: schoolId,
          },
        }
      );

      if (response.status === 200) {
        setDisableds(false)
        Swal.fire({
          text: response.data.msg,
          icon: "success",
          allowOutsideClick: false,
        });
      } else {
        Swal.fire({
          text: response.data.msg,
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };


  const handleClear = () => {
    setDisableds(true);
    setEditorHtml("");
    setPresidentIntroduction("");
    setImage("null");
    setShowImg("");
  };
  const isSubmitDisabled =
    !sanitizedEditorHtml || !sanitizedPresidentIntroduction || !ShowImg;
  //
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <h4 className="m-3">President Introduction</h4>
          <div className="d-flex">
            <div className="col-9">
              <div>
                <ReactQuill
                  theme="snow"
                  id="introductionquill"
                  value={presidentIntroduction}
                  onChange={setPresidentIntroduction}
                  modules={quillModules}
                  formats={quillFormats}
                />
              </div>
            </div>
            <div className="col-3">
              <ImageComponent
                formik={formik}
                fieldName="president_image"
                showImg={ShowImg}
                setShowImg={setShowImg} 
                setImage={setImage}
              />
            </div>
          </div>
          <h4 className="m-3">President Massage</h4>
          <ReactQuill
            theme="snow"
            id="introductionquill1"
            value={editorHtml}
            onChange={setEditorHtml}
            modules={quillModules}
            formats={quillFormats}
          />
        </div>
      </div>
      <button
        type="button"
        className="btn btn-success m-2"
        onClick={handleSubmit}
        disabled={isSubmitDisabled}
      >
        Submit
      </button>
      <button
        type="button"
        className="btn btn-danger m-2"
        onClick={handleDeleteImage}
        disabled={disableds}
      >
        Delete
      </button>
    </div>
  );
};

const quillModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
  ],
};

const quillFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "script",
  "indent",
  "direction",
  "color",
  "background",
  "code-block",
  "link",
  "align",
];

export default PresiedentMessage;
