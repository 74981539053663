import React from "react";
import { CardBody, CardTitle, Col, Row, Table } from "reactstrap";
import { Link, NavLink } from "react-router-dom";

function OnlineTestList({ testType, tests, onTestDelete }) {
  // user can see the online test list 
  return (
    <CardBody>
      <CardTitle className="fs-6">{testType} Test</CardTitle>
      <Row className="border-rounded">
        <Col xl={12}>
          <div className="table-responsive">
            {tests && tests.length > 0 ? (
              <Table className="table table-striped">
                <thead >
                  <tr className="text-nowrap">
                    <th>Test Name</th>
                    <th>Class</th>
                    <th>Subject</th>
                    <th>No. of Ques.</th>
                    <th>Action</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {tests?.map((test) => (
                    <tr key={test.id}>
                      <td className="text-start font-weight-bold text-uppercase">
                        <NavLink
                          to={`/school/online-test-result/${test.id}`}
                          style={{ color: "inherit" }}
                        >
                          {test.test_name}
                        </NavLink>
                      </td>
                      <td>{test.class_name?.toUpperCase()}</td>
                      <td>{test.subject_name}</td>
                      <td>{test.no_of_question} Ques.</td>
                      <td>
                        <div className="d-flex gap-3">
                          <Link
                            to={`/school/online-test-edit`}
                            state={{ test: test }}
                            className="text-success"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Edit"
                          >
                            <i className="fa fa-pencil text-success mx-2 text-center"></i>
                          </Link>
                          <Link
                            to="#"
                            className="text-danger"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete"
                            onClick={() => onTestDelete(test.id)}
                          >
                            <i
                              className="fa fa-trash font-size-18 mx-2"
                              id="deletetooltip"
                            />
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div className="square-switch d-flex">
                          <h6
                            className={`text-center ${
                              test.is_active ? "text-success" : "text-danger"
                            }`}
                          >
                            {test.is_active ? "Active" : "Inactive"}
                          </h6>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p className="no-list-message" style={{ fontSize: "1.5rem" }}>
                {testType} Test Not Found !{" "}
              </p>
            )}
          </div>
        </Col>
      </Row>
    </CardBody>
  );
}

export default OnlineTestList;
