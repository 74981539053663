import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Card, CardBody, Col, Table } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "flatpickr/dist/themes/material_blue.css";
import { AttendanceContext } from "../../../context/attendanceContext";
import LeftSidebar from "./LeftSidebar";
import HeaderPopup from "./HeaderPopup";
import { useDispatch } from "react-redux";
import {
  getClassSection,
  getStudentList,
  saveAttendance,
} from "../../../actions/school_user/Attenadance";
import Loader from "../../Loader/Loader";
import Swal from "sweetalert2";
import { resetState } from "../../../actions/ResetAction/ResetAction";
import CustomScrollbar from "../../CommonComponent/PerfectScrollbar";
import { scrollToTop } from "../../CommonComponent/helper";

const Attendance = () => {
  // in this page we are showing attendence of all students
  const token = localStorage.getItem("token"); 

  const {
    s_data,
    studentList,
    isLoading,
    data,
    setIsLoading,
    session_id,
    classSelect,
    school_id,
    school_emp_id,
    attendence_date,
    setClassSelect,
    setStudentList,
  } = useContext(AttendanceContext);

  const dispatch = useDispatch();
  const [students, setStudents] = useState([]);
  
  const [search, setSearch] = useState("");
  const keys = ["student_name", "student_code"];
  const handlesearch = (e) => {
    setSearch(e.target.value);
  };
  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);
  useEffect(() => {
    setClassSelect("");
  }, []);
  useEffect(() => {
    if (classSelect) {
      setStudents(studentList?.payload);
      if (studentList?.present_list?.length > 0) {
        Swal.fire({
          title: "",
          text: "Attendance is already marked. Do you want to edit it?",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
          } else {
            setStudents([]);
            setStudentList([]);
          }
        });
      }
    } else {
      setStudents([]);
      setStudentList([]);
    }
  }, [studentList?.payload, classSelect]);
  const handleCheckboxChange = (studentId) => {
    const updatedStudents = students.map((student) => {
      if (student.student_id === studentId) {
        student.is_present = !student.is_present;
      }
      return student;
    });

    setStudents(updatedStudents);
  };
  const makeAddendance = async () => {
    scrollToTop()
    if (!classSelect) {
      Swal.fire("", "Please Select Class ", "info");
      return;
    }
    if (students?.length === 0) {
      Swal.fire("", "Student List Not Found", "info");
      return;
    }

    let student_data = students?.map((s) => {
      return { student_id: s.student_id, is_present: s.is_present, id: s.id };
    });

    let save_data = {
      fk_academic: session_id,
      student_attendance_id: "",
      fk_section: classSelect,
      fk_school: school_id,
      school_emp_id: school_emp_id,
      attendance_date: attendence_date,
      student_data: student_data,
    };

    try {
      setIsLoading(true);
      let response = await dispatch(saveAttendance(token, save_data));
      if (response.status === 200) {
        await Swal.fire({
          text: response.msg,
          icon: "success",
          imageAlt: "success image",
          confirmButtonText: "OK",
        });
        setClassSelect("");
      } else if (response.status === 403) {
        await Swal.fire({
          text: response.msg,
          icon: "error",
          imageAlt: "error image",
          confirmButtonText: "OK",
        });
      }
      // await dispatch(getClassSection(token, s_data));
      // await dispatch(getStudentList(token, data));
      setIsLoading(false);
    } catch (error) {
      console.error("Error sending roll numbers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    
      <div className="page-content">
        <Container fluid>
          <Row>
          <Col xl={4}>
              <LeftSidebar />
            </Col>
            <Col xl={8}>
              <Row>
                <HeaderPopup />
              </Row>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={11} className="m-auto">
                      <form className="p-0">
                        <div className="input-group ">
                          <input
                            className="form-control bg-light  border"
                            type="search"
                            value={search || ""}
                            onChange={handlesearch}
                            id="search"
                            placeholder="Search by Student code or Name"
                            autoComplete="off"
                          />
                          <span className="input-group-append">
                            <button className="btn ms-n5" type="button">
                              <i className="fa fa-search"></i>
                            </button>
                          </span>
                        </div>
                      </form>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="m-auto">
                      <div className="mt-4">
                        {isLoading && <Loader />}
                        {students?.length === 0 ? (
                          <div
                            style={{ height: "310px" }}
                            className="align-center text-center"
                          >
                            {studentList?.msg ? (
                              <h3 className="text-danger mt-5 ">
                                {studentList?.msg} !
                              </h3>
                            ) : (
                              <h3 className="no-list-message mt-5 ">
                                Student List Not Found !
                              </h3>
                            )}
                          </div>
                        ) : (
                          !isLoading && (
                            <CustomScrollbar
                              style={{
                                height: "30rem",
                                paddingRight: "1rem",
                                boxSizing: "content-box",
                              }}
                            >
                              <div className="table-responsive">
                                <Table className="table-sm align-center text-center table-borderless text-nowrap" >
                                  <thead className="">
                                    <tr className="">
                                      <th className="text-left" scope="col">
                                        <h6>Student Code </h6>
                                      </th>
                                      <th className="" scope="col">
                                        <h6>Roll No </h6>
                                      </th>
                                      <th className="" scope="col">
                                        <h6>Name</h6>
                                      </th>
                                      <th className="" scope="col">
                                        <h6>Present</h6>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {students
                                      ?.filter((stu) =>
                                        keys.some((key) =>
                                          stu[key]
                                            ?.toLowerCase()
                                            .includes(search?.toLowerCase())
                                        )
                                      )
                                      .map((stu, index) => (
                                        <tr key={index}>
                                          <td className="p-0 text-left ">
                                            {stu.student_code}
                                          </td>
                                          <td className="p-0  ">
                                            {stu.roll_no}
                                          </td>
                                          <td className="text-left">
                                            <h6 className="text-truncate font-size-13 text-capitalize">
                                              {stu.student_name}
                                            </h6>
                                          </td>
                                          <td className="p-0">
                                            <div className="d-flex align-items-center justify-content-center">
                                              <label className="custom-switch pr-1">
                                                <input
                                                  type="checkbox"
                                                  className="custom-switch-input"
                                                  name="status"
                                                  checked={stu.is_present}
                                                  onChange={() =>
                                                    handleCheckboxChange(
                                                      stu.student_id
                                                    )
                                                  }
                                                />
                                                <span
                                                  className="custom-switch-indicator"
                                                  style={{
                                                    backgroundColor:
                                                      stu.is_present
                                                        ? "#79AC78"
                                                        : "#F15A59",
                                                  }}
                                                ></span>
                                              </label>
                                              {stu.is_present ? (
                                                <span className="">YES</span>
                                              ) : (
                                                <span> NO</span>
                                              )}
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </Table>
                              </div>
                            </CustomScrollbar>
                          )
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div className="row d-flex justify-content-end">
                    {students?.length > 0 ? (
                      <button
                        type="button"
                        className="mt-1 btn px-5"
                        onClick={() => makeAddendance()}
                        style={{
                          backgroundColor: "#42a5f5",
                          color: "#fff",
                        }}
                      >
                        Save
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
           
          </Row>
        </Container>
      </div>
    
  );
};

export default Attendance;
