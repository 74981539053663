import React, { useState, useEffect } from "react";

const Time = () => {
  // all time formats are avalable here 
  const [currentTime, setCurrentTime] = useState("");
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const today = new Date();
      let hr = today.getHours();
      let min = today.getMinutes();
      let sec = today.getSeconds();
      const ap = hr < 12 ? "AM" : "PM";
      hr = hr === 0 ? 12 : hr > 12 ? hr - 12 : hr;
      hr = checkTime(hr);
      min = checkTime(min);
      sec = checkTime(sec);

      const timeString = `${hr}:${min}:${sec} ${ap}`;

      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const curWeekDay = days[today.getDay()];
      const curDay = today.getDate();
      const curMonth = months[today.getMonth()];
      const curYear = today.getFullYear();
      const dateString = `${curWeekDay}, ${curDay} ${curMonth} ${curYear}`;

      setCurrentTime(`${timeString}`);
      setCurrentDate(`${dateString}`);
    };

    const intervalId = setInterval(updateTime, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array means the effect runs once after the initial render

  function checkTime(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  return (
    <div className=" text-success TimeSize ml-3 ">
      <b>{currentTime}</b>
      <br />
      <small>{currentDate}</small>
    </div>
  );
};

export default Time;
