import React, { useEffect, useState } from "react";
import HostelAPI from "../../../../api/Hostel/HostelRegistration";
import CollectionTable from "../../FeeCollection/CollectionTable";
import useLocalStorageData from "../../Comman/LocalStorageData";
import useLoading from "../../../CommonComponent/useLoading";

const HostelFeeCollection = () => {
  const { school_id, token, school_emp_id, schoolUser } = useLocalStorageData();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const currentDate = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(currentDate.getMonth() - 1);
  
  const [from_date1, setFrom_date1] = useState(oneMonthAgo.toISOString().substr(0, 10));
  const [to_date1, setTo_date1] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [AdmissionStatus1, setAdmissionStatus1] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [Page, setPage] = useState(1);
  const [CurrentPage, setCurrentPage] = useState(1);
  const StatusType = [
    { value: "Regular", label: "Regular" },
    { value: "External", label: "External" },
  ];
  let hostelfeeCollection = async () => {
    try {
      let collData = {
        school_id: school_id,
        from_date: from_date1,
        to_date: to_date1,
        student_type: AdmissionStatus1,
      };
      let { data } = await HostelAPI.getHostelPaymentfeeCollection(
        collData,
        CurrentPage
      );
      setCollectionData(data.fee_collection);
      setPage(data.fee_collection_count);
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    hostelfeeCollection();
  }, [school_id, AdmissionStatus1, from_date1, to_date1, CurrentPage]);
  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mx-1">
        <div className="header-action">
          <h1 className="page-title">Fee Collection Report</h1>
        </div>
        <ul className="nav nav-tabs page-header-tab">
          <li className="nav-item">
            <a className="nav-link active" data-toggle="tab" href="#Hostel">
              Hostel Fee Collection
            </a>
          </li>
        </ul>
      </div>
      <div className="tab-content">
        <div className="tab-pane active mt-3" id="Hostel">
          <CollectionTable
            feeCollectionData={collectionData}
            from_date={from_date1}
            setFrom_date={setFrom_date1}
            setTo_date={setTo_date1}
            to_date={to_date1}
            AdmissionStatus={AdmissionStatus1}
            setAdmissionStatus={setAdmissionStatus1}
            optionList={StatusType}
            placeholder={"Select student type"}
            FeeTable={"Hostel"}
            Page={Page}
            setCurrentPage={setCurrentPage}
            CurrentPage={CurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default HostelFeeCollection;
