import React, { useState, useEffect, useRef } from "react";
import GroupClassList from "./GroupClassList";
import Select from "react-select";
import useSessionRedirect from "../../Comman/SessionChange";
import { useDispatch, useSelector } from "react-redux";
import { getClass } from "../../../../actions/Admission/StudentReport";
import useLocalStorageData from "../../Comman/LocalStorageData";
import { useFormik } from "formik";
import * as Yup from "yup";
import groupClassMapAPI from "../../../../api/Examination/GroupClassMapping";
import Swal from "sweetalert2";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";
import useLoading from "../../../CommonComponent/useLoading";
import Loader from "../../../Loader/Loader";

const GroupClassMapping = () => {
  // this file is used for the group and class mapping
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const session_id = useSessionRedirect();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const dispatch = useDispatch();
  const { classsectionList } = useSelector((state) => state.attendance);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(true);
  const [isGroupId, setIsGroupId] = useState(null);
  const [classGroupList, setClassGroupList] = useState([]);
  const [classGroup, setClassGroup] = useState([]);
  const [isClass, setIsClass] = useState([]);
 

  let classListOption = classsectionList?.payload?.map((c) => {
    return {
      label: `${c.class_name} ${c.section}`,
      value: c.id,
    };
  });

  
  const fetchClassGroup = async () => {
    try {
      let body = {
        school_id: school_id,
        academic_id: session_id,
      };
      let response = await groupClassMapAPI.getGroupClass(body);
      let responseData = response.data;
      setClassGroupList(responseData.group_ofclass);
    } catch (error) {
      console.error(error);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      academic_id: session_id,
      school_id: school_id,
      insert_by: school_emp_id,
      group_id: classGroup?.id || "",
      group_name: classGroup?.group_name || "",
      section: classGroup?.section?.map((c) => c.fk_section) || [],
    },
    validationSchema: Yup.object().shape({
      group_name: Yup.string().required("This field is required."),
      section: Yup.array()
        .min(1, "At least one class must be selected.")
        .of(Yup.string())
        .required("This field is required."),
    }),
    onSubmit: async (values) => {
      startLoading();
      try {
        const data = new FormData();
        data.append("academic_id", values.academic_id);
        data.append("school_id", values.school_id);
        data.append("insert_by", values.insert_by);
        data.append("group_id", values.group_id);
        data.append("group_name", values.group_name);
        data.append("section", JSON.stringify(values.section));

        const response = await groupClassMapAPI.createGroupClass(data);
        let responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            allowOutsideClick: false,
          });
          fetchClassGroup();
          handleClear();
        } else if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        console.error(error);
      }finally {
        stopLoading();
      }
    },
  });
 
  const handleSubmit = (e) => {
    e.preventDefault();
    formik.handleSubmit();
    return false;
  };
  const handleClear = () => {
    formik.resetForm();
    setClassGroup([]);
    formik.setFieldValue("section", []);
    setIsEdit(false);
    setIsDelete(true);
    setIsGroupId(null)
  };

  const fetchClassGroupForEdit = async () => {
    let body = {
      group_id: isGroupId,
    };
    startLoading();
    try {
      let response = await groupClassMapAPI.getGroupClassData(body);
      let responseData = response.data;
      if (responseData.status === 200) {
        setClassGroup(responseData.data[0]);
        setIsEdit(true);
        setIsDelete(false);
      }
    } catch (error) {
      console.error(error);
    }finally {
      stopLoading();
    }
  };

  useEffect(() => {
    if (isGroupId !== null) {
      fetchClassGroupForEdit();
    }
  }, [isGroupId]);

  useEffect(() => {
    fetchClassGroup();
  }, [session_id]);

  const onDelete = (id) => {
    let group_id = {
      group_id: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await groupClassMapAPI.deleteGroupClass(group_id);
        let responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            imageAlt: "success image",
          });
          fetchClassGroup();
          handleClear();
        }
        if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            imageAlt: "error image",
          });
        }
      }
    });
  };

  if (isLoading)  return <Loader />
  return (
    <div className="section-body">
      <div className="container-fluid">
        <form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Group Class Mapping</h1>
            </div>
            <div className=" mt-2">
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm mx-1"
                onClick={() => handleClear()}
              >
                <i className="fa fa-pencil px-1"></i>
                New
              </button>
              <button className="btn btn-primary btn-sm mx-1" type="submit">
                <i className="fa fa-plus px-1"></i>
                {isEdit ? "Update" : "Save"}
              </button>
              <button
                type="button"
                className="btn btn-outline-danger btn-sm mx-1"
                onClick={() => onDelete(classGroup?.id)}
                disabled={isDelete}
              >
                <i className="fa fa-trash px-1"></i>
                Delete
              </button>
              <GroupClassList
                classGroupList={classGroupList}
                isGroupId={isGroupId}
                setIsGroupId={setIsGroupId}
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="card">
              <div className="card-body row">
                <div className="col-lg-7 col-md-12">
                  <div className="form-group col-md-12 row">
                    <label className="col-md-3 col-form-label">
                      Group Name <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Group Name"
                        name="group_name"
                        value={formik.values.group_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                      {formik.touched.group_name && formik.errors.group_name ? (
                        <ErrorDisplay errorMsg={formik.errors.group_name} />
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group col-md-12 row">
                    <label className="col-md-3 col-form-label">
                      Class Name <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <Select
                        isMulti
                        name="section"
                        placeholder="Select Class"
                        options={classListOption}
                        value={classListOption?.filter((option) =>
                          formik.values.section.includes(option.value)
                        )}
                        onChange={(selectedOptions) => {
                          const selectedValue = selectedOptions.map(
                            (option) => option.value
                          );
                          formik.setFieldValue("section", selectedValue);
                        }}
                        onBlur={formik.handleBlur}
                        isSearchable={true}
                      />
                      {formik.touched.section && formik.errors.section ? (
                        <ErrorDisplay errorMsg={formik.errors.section} />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12"></div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GroupClassMapping;
