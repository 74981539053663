import React from "react";
import { AuthAPI } from "../../api/authAPI";
import { Heading } from "../CommonComponent/heading";
import ChangePasswordForm from "./ChangePasswordForm";

const ChangePassword = () => {
  // this code is for change password 
  const storedSchoolUser = JSON.parse(localStorage.getItem("school_user"));

  return (
    <div className="section-body">
      <div className="container-fluid">
        <Heading pageTitle={"Change Password"}></Heading>
        <div className="col-lg-6 col-md-8 col-sm-12 mt-4">
          <div className="card">
            <div className="card-body">
              <ChangePasswordForm
              apiFunction={AuthAPI.changePassword}
              navigateTo={`/school/dashboard`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
