import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import NotificationApi from "../../../api/Notification/Notification";
import useLocalStorageData from "../Comman/LocalStorageData";
import Swal from "sweetalert2";
import { ClearButton } from "../../CommonComponent/ReusableButton";
import { useFormik } from "formik";
import ErrorDisplay from "../../CommonComponent/ErrorDisplay";
import * as Yup from "yup";
import { MEDIA_BASE_URL } from "../../../Config/BaseUrl";
import { NavLink } from "react-router-dom";
import { FaFileDownload } from "react-icons/fa";



const AddNotification = () => {
  // it is used to show the notification bell in the header portion of the page
  // where you can do Crud with the notification
  const [staffList, setStaffList] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [getAddedNotification, setGetAddedNotification] = useState([]);
  const [uploadedDocument, setUploadedDocument] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const { user_type, school_emp_id, id } = useLocalStorageData();
  const fileInputRef = useRef(null);


  useEffect(() => {
    fetchStaffList();
    getNotificationList();
  }, []);

  const getNotificationList = async () => {
    try {
      let data = {};

      if (user_type === "Admin") {
        data = {
          user_type: "SuperAdmin",
          user_id: id,
        };
        console.log(user_type, id);
        const response = await NotificationApi.getNotificationList(data);
        let responseData = response.data;
        setGetAddedNotification(responseData.notification);
      } else {
        data = {
          user_type: user_type,
          user_id: school_emp_id,
        };
        console.log(user_type, school_emp_id);
        const response = await NotificationApi.getNotificationList(data);
        let responseData = response.data;
        setGetAddedNotification(responseData.notification);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const get_StaffNotification = async () => {
    let data = {};
  };

  const fetchStaffList = async () => {
    try {
      let data = {};

      if (user_type === "Admin") {
        data = {
          user_type: "SuperAdmin",
          user_id: id,
        };
      } else {
        data = {
          user_type: "School",
          user_id: school_emp_id,
        };
      }

      const response = await NotificationApi.get_StaffList(data);
      let responseData = response.data;
      setStaffList(
        responseData.stafff.map((staff) => ({ ...staff, checked: false }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  const onDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const data = {
            notification_id: id,
          };

          let response = await NotificationApi.delete_StaffNotification(data);
          let responseData = response.data;

          if (responseData.status === 200) {
            getNotificationList(); // Refresh the notification list after deletion
            Swal.fire({
              text: responseData.msg,
              icon: "success",
            });
          }
          if (responseData.status === 403) {
            Swal.fire({
              text: responseData.msg,
              icon: "error",
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileChange = async (file) => {
    if (!file) return;
    try {
      let base64 = await convertBase64(file);
      setSelectedFile(base64);
      setUploadedDocument(file);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectAllChange = () => {
    setSelectAllChecked(!selectAllChecked);
    setCheckedIds(selectAllChecked ? [] : staffList.map((staff) => staff.id));
    setStaffList((prevStaffList) =>
      prevStaffList.map((staff) => ({
        ...staff,
        checked: !selectAllChecked,
      }))
    );
  };

  const handleCheckboxChange = (id) => {
    setStaffList((prevStaffList) =>
      prevStaffList.map((staff) =>
        staff.id === id ? { ...staff, checked: !staff.checked } : staff
      )
    );

    setCheckedIds((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((checkedId) => checkedId !== id)
        : [...prevIds, id]
    );

    setSelectAllChecked(
      staffList.every((staff) => staff.checked) && staffList.length > 0
    );
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .trim() // Trim leading and trailing whitespaces
        .required("This field is required.")
        .test("notEmptySpaces", "Please enter a non-empty value", (value) => {
          return value.trim() !== "";
        }),
      document: Yup.mixed()
        .test("filesize", "File is too large. Maximum size is 5mb", (value) => {
          if (typeof value === "string") {
            return true;
          }
          return !value || (value && value.size <= 5 * 1024 * 1024);
        })
        .nullable(),
    }),

    onSubmit: async (values) => {
      if (!checkedIds.length) {
        Swal.fire({
          title: "",
          text: "Select Atleast One Staff Member.",
          icon: "warning",
          allowOutsideClick: false,
        });
        return;
      }

      try {
        const data = new FormData();
        data.append("message", values.title);
        data.append("school_empId", JSON.stringify(checkedIds));
        data.append("super_admin_id", id);
        data.append("document", uploadedDocument);
        if (user_type !== "Admin") {
          data.append("emp_id", school_emp_id);
        } else {
          data.append("emp_id", "");
        }

        const response = await NotificationApi.send_StaffNotification(data);
        let responseData = response.data;

        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
          });
          handleClear();
          try {
            let data = {};

            if (user_type === "Admin") {
              data = {
                user_type: "SuperAdmin",
                user_id: id,
              };
              console.log(user_type, id);
              const response = await NotificationApi.getNotificationList(data);
              let responseData = response.data;
              setGetAddedNotification(responseData.notification);
            } else {
              data = {
                user_type: user_type,
                user_id: school_emp_id,
              };
              console.log(user_type, school_emp_id);
              const response = await NotificationApi.getNotificationList(data);
              let responseData = response.data;
              setGetAddedNotification(responseData.notification);
            }
          } catch (error) {
            console.error(error);
          }
        } else if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const handleClear = () => {
    validation.resetForm();
    setNotificationMessage("");
    setUploadedDocument(null);
    setCheckedIds([]);
    setSelectAllChecked(false);

    fileInputRef.current.value = "";

    setStaffList((prevStaffList) =>
      prevStaffList.map((staff) => ({ ...staff, checked: false }))
    );
  };

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Add Notification </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="card">
                  <form>
                    <div className="card-body row">
                      <div className="form-group col-md-12 row">
                        <label className="col-md-4 col-form-label">
                          Add Notification{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-8">
                          <textarea
                            type="text"
                            className={`form-control ${
                              validation.touched.title &&
                              validation.errors.title
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Notice"
                            name="title"
                            value={notificationMessage}
                            onChange={(e) => {
                              setNotificationMessage(e.target.value);
                              validation.handleChange(e);
                            }}
                            onBlur={validation.handleBlur}
                          />
                          {validation.touched.title &&
                          validation.errors.title ? (
                            <div className="invalid-feedback">
                              {validation.errors.title}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group col-md-12 row">
                        <label className="col-md-4 col-form-label">
                          Upload Document:<span className="text-danger"></span>
                        </label>{" "}
                        <div className="col-md-8">
                          <input
                            type="file"
                            className="form-control"
                            name="document"
                            ref={fileInputRef}
                            onChange={(e) => {
                              handleFileChange(e.currentTarget.files[0]);
                            }}
                            onBlur={validation.handleBlur}
                            accept=".jpg, .jpeg, .png, .gif, .pdf"
                          />
                          {validation.touched.document &&
                          validation.errors.document ? (
                            <ErrorDisplay
                              errorMsg={validation.errors.document}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="form-group text-right">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={validation.handleSubmit}
                        >
                          Submit
                        </button>
                        <ClearButton
                          type="button"
                          onClick={handleClear}
                          className="m-3"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <PerfectScrollbar
                  style={{
                    height: "250px",
                    paddingRight: "16px",
                    boxSizing: "content-box",
                    borderRadius: "10px",
                    margin: "5px",
                  }}
                >
                  {staffList.length ? (
                    <table
                      className="table table-sm table-hover table-bordered"
                      style={{ width: "100%", borderRadius: "10px" }}
                    >
                      <thead className="thead-light">
                        <tr className="text-center">
                          <th>Sr No</th>
                          <th>Name</th>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAllChecked}
                              onChange={handleSelectAllChange}
                              style={{ cursor: "pointer" }}
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {staffList.map((staff, index) => (
                          <tr className={`text-center`} key={index}>
                            <td>{index + 1}</td>
                            <td className="text-left">
                              {`${staff.first_name} ${staff.last_name}`}
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                checked={staff.checked}
                                onChange={() => handleCheckboxChange(staff.id)}
                                style={{ cursor: "pointer" }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p className="text-center mt-3 text-muted text-capitalize text-xxl">
                      No data available
                    </p>
                  )}
                </PerfectScrollbar>
              </div>
              <div>
                <br></br>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Notice List</h3>
                  </div>
                  <br />
                  <div className="card-body mt-1">
                    <div className="table-responsive">
                      {getAddedNotification &&
                      getAddedNotification.length > 0 ? (
                        <table className="table table-hover table-vcenter table-striped mb-0 table-sm">
                          <thead>
                            <tr className="text-center">
                              <th>Sr No</th>
                              <th>Message</th>
                              <th>Document</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {getAddedNotification.map((notification, index) => (
                              <tr key={index} className="text-center">
                                <td>{index + 1}</td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {notification.message}
                                </td>
                                <td>
                                  {notification.document ? (
                                    <strong className="text-blue font-weight-bold">
                                      <a
                                        href={`${MEDIA_BASE_URL}/${notification.document}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <FaFileDownload
                                          style={{
                                            fontSize: "1.3rem",
                                          }}
                                          className="text-danger"
                                        />
                                      </a>
                                    </strong>
                                  ) : (
                                    <span></span>
                                  )}
                                </td>
                                <td>
                                  <NavLink
                                    to="#"
                                    className="card-options-remove mr-3"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Delete"
                                    onClick={() => onDelete(notification.id)}
                                  >
                                    <i className="fa fa-trash-o text-danger"></i>
                                  </NavLink>{" "}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <h3 className="no-list-message mt-1 text-center">
                          No Notification List available
                        </h3>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNotification;
