import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { BusReportAPI } from "../../../../api/Transport/Report";
import useSessionRedirect from "../../Comman/SessionChange";
import useLocalStorageData from "../../Comman/LocalStorageData";
import { ExportButton } from "../../../CommonComponent/ReusableButton";
import Pagination from "react-js-pagination";

const BusFeeBalanceReport = () => {
  
  const [CurrentPage, setCurrentPage] = useState(1);
  const [Page, setPage] = useState(1);
  const [reportData, setReportData] = useState([]);
  const resultPerPage = 10;
  const { school_id, token, school_emp_id, schoolUser } = useLocalStorageData();
  const session_id = useSessionRedirect();
  const [selectedId, setSelectedId] = useState(null);
  const { classsectionList } = useSelector((state) => state.attendance);
  const data1 = classsectionList?.payload?.map((c) => ({
    value: c.id,
    label: `${c.class_name} - ${c.section}`,
  }));
  console.log(classsectionList?.payload, "data1");
  const handleOptionChange = (selectedOption) => {
    console.log(selectedOption, "selectedOption");
    setSelectedId(selectedOption.value);
  };

  const GetBusFeeData = async () => {
    let body = {
      school_id: school_id,
      academic_id: session_id,
      section_id: selectedId,
    };
    const response = await BusReportAPI.BusFeeBalanceFeeReport(
      body,
      CurrentPage
    );
    const responseData = response.data;
    setReportData(responseData.student_balance_report);
   setPage(responseData.student_balance_report_count);
  };
  useEffect(() => {
    GetBusFeeData();
  }, [selectedId]);
  console.log(reportData, "reportData");

  const handleClear = () => {
    setSelectedId(null);
    setReportData([]);
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center ">
        <div className="header-action m-3">
          <h1 className="page-title ">Bus Fee Balance Report</h1>
        </div>
      </div>
      
      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-5 col-md-6 col-sm-12 my-1">
                  <div className="row">
                    <label className="col-form-label col-md-3">Class</label>
                    <div className="col-md-9">
                      <Select
                        options={data1}
                        placeholder="Select class"
                        value={
                          selectedId
                            ? data1?.find(
                                (option) => option.value == selectedId
                              )
                            : null
                        }
                        onChange={handleOptionChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6 my-1 text-right">
                  {reportData?.length > 0 && (
                    <>
                      <ExportButton id="test-table-xls-button" />
                      <button
                        className="btn btn-outline-danger mx-2"
                        onClick={handleClear}
                      >
                        {" "}
                        <i class="fa fa-times px-1"></i>Clear
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Fee Summary</h3>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    {reportData && reportData?.length > 0 ? (
                      <>
                        <table
                          className="table table-bordered table-hover text-nowrap table-sm"
                          id="table-to-xls"
                        >
                          <thead>
                            <tr className="text-center">
                              <th> Sr No</th>
                              <th>Student Name</th>
                              <th>Student Code</th>
                              <th>Class</th>
                              <th>Balanced Amount</th>
                              <th>Paid Amount</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {reportData?.map((st, dIndex) => {
                              return (
                                <tr key={dIndex}>
                                  <td>{dIndex + 1}</td>
                                  <td className="text-left">
                                    {st.fk_student__fk_student__std_name}
                                  </td>
                                  <td>
                                    {st.fk_student__fk_student__student_code}
                                  </td>
                                  <td>
                                    {st.fk_student__fk_section__fk_class__class_name +
                                      " - " +
                                      st.fk_student__fk_section__section}
                                  </td>
                                  <td className="text-right">
                                    {st.balance_fee}
                                  </td>
                                  <td className="text-right">
                                    {st.paid_amount}
                                  </td>
                                  <td className="text-right">
                                    {st.total_amount}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <ul className="pagination justify-content-center">
                          {reportData?.length > 0 && (
                            <Pagination
                              activePage={CurrentPage}
                              itemsCountPerPage={resultPerPage}
                              totalItemsCount={Page}
                              hideFirstLastPages={false}
                              onChange={(e) => setCurrentPage(e)}
                              nextPageText="Next"
                              prevPageText="Previous"
                              lastPageText="Last"
                              firstPageText="1st"
                              itemClass="page-item"
                              linkClass="page-link"
                              activeClass="active"
                              activeLinkClass="active"
                              hideDisabled={false}
                            />
                          )}
                        </ul>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusFeeBalanceReport;
