import React, { useState } from "react";
import {  useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAlert } from "react-alert";
import { AuthAPI } from "../../api/authAPI";
import CustomPage from "./CustomPage";
import Swal from "sweetalert2";
import Logos from "../../Logo/Logos.svg";

import campuslink_logo from "../../Logo/campuslink_logo.png"

const ConfirmPassword = () => {
  // this is for Confirm Password form
  const alert = useAlert();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const navigate = useNavigate();
  const email = location.state && location.state.email;
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: email,
      newPassword: "",
      confirmPassword: "",
    },

    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("New Password is required.")
        .min(6, "New Password must be at least 6 characters long."),
      confirmPassword: Yup.string()
        .required("Confirm Password is required.")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match."),
    }),

    onSubmit: (values) => {
      conf_pwd(values);
    },
  });

  const conf_pwd = (value) => {
    const { newPassword, confirmPassword } = value;
    // const email = location.state && location.state.email;

    const data = {
      email: email,
      password: newPassword,
    };

    AuthAPI.confirmOTP(data)
      .then((res) => {
        if (res.data.status === 200) {
          Swal.fire({
            text: res.data.msg,
            icon: "success",
            allowOutsideClick: false,
          }).then(() => {
            navigate("/login");
          })
          
        } 
        if (res.data.status === 403 || res.data.status === 503) {
          alert.error(res.data.msg);
        }
      })
      .catch(function (error) {
        // Handle error scenarios
      });
  };

  return (
    <CustomPage>
       {/* <div className="mt-3"> */}
       <div className="mt-3">
      <img
                src={campuslink_logo}
                alt=""
                style={{ width: "20%", margin: "auto" , display: "block"  }}
              /> 
      <form onSubmit={validation.handleSubmit} className="mt-2">
        <h4
          className="text-center"
          style={{ color: "#19bb00", fontWeight: 700 }}
        >
          <span style={{ fontSize: 18, color: "#0E487A" }}>
            SET NEW PASSWORD
          </span>
        </h4>
        <div className="mt-3"> 
          <i
            style={{
              fontSize: 18,
              position: "absolute",
              zIndex: 1,
              marginTop: 16,
              marginLeft: 30,
            }}
            className="fa-solid fa-lock"
          />
          <input
            style={{ padding: "12px 12px 12px 60px", borderRadius: 50 }}
            type="password"
            className="form-control"
            id="newPassword"
            name="newPassword"
            value={validation.values.newPassword}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            placeholder="Enter New Password"
            autoComplete="off"
            required
          />
          {validation.touched.newPassword && validation.errors.newPassword ? (
            <div className="error">{validation.errors.newPassword}</div>
          ) : null}
        </div>

        <div className="mt-3">
          <i
            style={{
              fontSize: 18,
              position: "absolute",
              zIndex: 1,
              marginTop: 16,
              marginLeft: 30,
            }}
            className="fa-solid fa-lock"
          />
          <input
            style={{ padding: "12px 12px 12px 60px", borderRadius: 50 }}
            type="password"
            className="form-control"
            id="confirmPassword"
            name="confirmPassword"
            value={validation.values.confirmPassword}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            placeholder="Enter Confirm Password"
            autoComplete="off"
            required
          />
          {validation.touched.confirmPassword &&
          validation.errors.confirmPassword ? (
            <div className="error">{validation.errors.confirmPassword}</div>
          ) : null}
        </div>

        <div className="text-center mx-auto mt-4">
          <button
            type="submit"
            style={{
              borderRadius: 50,
              backgroundColor: "#19bb00",
              color: "#fff",
              fontWeight: 600,
            }}
            className="btn  btn-block btn-lg mb-3"
            title=""
          >
            Save
          </button>
        </div>
      </form>
      </div>
    </CustomPage>
  );
};

export default ConfirmPassword;
