import React, { useState, useEffect } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import pic1 from "../../../assets/images/dummy.png";
import Swal from "sweetalert2";
import {
  deleteSchool,
  getCity,
  getSchoolRegistrationList,
  registerSchool,
} from "../../../actions/super-user/SchoolRegistration";
import { getAllModule } from "../../../actions/super-user/CommanModule";
import Loader from "../../Loader/Loader";
import { setActiveTabSR } from "../../../actions/TabAction/TabAction";
import ImageUpload from "../../schooladmin/studentRegistration/ImageUpload";
import { getTitle } from "../../CommonComponent/helper";





const SchoolRegistration = () => {
  getTitle("School Registration");
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let super_admin_id = school_user?.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, schooldata, organizationdata, statelist, citylist, error } =
    useSelector((state) => state.schoolRegister);
  const { createdata } = useSelector((state) => state.createSchool);
  const { moduleList } = useSelector((state) => state.module);
  const [selectedState, setSelectedState] = useState("");
  const [files1, setFiles1] = useState(null);
  const [ShowImg, setShowImg] = useState("");
  const [checkedIds, setCheckedIds] = useState([]);
  const [Ids, setIds] = useState(""); 
  const handleSwitchChange = (id) => {
    if (checkedIds.includes(id)) {
      setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id));
    } else {
      setCheckedIds([...checkedIds, id]);
    }
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleSwitchChange1 = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (token) {
      dispatch(getAllModule(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    let getcityasperstate = {
      state_id: selectedState?.value,
    };
    if (token && selectedState?.value) {
      dispatch(getCity(token, getcityasperstate));
    }
  }, [dispatch, token, selectedState?.value]);

  const orgListOption = organizationdata?.payload?.map((org) => {
    return {
      value: org.id,
      label: org.name,
    };
  });
  const stateListOption = statelist?.payload?.map((state) => {
    return {
      value: state.id,
      label: state.name,
    };
  });
  const cityListOption = citylist?.payload?.map((state) => {
    return {
      value: state.id,
      label: state.name,
    };
  });

  const handleState = (e) => {
    setSelectedState(e);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      organization_id: "",
      name: "",
      contact_person: "",
      contact_number: "",
      contact_email: "",
      address: "",
      state_id: "",
      city_id: "",
      pincode: "",
      status: false,
      school_logo: "",
      udise_no: "",
      school_code: "",
      student_initial: "",
      empolyee_initial :"",
      is_government: false,
    },
    validationSchema: Yup.object().shape({
      contact_email: Yup.string()
        .required("Contact Email is required")
        .email("Invalid email format")
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
          "Please enter a valid email address."
        ),
      udise_no: Yup.string()
        .required("Udise number is required")
        .matches(/^[0-9]{11}$/, "Udise No. must be an 11 number"),
        student_initial: Yup.string().required("Student initial is required").matches(/^[a-zA-Z0-9]{3,4}$/, "Student initial must be between 3 to 4 alphanumeric characters"),
        empolyee_initial: Yup.string()
        .required("Employee initial  is required")
        .matches(/^[a-zA-Z0-9]{3,4}$/, "Employee initial must be between 3 to 4 alphanumeric characters"),
      pincode: Yup.string()
        .required("Pincode is required")
        .matches(/^\d{6}$/, "Pincode must be a 6-digit number"),
      contact_number: Yup.string()
        .required("Contact number is required")
        .matches(/^[0-9]{10}$/, "Contact number must be a 10-digit number"),
    }),
    onSubmit: async (values) => {
      if (checkedIds.length === 0) {
        Swal.fire({
          title: "",
          text: "Please select at least one Module.",
          icon: "info",
          allowOutsideClick: false,
        });
      } else {
        const data = new FormData();
        data.append("organization_id", values.organization_id);
        data.append("super_admin_id", super_admin_id);
        data.append("name", values.name);
        data.append("contact_person", values.contact_person);
        data.append("contact_number", values.contact_number);
        data.append("contact_email", values.contact_email);
        data.append("address", values.address);
        data.append("school_logo", values.school_logo);
        data.append("state_id", selectedState?.value);
        data.append("city_id", values.city_id);
        data.append("pincode", values.pincode);
        data.append("status", values.status ? "Active" : "Inactive");
        data.append("udise_no", values.udise_no);
        data.append("school_code", "");
        data.append("student_initial", values.student_initial);
        data.append("empolyee_initial", values.empolyee_initial);
        data.append("is_government", values.is_government ? "True" : "False");
        data.append("modules", JSON.stringify(checkedIds));
        try {
          const res = await dispatch(
            registerSchool(token, data, clearField, navigate)
          );
          setIds(res.school_id);
          const res1 = await dispatch(getSchoolRegistrationList(token));
        } catch (error) {
          console.error(error);
        }
      }
    },
  });

  let clearField = () => {
    validation.resetForm();
    validation.setFieldValue("organization_id", "");
    validation.setFieldValue("state_id", "");
    validation.setFieldValue("city_id", "");
    validation.setFieldValue("school_logo", "");
    setCheckedIds([]);
    setShowImg("");
    setFiles1(null);
  };

  const handleFileRead = async (e) => {
    const file = e.target.files[0];
    validation.setFieldValue("school_logo", file);
    setFiles1(file);
    const base64 = await convertBase64(file);
    setShowImg(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };


  const isDisabled = true;
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">School Registration</h1>
            </div>
            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#Student-all"
                >
                  School List
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="tab-pane active" id="Student-all">
              {loading ? (
                <Loader />
              ) : (
                <div className="table-responsive card ">
                  <table className="table table-hover table-vcenter table-striped text-nowrap table-sm">
                    <thead>
                      <tr className="text-center">
                        <th>Sr.No.</th>
                        <th>logo</th>
                        <th>School Name</th>
                        <th>Contact No.</th>
                        <th>Email</th>
                        <th>State</th>
                        <th>City</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {organizationdata?.payload?.map((org, orgIndex) => (
                        <React.Fragment key={org.id}>
                          {schooldata?.school_list?.some(
                            (school) => school.fk_organization_id === org.id
                          ) && (
                            <tr className="text-white bg-lightgray">
                              <td colSpan="9" className="p-0">
                                {" "}
                                <h6 className="m-auto p-2">{org.name}</h6>
                              </td>
                            </tr>
                          )}

                          {schooldata?.school_list
                            ?.filter(
                              (school) => school.fk_organization_id === org.id
                            )
                            ?.map((school, index) => (
                              <tr key={index}>
                                <td className="text-center">{index + 1}</td>
                                <td className="text-center">
                                  <img
                                    className="avatar"
                                    src={school.school_logo || pic1}
                                    alt="logo"
                                  />
                                </td>
                                <td>
                                  <span className="font-16">{school.name}</span>
                                </td>
                                <td>{school.contact_number}</td>
                                <td>{school.contact_email}</td>
                                <td>{school.state}</td>
                                <td>{school.city}</td>
                                <td className="text-center">{school.status}</td>
                                <td className="text-center">
                                
                                  <NavLink
                                    className="text-muted"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Edit"
                                    to={`/admin/school-details`}
                                    state={{ id: school.id }}
                                    onClick={() =>
                                      dispatch(setActiveTabSR("Basic-info"))
                                    }
                                  >
                                    <i className="fa fa-edit"></i>
                                  </NavLink>
                                </td>
                              </tr>
                            ))}
                        </React.Fragment>
                      ))}

                      {/* {schooldata?.school_list?
                      } */}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            <div className="tab-pane" id="Student-add">
              <div className="row clearfix">
                <div className="col-lg-2 col-md-12 col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <ul className="nav nav-tabs flex-column py-2">
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              data-toggle="tab"
                              href="#Basic-info"
                            >
                              {" "}
                              Basic Info
                            </a>
                          </li>

                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                isDisabled ? " disabled" : ""
                              }`}
                              data-toggle="tab"
                              href="#Fees-heads"
                            >
                              Fees Heads
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                isDisabled ? " disabled" : ""
                              }`}
                              data-toggle="tab"
                              href="#Classes-section"
                            >
                              {" "}
                              Classes & Section
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                isDisabled ? " disabled" : ""
                              }`}
                              data-toggle="tab"
                              href="#Fees-structure"
                            >
                              Fees Structure
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                isDisabled ? " disabled" : ""
                              }`}
                              data-toggle="tab"
                              href="#UserAuth"
                            >
                              Authorization
                            </a>
                          </li>

                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                isDisabled ? " disabled" : ""
                              }`}
                              data-toggle="tab"
                              href="#Hostel"
                            >
                              Hostel Registration
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-10 col-md-12 col-sm-12">
                  <div className="tab-content">
                    <div className="tab-pane active" id="Basic-info">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="card">
                          <form
                            className="card-body"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            <div className="row">
                              <div className="col-lg-8">
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    Organization
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <Select
                                      name="organization_id"
                                      options={orgListOption}
                                      placeholder="Select "
                                      onChange={(option) =>
                                        validation.setFieldValue(
                                          "organization_id",
                                          option.value
                                        )
                                      }
                                      onBlur={validation.handleBlur(
                                        "organization_id"
                                      )}
                                      value={
                                        validation.values.organization_id
                                          ? orgListOption?.find(
                                              (option) =>
                                                option.value ===
                                                validation.values
                                                  .organization_id
                                            )
                                          : null
                                      }
                                      isSearchable={true}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    School Name
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter School Name"
                                      name="name"
                                      value={validation.values.name}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      required
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    className="col-lg-3 col-form-label"
                                    htmlFor="udisnumber"
                                  >
                                    U-DISE No
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="udisnumber"
                                      type="text"
                                      className="form-control"
                                      placeholder="Please Enter U-DISE Number"
                                      name="udise_no"
                                      value={validation.values.udise_no}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (
                                          /^\d*$/.test(inputValue) &&
                                          inputValue.length <= 12
                                        ) {
                                          validation.handleChange(e);
                                        }
                                      }}
                                      onBlur={validation.handleBlur}
                                      required
                                      autoComplete="off"
                                      maxLength={11}
                                      inputMode="numeric"
                                    />
                                    {validation.touched.udise_no &&
                                      validation.errors.udise_no && (
                                        <div className="error-message">
                                          {validation.errors.udise_no}
                                        </div>
                                      )}
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label
                                    className="col-lg-3 col-form-label"
                                    htmlFor="schoolcode"
                                  >
                                    Student Initial
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="schoolcode"
                                      type="text"
                                      className="form-control"
                                      placeholder="Please Enter Student Initial"
                                      name="student_initial"
                                      value={validation.values.student_initial}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      required
                                      autoComplete="off"
                                      maxLength={4}
                                
                                    />
                                    {validation.touched.student_initial &&
                                      validation.errors.student_initial && (
                                        <div className="error-message">
                                          {validation.errors.student_initial}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    className="col-lg-3 col-form-label"
                                    htmlFor="EmpolyeeInitial"
                                  >
                                   Empolyee Initial
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="EmpolyeeInitial"
                                      type="text"
                                      className="form-control"
                                      placeholder="Please Enter Empolyee Initial"
                                      name="empolyee_initial"
                                      value={validation.values.empolyee_initial}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      required
                                      autoComplete="off"
                                      maxLength={4}
                                    />
                                    {validation.touched.empolyee_initial &&
                                      validation.errors.empolyee_initial && (
                                        <div className="error-message">
                                          {validation.errors.empolyee_initial}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    Government{" "}
                                    <span className="text-danger">*</span>
                                  </label>

                                  <div className="col-lg-9 d-flex  align-items-center">
                                    <label className="custom-switch pr-2">
                                      <input
                                        type="checkbox"
                                        className="custom-switch-input"
                                        name="is_government"
                                        checked={
                                          validation.values.is_government
                                        }
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                      />
                                      <span className="custom-switch-indicator"></span>
                                    </label>
                                    {validation.values.is_government ? (
                                      <b>Yes</b>
                                    ) : (
                                      <b>No</b>
                                    )}
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    Address
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Address"
                                      name="address"
                                      value={validation.values.address}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    State <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <Select
                                      options={stateListOption}
                                      placeholder="Select "
                                      value={selectedState}
                                      onChange={handleState}
                                      isSearchable={true}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    City <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <Select
                                      name="city_id"
                                      options={cityListOption}
                                      placeholder="Select "
                                      // value={selectedOptions}
                                      // onChange={handleSelect}
                                      onChange={(option) =>
                                        validation.setFieldValue(
                                          "city_id",
                                          option.value
                                        )
                                      }
                                      onBlur={validation.handleBlur("city_id")}
                                      value={
                                        validation.values.city_id
                                          ? cityListOption?.find(
                                              (option) =>
                                                option.value ===
                                                validation.values.city_id
                                            )
                                          : null
                                      }
                                      isSearchable={true}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    Pincode{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Pincode"
                                      name="pincode"
                                      value={validation.values.pincode}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (
                                          /^\d*$/.test(inputValue) &&
                                          inputValue.length <= 6
                                        ) {
                                          validation.handleChange(e);
                                        }
                                      }}
                                      onBlur={validation.handleBlur}
                                      maxLength={6}
                                      required
                                    />
                                    {validation.touched.pincode &&
                                      validation.errors.pincode && (
                                        <div className="error-message">
                                          {validation.errors.pincode}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    Status{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  {/* <div className="col-md-9">
                                    <Select
                                      name="status"
                                      options={statusList}
                                      placeholder="Select "
                                      // value={selectedOptions}
                                      // onChange={handleSelect}
                                      onChange={(option) =>
                                        validation.setFieldValue(
                                          "status",
                                          option.label
                                        )
                                      }
                                      onBlur={validation.handleBlur("status")}
                                      value={statusList.find(
                                        (option) =>
                                          option.label ===
                                          validation.values.status
                                      )}
                                      isSearchable={true}
                                      required
                                    />
                                  </div> */}
                                  <div className="col-lg-9 d-flex  align-items-center">
                                    <label className="custom-switch pr-2">
                                      <input
                                        type="checkbox"
                                        className="custom-switch-input"
                                        name="status"
                                        checked={validation.values.status}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                      />
                                      <span className="custom-switch-indicator"></span>
                                    </label>
                                    {validation.values.status ? (
                                      <b>Yes</b>
                                    ) : (
                                      <b>No</b>
                                    )}
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    Contact Person{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Contact Person"
                                      name="contact_person"
                                      value={validation.values.contact_person}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    Contact Number
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Contact Number"
                                      name="contact_number"
                                      value={validation.values.contact_number}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (
                                          /^\d*$/.test(inputValue) &&
                                          inputValue.length <= 10
                                        ) {
                                          validation.handleChange(e);
                                        }
                                      }}
                                      onBlur={validation.handleBlur}
                                      required
                                      maxLength={10}
                                    />
                                    {validation.touched.contact_number &&
                                      validation.errors.contact_number && (
                                        <div className="error-message">
                                          {validation.errors.contact_number}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    Contact Email{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Enter Contact Email"
                                      name="contact_email"
                                      value={validation.values.contact_email}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      required
                                    />
                                    {validation.touched.contact_email &&
                                    validation.errors.contact_email ? (
                                      <div className="error-message">
                                        {validation.errors.contact_email}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                {/* <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    School Logo
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      type="file"
                                      name="school_logo"
                                      className="dropify"
                                      accept="image/*"
                                      onChange={handleFileRead}
                                      required
                                    />

                                    
                                  </div>
                                </div> */}
                              </div>
                              <div className="col-lg-4 d-flex flex-column">
                                <ImageUpload
                                  formik={validation}
                                  fieldName="school_logo"
                                  showImg={ShowImg}
                                  setShowImg={setShowImg}
                                />

                                <div>
                                  <h6 className="font-18 font-weight-bold mt-2 text-muted">
                                    Module Access{" "}
                                    <span className="text-danger">*</span>
                                  </h6>
                                  <ul className="setting-list list-unstyled mt-1 setting_switch">
                                    {moduleList?.payload?.map(
                                      (module, index) => (
                                        <li key={module.id}>
                                          <label className="custom-switch">
                                            <span
                                              className="custom-switch-description"
                                              htmlFor={`flexSwitchCheckDefault-${module.id}`}
                                            >
                                              {module.name}
                                            </span>
                                            <input
                                              type="checkbox"
                                              className="custom-switch-input"
                                              name="custom-switch-checkbox"
                                              id={`flexSwitchCheckDefault-${module.id}`}
                                              checked={checkedIds.includes(
                                                module.id
                                              )}
                                              onChange={() =>
                                                handleSwitchChange(module.id)
                                              }
                                            />
                                            <span className="custom-switch-indicator"></span>
                                          </label>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 justify-content-center align-item-center mb-3">
                              <button
                                type="submit"
                                className="mr-1 btn btn-primary px-5"
                              >
                                Save
                              </button>
                              {/* <button
                                    type="submit"
                                    className="btn btn-outline-secondary px-5"
                                  >
                                    Cancel
                                  </button> */}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SchoolRegistration;
