import React, { useMemo } from "react";
import { useTable } from "react-table";
import pic1 from "../../../assets/images/dummy.png";
import img from "../../../assets/image/rotation-lock.png";
import { NavLink } from "react-router-dom";
const StaffTable = (props) => {
  // school user can see all the staff from here
  const { data, onDelete, handleResetPassword } = props;
  
  const columns = React.useMemo(
    () => [
      {
        Header: "Sr.No.",
        // Cell: ({ row }) => row.index + 1,
        Cell: ({ row }) => <div className="text-center">{row.index + 1}</div>,
      },
      {
        Header: "Logo",
        accessor: "profile",
        Cell: ({ cell: { value } }) => (
          <img
            src={value || pic1}
            alt="Profile"
            width="50"
            className="avatar"
          />
        ),
      },
      {
        Header: "Emp_No",
        accessor: "emp_no",
      },
      {
        Header: "Name",
        accessor: "first_name",
        Cell: ({ row }) =>
          `${row.original.first_name} ${row.original.last_name}`,
      },
      {
        Header: "Contact No.",
        accessor: "mobile_no",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Qualification",
        accessor: "qualification",
        Cell: ({ cell: { value } }) => (
          <div className="text-center">{value}</div>
        ),
      },
      {
        Header: "User Type",
        accessor: "user_type",
        Cell: ({ cell: { value } }) => (
          <div className="text-center">{value}</div>
        ),
      },

      {
        Header: "Action",
        accessor: "id",
        Cell: ({ cell: { value, row } }) => (
          <span className="d-flex justify-content-between align-items-center">
            <NavLink
              to="#"
              className="card-options-remove"
              // data-toggle="card-remove"
              data-toggle="tooltip"
              data-placement="top"
              title="Delete"
              onClick={() => onDelete(value)}
            >
              <i className="fa fa-trash-o text-danger"></i>
            </NavLink>
            <NavLink
              className="text-muted"
              to={`/school/edit-staff-master`}
              state={{ id: value }}
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
            >
              <i className="fa fa-edit"></i>
            </NavLink>
            {row.original.user_type === "User" && (
              <NavLink className="" onClick={() => handleResetPassword(value)}  data-toggle="tooltip"
              data-placement="top"
              title="Password Reset">
                <img src={img} alt="Edit" width={16} />
              </NavLink>
            )}
          </span>
        ),
      },
    ],
    []
  );

  // const data = useMemo(()=> data ,[])
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <div className="table-responsive card col-lg-12 col-md-12 col-sm-12">
      <table
        {...getTableProps()}
        className="table table-striped table-hover table-vcenter text-nowrap table-sm"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr className="text-center" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default StaffTable;
