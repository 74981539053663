import React, { useEffect, useState } from "react";
import useLocalStorageData from "../../Comman/LocalStorageData";
import useSessionRedirect from "../../Comman/SessionChange";
import useLoading from "../../../CommonComponent/useLoading";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import StaffList from "./StaffList";
import { getStaffList } from "../../../../actions/school_user/StaffMaster";
import HostelAPI from "../../../../api/Hostel/HostelRegistration";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";
import Swal from "sweetalert2";
import { scrollToTop } from "../../../CommonComponent/helper";
import Loader from "../../../Loader/Loader";
import EmptyListMessage from "../../../CommonComponent/EmptyListMessage";
import PageRedirect from "../../../superadmin/Comman/PageRedirect";

const HostelRegistration = (props) => {
  const { schoolId, token, school_emp_id } = props;
  const insertBy = PageRedirect();
  // const { school_id, token, school_emp_id } = useLocalStorageData();
  // const session_id = useSessionRedirect();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const { masterData } = useSelector((state) => state.masterData);
  const { loading, error, staffdata } = useSelector(
    (state) => state.staffMaster
  );
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [editHostel, setEditHostel] = useState([]);
  const [isStaff, setIsStaff] = useState([]);
  const [hostelData, setHostelData] = useState([]);
  useEffect(() => {
    const fetchStaff = () => {
      if (token) {
       
        dispatch(getStaffList(token, schoolId));
        // console.log("getStaffList");
      }
    };
    fetchStaff();
  }, [dispatch, token, schoolId]);

  let hostelTypeList = masterData?.payload
    ?.filter((obj) => obj.name === "Hostel Type")
    .map((n) => ({
      value: n.id,
      label: n.value,
    }));
  const fetchData = async () => {
    startLoading();
    try {
      let data = {
        school_id: schoolId,
      };
      const response = await HostelAPI.getHostelList(data);
      let responseData = response.data;
      setHostelData(responseData.hostelList);
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      school_id: schoolId,
      insert_by: school_emp_id,
      hostelRegisterId: editHostel?.id ?? "",
      staff_id: "",
      hostelTypeId: editHostel?.fk_hostelType ?? "",
      hostelName: editHostel?.hostelName ?? "",
      hostelAddress: editHostel?.hostelAddress ?? "",
      contactNo: editHostel?.contactNo ?? "",
    },
    validationSchema: Yup.object().shape({
      hostelName: Yup.string().required("Hostel Type is required."),
      hostelTypeId: Yup.string().required("Hostel Name is required."),
      hostelAddress: Yup.string().required("Hostel Address is required."),
      contactNo: Yup.string()
        .matches(/^[0-9]{10}$/, "Invalid Contact No.")
        .required("Contact No. is required."),
    }),
    onSubmit: async (values) => {
      startLoading();
      try {
        const data = new FormData();
        data.append("school_id", values.school_id);
        data.append("insert_by", values.insert_by);
        data.append("hostelRegisterId", values.hostelRegisterId);
        data.append("staff_id", isStaff?.id ? isStaff?.id : "");
        data.append("hostelTypeId", values.hostelTypeId);
        data.append("hostelName", values.hostelName);
        data.append("hostelAddress", values.hostelAddress);
        data.append("contactNo", values.contactNo);

        const response = await HostelAPI.registerHostel(data);
        let responseData = response.data;

        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            allowOutsideClick: false,
          });
          fetchData();
          handleClear();
        } else if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        stopLoading();
      }
    },
  });
  const handleClear = () => {
    formik.resetForm();
    setIsStaff([]);
    setEditHostel([]);
    setIsEdit(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const onEdit = (data) => {
    setEditHostel(data);
    let s_data = staffdata?.payload?.find((s) => s.id === data.fk_warden);
    setIsStaff(s_data);
    setIsEdit(true);
    scrollToTop();
  };
  const onDelete = (id) => {
    let hostel_Id = {
      hostelId: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await HostelAPI.deleteHostel(hostel_Id);
        let responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            imageAlt: "success image",
          });
          fetchData();
          handleClear();
        }
        if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            imageAlt: "error image",
          });
        }
      }
    });
  };
  if (isLoading) return <Loader />;
  return (
    // <div className="section-body">
    //   <div className="container-fluid"></div>
    // </div>
    <form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
        return false;
      }}
    >
      <div className="d-flex justify-content-between align-items-center ">
        <div className="header-action">
          <h1 className="page-title">Hostel Registration</h1>
        </div>
        <div className=" mt-2">
          <button
            type="button"
            className="btn btn-outline-secondary btn-sm mx-1"
            onClick={() => handleClear()}
          >
            <i className="fa fa-pencil px-1"></i>
            New
          </button>
          <button className="btn btn-primary btn-sm mx-1" type="submit">
            <i className="fa fa-plus px-1"></i>
            {isEdit ? "Update" : "Save"}
          </button>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
        <div className="card">
          <div className="card-body row">
            <div className="col-lg-9 col-md-12">
              <div className="form-group col-md-12 row">
                <label className="col-md-3 col-form-label">
                  Hostel Type <span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <Select
                    name="hostelTypeId"
                    placeholder="Hostel Type"
                    options={hostelTypeList}
                    value={
                      formik.values.hostelTypeId
                        ? hostelTypeList?.find(
                            (option) =>
                              option.value === formik.values.hostelTypeId
                          )
                        : null
                    }
                    onChange={(selectedOptions) => {
                      formik.setFieldValue(
                        "hostelTypeId",
                        selectedOptions.value
                      );
                    }}
                    onBlur={formik.handleBlur}
                    isSearchable={true}
                  />
                  {formik.touched.hostelTypeId && formik.errors.hostelTypeId ? (
                    <ErrorDisplay errorMsg={formik.errors.hostelTypeId} />
                  ) : null}
                </div>
              </div>
              <div className="form-group col-md-12 row">
                <label className="col-md-3 col-form-label">
                  Hostel Name <span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Hostel Name"
                    name="hostelName"
                    value={formik.values.hostelName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.touched.hostelName && formik.errors.hostelName ? (
                    <ErrorDisplay errorMsg={formik.errors.hostelName} />
                  ) : null}
                </div>
              </div>
              <div className="form-group col-md-12 row">
                <label className="col-md-3 col-form-label">
                  Hostel Address <span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Hostel Address"
                    name="hostelAddress"
                    value={formik.values.hostelAddress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.touched.hostelAddress &&
                  formik.errors.hostelAddress ? (
                    <ErrorDisplay errorMsg={formik.errors.hostelAddress} />
                  ) : null}
                </div>
              </div>
              <div className="form-group col-md-12 row">
                <label className="col-md-3 col-form-label">
                  Contact No <span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Contact No"
                    name="contactNo"
                    value={formik.values.contactNo}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                    maxLength={10}
                  />
                  {formik.touched.contactNo && formik.errors.contactNo ? (
                    <ErrorDisplay errorMsg={formik.errors.contactNo} />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12"></div>
          </div>
        </div>
      </div>

      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Warden Detail</h3>
          </div>
          <div className="card-body">
            <div className="">
              <div className="form-group col-md-12 row">
                <label className="col-md-3 col-form-label">
                  Name <span className="text-danger">*</span>
                </label>
                <div className="col-md-9 row">
                  <div className="col-md-4">
                    <div className="input-group-append">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="No."
                        value={isStaff?.emp_no ? isStaff?.emp_no : ""}
                        readOnly
                      />
                      <StaffList
                        data={staffdata?.payload}
                        setIsStaff={setIsStaff}
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    name="group_name"
                    className="form-control col-md-8"
                    placeholder="Name"
                    value={
                      isStaff?.first_name
                        ? `${isStaff?.first_name} ${isStaff?.last_name}`
                        : ""
                    }
                    readOnly
                  />
                </div>
              </div>
              <div className="form-group col-md-12 row">
                <label className="col-md-3 col-form-label">
                  Address <span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Address"
                    name="group_name"
                    value={isStaff?.address ? isStaff?.address : ""}
                    readOnly
                  />
                </div>
              </div>
              <div className="form-group col-md-12 row">
                <label className="col-md-3 col-form-label">
                  Contact No <span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Contact No"
                    name="mobile_no"
                    value={isStaff?.mobile_no ? isStaff?.mobile_no : ""}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title"> List of hostel</h3>
          </div>
          <div className="card-body">
            {hostelData?.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-hover table-vcenter table-striped table-sm text-nowrap">
                  <thead>
                    <tr className="text-center">
                      <th>Sr No</th>
                      <th>Hostel Type</th>
                      <th>Hostel Name</th>
                      <th>Hostel Contact</th>
                      <th>Warden Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hostelData?.map((hostel, index) => (
                      <tr key={index} className="text-center">
                        <td>{index + 1}</td>
                        <td className="text-left text-wrap">
                          {hostel.fk_hostelType__value}
                        </td>
                        <td className="text-left">{hostel.hostelName}</td>
                        <td>{hostel.contactNo}</td>
                        <td className="text-left">{`${hostel.fk_warden__first_name} ${hostel.fk_warden__last_name}`}</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-icon text-muted btn-sm"
                            title="Edit"
                            onClick={() => onEdit(hostel)}
                          >
                            <i className="fa fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-icon btn-sm"
                            title="Delete"
                            onClick={() => onDelete(hostel.id)}
                          >
                            <i class="fa fa-trash-o text-danger"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <EmptyListMessage message={"Hostel Not Available !"} Div="div" />
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default HostelRegistration;
