import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import useSessionRedirect from "../../Comman/SessionChange";
import useLocalStorageData from "../../Comman/LocalStorageData";
import { useFormik } from "formik";
import * as Yup from "yup";
import groupClassMapAPI from "../../../../api/Examination/GroupClassMapping";
import Swal from "sweetalert2";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";
import useLoading from "../../../CommonComponent/useLoading";
import Loader from "../../../Loader/Loader";
import { ExamsAPI } from "../../../../api/Examination/Exam";
import { useDispatch, useSelector } from "react-redux";
import { markEntryAPI } from "../../../../api/Examination/MarkEntry";
import { Table } from "reactstrap";

const MarkEntry = () => {
  // teacher or school user can add or delete marks or edit
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const session_id = useSessionRedirect();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const dispatch = useDispatch();
  const { classsectionList } = useSelector((state) => state.attendance);
  const [classWiseExamList, setClassWiseExamList] = useState([]);
  const [classWiseCategoryList, setClassWiseCategoryList] = useState([]);
  const [isMarking, setIsMarking] = useState(null);
  const [Subject, setSubject] = useState([]);
  const [StudentList, setStudentList] = useState([]);
  let [validationError, setValidationError] = useState(false);

  let classListOption = classsectionList?.payload?.map((c) => ({
    label: `${c.class_name} ${c.section}`,
    value: c.id,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      academic_id: session_id,
      school_id: school_id,
      insert_by: school_emp_id,
      exam_CategoryId: null,
      exam_id: null,
      section_id: null,
      subject_marks: [],
    },
    validationSchema: Yup.object().shape({
      section_id: Yup.string().required("This field is required."),
      exam_id: Yup.string().required("This field is required."),
      exam_CategoryId: Yup.string().required("This field is required."),
    }),
    onSubmit: async (values) => {
      let subList = StudentList?.map((stu) => ({
        student_id: stu.student_id,
        subject_detail: stu.subject_detail
          ?.map((sub) => ({
            subjectCategory_id: sub.subjectCategory_id,
            marks_grade: sub.marks_grade,
          }))
          .filter((subject) => subject.marks_grade !== ""),
      }));
      subList = subList?.filter((stu) => stu.subject_detail.length > 0);
      startLoading();
      try {
        const data = new FormData();
        data.append("academic_id", values.academic_id);
        data.append("school_id", values.school_id);
        data.append("insert_by", values.insert_by);
        data.append("exam_CategoryId", values.exam_CategoryId);
        data.append("exam_id", values.exam_id);
        data.append("section_id", values.section_id);
        data.append("subject_marks", JSON.stringify(subList));

        const response = await markEntryAPI.createMarkEntry(data);
        let responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            allowOutsideClick: false,
          });

          handleClear();
        } else if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        stopLoading();
      }
    },
  });
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 992); // 992px is the breakpoint for col-md in Bootstrap
    };

    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set the initial state
    handleResize();

    // Remove the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClear = () => {
    formik.resetForm();
    setStudentList([]);
    setSubject([]);
    setIsMarking(null);
    setClassWiseCategoryList([]);
    setClassWiseExamList([]);
  };

  const fetchExamClassWise = async () => {
    try {
      let body = {
        school_id: school_id,
        academic_id: session_id,
        section_id: formik.values.section_id,
      };
      let response = await markEntryAPI.getClassWiseExamsList(body);
      let res = await markEntryAPI.getClassWiseExamCategory(body);
      let responseData = response.data;
      let responseCat = res.data;
      if (responseData.status === 200) {
        let list = responseData?.exam_list?.map((c) => ({
          label: `${c.exam_name} `,
          value: c.exam_id,
        }));
        setClassWiseExamList(list);
      }
      if (responseCat.status === 200) {
        let list = responseCat?.exam_category?.map((c) => ({
          label: `${c.examCategoryName} `,
          value: c.examCategoryId,
        }));
        setClassWiseCategoryList(list);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (formik.values.section_id) {
      fetchExamClassWise();
    }
  }, [formik.values.section_id]);

  let validationControl = async () => {
    let Validationstatus = true;
    if (!formik.values.exam_id) {
      Validationstatus = false;
    } else if (!formik.values.section_id) {
      Validationstatus = false;
    } else if (!formik.values.exam_CategoryId) {
      Validationstatus = false;
    }
    if (!Validationstatus) {
      setValidationError(true);
      setTimeout(() => {
        setValidationError(false);
      }, 5000);
    }
    return Validationstatus;
  };

  const fetchMarkEntry = async () => {
    let isValid = await validationControl();
    if (!isValid) return;
    startLoading();
    try {
      let body = {
        school_id: school_id,
        academic_id: session_id,
        section_id: formik.values.section_id,
        exam_id: formik.values.exam_id,
        exam_CategoryId: formik.values.exam_CategoryId,
      };
      let response = await markEntryAPI.getMarkEntryListClassWise(body);
      let responseData = response.data;
      if (responseData.status === 200) {
        setIsMarking(responseData.marking);
        setSubject(responseData.subject_list);
        setStudentList(responseData.mark_Entry);
      }
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };

  const handleInputChange = (stuIndex, subIndex, value) => {
    const updateSubject = [...StudentList];
    updateSubject[stuIndex].subject_detail[subIndex].marks_grade =
      value.toUpperCase();
    setStudentList(updateSubject);
  };
  const handleClearMark = () => {
    setStudentList([]);
    setSubject([]);
    setIsMarking(null);
  };
  const handleSelectSection = (selectedOptions) => {
    formik.setFieldValue("section_id", selectedOptions?.value || "");
    setStudentList([]);
    setSubject([]);
    setIsMarking(null);
    setClassWiseExamList([]);
    setClassWiseCategoryList([]);
    formik.setFieldValue("exam_id", null);
    formik.setFieldValue("exam_CategoryId", null);
  };

  if (isLoading) return <Loader />;
  return (
    <div className="section-body">
      <div className="container-fluid">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Mark Entry</h1>
            </div>
            <div className=" mt-2">
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm mx-1"
                onClick={() => fetchMarkEntry()}
              >
                <i className="fa fa-eye  px-1"></i>
                View
              </button>
              <button
                className="btn btn-primary btn-sm mx-1"
                type="submit"
                // disabled={examWiseSubject?.length > 0 ? false : true}
              >
                <i className="fa fa-plus px-1"></i>
                Save
              </button>

              <button
                type="button"
                className="btn btn-outline-danger btn-sm mx-1"
                onClick={() => handleClear()}
              >
                <i className="fa fa-times px-1"></i>
                Clear
              </button>
            </div>
          </div>
          <div className="col-lg-12  col-sm-12 mt-4">
            <div className="card">
              <div className="card-body row">
                <div className="col-lg-4 ">
                  <div className="form-group  row">
                    <label className="col-md-3 col-form-label">
                      Class <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <Select
                        name="section_id"
                        placeholder="Select  Class"
                        options={classListOption}
                        value={
                          formik.values.section_id
                            ? classListOption?.find(
                                (g) => g.value === formik.values.section_id
                              )
                            : null
                        }
                        onChange={handleSelectSection}
                        onBlur={formik.handleBlur}
                        isSearchable={true}
                      />
                      {formik.touched.section_id && formik.errors.section_id ? (
                        <ErrorDisplay errorMsg={formik.errors.section_id} />
                      ) : null}
                      {validationError && !formik.values.section_id ? (
                        <ErrorDisplay errorMsg={"Class is required"} />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 ">
                  <div className="form-group  row">
                    <label className="col-md-3 col-form-label">
                      Exam <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <Select
                        name="exam_id"
                        placeholder="Select Exam "
                        options={classWiseExamList}
                        value={
                          formik.values.exam_id
                            ? classWiseExamList?.find(
                                (g) => g.value === formik.values.exam_id
                              )
                            : null
                        }
                        onChange={(selectedOptions) => {
                          formik.setFieldValue(
                            "exam_id",
                            selectedOptions?.value || ""
                          );
                          handleClearMark();
                        }}
                        onBlur={formik.handleBlur}
                        isSearchable={true}
                      />
                      {formik.touched.exam_id && formik.errors.exam_id ? (
                        <ErrorDisplay errorMsg={formik.errors.exam_id} />
                      ) : null}
                      {validationError && !formik.values.exam_id ? (
                        <ErrorDisplay errorMsg={"Exam is required"} />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className={`col-lg-4 px-1 ${isSmallScreen ? "" : "p-0"}`}>
                  <div className={`form-group  row ${isSmallScreen ? "" : "p-0"}`}>
                    <label className={`col-md-3 col-form-label ${isSmallScreen ? "" : "px-0"}`}>
                      Category <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <Select
                        name="exam_CategoryId"
                        placeholder="Select Category"
                        options={classWiseCategoryList}
                        value={
                          formik.values.exam_CategoryId
                            ? classWiseCategoryList?.find(
                                (g) => g.value === formik.values.exam_CategoryId
                              )
                            : null
                        }
                        onChange={(selectedOptions) => {
                          formik.setFieldValue(
                            "exam_CategoryId",
                            selectedOptions?.value || ""
                          );
                          handleClearMark();
                        }}
                        onBlur={formik.handleBlur}
                        isSearchable={true}
                      />
                      {formik.touched.exam_CategoryId &&
                      formik.errors.exam_CategoryId ? (
                        <ErrorDisplay
                          errorMsg={formik.errors.exam_CategoryId}
                        />
                      ) : null}
                      {validationError && !formik.values.exam_CategoryId ? (
                        <ErrorDisplay errorMsg={"Category is required"} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 table-responsive">
            {StudentList && StudentList.length > 0 ? (
              <Table
                size="sm"
                className="table-bordered table-vcenter table-striped text-nowrap"
                striped
              >
                <thead className="">
                  <tr className="text-center text-nowrap ">
                    <th>Sr No.</th>
                    <th>Roll No</th>
                    <th>Student Name</th>
                    {Subject?.map((s, i) => (
                      <th key={i}>
                        {s.subject_name}
                        {isMarking === "Grade" ? (
                          <small className="form-text">{`[ Grade ]`}</small>
                        ) : (
                          <small className="form-text">{`[ Max Marks ${s.max_marks}]`}</small>
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {StudentList?.map((stu, stuIndex) => (
                    <tr
                      key={stuIndex}
                      className="text-center clickable-row-color"
                    >
                      <td>{stuIndex + 1}</td>
                      <td>{stu.roll_no}</td>
                      <td className="text-left">{stu.student_name}</td>
                      {stu.subject_detail?.map((sub, subIndex) => {
                        const existSubject = Subject?.find(
                          (s) => s.subject_id === sub.subject_id
                        );
                        if(existSubject){
                          return (
                            <td key={subIndex}>
                              <input
                                className="form-control"
                                type="text"
                                value={sub.marks_grade}
                                onChange={(e) => {
                                  const inputText = e.target.value;
                                  const enterValue =
                                    isMarking === "Grade"
                                      ? inputText.toUpperCase()
                                      : inputText;
                                  if (
                                    (isMarking === "Grade" &&
                                      /^[a-zA-Z]{0,1}$/.test(enterValue)) ||
                                    (isMarking !== "Grade" &&
                                      /^\d{0,3}$/.test(enterValue)) && (enterValue) <=existSubject.max_marks
                                  ) {
                                    handleInputChange(
                                      stuIndex,
                                      subIndex,
                                      e.target.value
                                    );
                                  }
                                }}
                                placeholder={`${sub.subject_name}`}
                              />
                            </td>
                          );
                        }
                       
                      })}
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              ""
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default MarkEntry;
