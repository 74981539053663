import React from "react";
import b1 from "../../assets/image/b1.png";
import Footer from "../Layout/Footer";
const CustomPage = ({ children }) => {
  return (
    <div
      style={{ overflow: "hidden", background: "" }}
      className="resp-height "
    >
      <div
        className="d-flex flex-column "
        style={{
          height: "100%",
          background:
            "linear-gradient(145deg, #45aaf2, #6435c9)",
        }}
      >
        <div
          className="col-xl-8 col-lg-10 col-md-10 col-11 box-margin mt-5"
          style={{
            margin: "auto",
            background:
              "rgba(255, 255, 255, 0.6)",
            border: 0,
            borderRadius: 8,
          }}
        >
          <div className="row px-md-5"></div>
          <div className="row px-md-5 px-3 py-lg-5 py-3">
          <div className="col-lg-6 px-md-5 px-3 mt-lg-0 mt-5">{children}</div>
            <div className="col-lg-6 px-md-5 pc-2 d-flex justify-content-center flex-column">
              <h1
                className="text-center  login_font py-3"
                style={{ color: "#0E487A", fontWeight: 900, fontSize: "2rem" }}
              >
                campuslink.pro
              </h1>
              <img
                src={b1}
                alt=""
                style={{ width: "100%", borderRadius: "2rem" }}
              />
            </div>
            
          </div>
        </div>
        <Footer nonAuth={true}/>
      </div>
    </div>
  );
};

export default CustomPage;
