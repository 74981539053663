import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Swal from "sweetalert2";
import { useAlert } from "react-alert";
import { allUsers } from "../../../../actions/users/UsersAction";
import { UsersAPI } from "../../../../api/usersAPI";
import Loader from "../../../Loader/Loader";
import {
  addStaffUser,
  getStaffUser,
} from "../../../../actions/school_user/StaffUserMaster";
import AddUserMaster from "./AddUserMaster";
import { getStaffList } from "../../../../actions/school_user/StaffMaster";
import ShowUserMaster from "./ShowUserMaster";

// import UserDetails from "./UserDetails";

const StaffUserMaster = () => {
  // this file is for the user master
  const { id } = useParams();
  console.log(id);
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { user_type, name, user_detail } = school_user;
  const school_id = user_detail?.fk_school_id;
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { loading, error, staffdata, createstaff } = useSelector(
    (state) => state.staffMaster
  );
  const { StaffUser } = useSelector((state) => state.getStaffUser);

  useEffect(() => {
    const body = {
      school_id: school_id,
    };
    if (token) {
      dispatch(getStaffList(token, school_id));
      dispatch(getStaffUser(body));
    }
  }, [dispatch, token]);
console.log(StaffUser)
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">User Master</h1>
            </div>
            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <a
                  className={!id ? "nav-link active" : "nav-link"}
                  data-toggle="tab"
                  href="#Fees-all"
                >
                  User List
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={id ? "nav-link active" : "nav-link"}
                  data-toggle="tab"
                  href="#Fees-add"
                >
                  {id ? "Update" : "Add"} User
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="tab-content">
            <ShowUserMaster
              staffdata={StaffUser?.payload}
              id={id}
              navigate={navigate}
              dispatch={dispatch}
            />
            <AddUserMaster
              user_detail={user_detail}
              staffdata={staffdata?.payload}
              staffUser={StaffUser?.payload}
              id={id}
              school_id={school_id}
              navigate={navigate}
              dispatch={dispatch}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default StaffUserMaster;
