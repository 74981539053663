import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "./ChartsDynamicColor";

const BarChart = ({ dataColors, balance, paid, heads_name, chartFor , is_holiday}) => {



  console.log(balance, paid, heads_name, chartFor , is_holiday)
  const spineareaChartColors = getChartColorsArray(dataColors);


 const headLabel=heads_name?.map((str)=> str?.length > 10 ? `${str.slice(0, 15)}...` : str
 )
 const series = [
  {
    name: chartFor === "Attendance" ? "Absent" : "Balance",
    data: balance,
  },
  {
    name: chartFor === "Attendance" ? "Present" : "Paid",
    data: paid,
  }
];

if (is_holiday) {
  series.push({
    name: "Holiday",
    data: is_holiday
  });
}

  var options = {
    series: series,
    colors: spineareaChartColors,
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
      // dropShadow: {
      //   enabled: true,
      //   top: 0,
      //   left: 0,
      //   bottom:0,
      //   blur: 2,
      //   opacity: 0.5
      // },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 2,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: "13px",
              fontWeight: 700,
            },
          },
        },
      },
    },
    xaxis: {
      type: "string",
      categories: headLabel ?? [],
    },
    legend: {
      position: "right",
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height={350}
      className="apex-charts"
    />
  );
};
export default BarChart;
