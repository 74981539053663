import axios from "axios";
import Swal from "sweetalert2";
import {
  ADD_DISTRICT_FAILURE,
  ADD_DISTRICT_REQUEST,
  ADD_DISTRICT_SUCCESS,
  CITY_LIST_FAILURE,
  CITY_LIST_REQUEST,
  CITY_LIST_SUCCESS,
  CLASS_LIST_FAILURE,
  CLASS_LIST_REQUEST,
  CLASS_LIST_SUCCESS,
  CREATE_CLASS_FAILURE,
  CREATE_CLASS_REQUEST,
  CREATE_CLASS_SUCCESS,
  CREATE_FEES_STRUCTURE_FAILD,
  CREATE_FEES_STRUCTURE_REQUIST,
  CREATE_FEES_STRUCTURE_SUCCESS,
  CREATE_SCHOOLREG_FAILURE,
  CREATE_SCHOOLREG_REQUEST,
  CREATE_SCHOOLREG_SUCCESS,
  DELETE_CLASS_FAILURE,
  DELETE_CLASS_REQUEST,
  DELETE_CLASS_SUCCESS,
  DELETE_FEES_FAILD,
  DELETE_FEES_REQUIST,
  DELETE_FEES_STRUCTURE_FAILD,
  DELETE_FEES_STRUCTURE_REQUIST,
  DELETE_FEES_STRUCTURE_SUCCESS,
  DELETE_FEES_SUCCESS,
  DELETE_SCHOOLREG_FAILURE,
  DELETE_SCHOOLREG_REQUEST,
  DELETE_SCHOOLREG_SUCCESS,
  FEES_FAILD,
  FEES_LIST_FAILD,
  FEES_LIST_REQUIST,
  FEES_LIST_SUCCESS,
  FEES_REQUIST,
  FEES_SUCCESS,
  GET_FEES_STRUCTURE_LIST_FAILD,
  GET_FEES_STRUCTURE_LIST_REQUIST,
  GET_FEES_STRUCTURE_LIST_SUCCESS,
  SCHOOLOREGISTRATION_FAILURE,
  SCHOOLOREGISTRATION_REQUEST,
  SCHOOLOREGISTRATION_SUCCESS,
  STATE_LIST_FAILURE,
  STATE_LIST_REQUEST,
  STATE_LIST_SUCCESS,
  UPDATE_FEES_FAILD,
  UPDATE_FEES_REQUIST,
  UPDATE_FEES_SUCCESS,
  UPDATE_SCHOOLREG_FAILURE,
  UPDATE_SCHOOLREG_REQUEST,
  UPDATE_SCHOOLREG_SUCCESS,
} from "../../Constants/SchoolRegConstant";
import { BASE_URL } from "../../Config/BaseUrl";
import * as url from "../../helper/url_hrlper";
import {
  GET_ORGANIZATION_FAILD,
  GET_ORGANIZATION_REQUIST,
  GET_ORGANIZATION_SUCCESS,
} from "../../Constants/OrganizationConstant";

export const getSchoolRegistrationList = (token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SCHOOLOREGISTRATION_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.get(
        `${BASE_URL}get_school_designation_list/`,
        config
      );
      const data = response.data;
      dispatch({ type: SCHOOLOREGISTRATION_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: SCHOOLOREGISTRATION_FAILURE,
        payload: error,
      });
    }
  };
};

export const getState = (token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: STATE_LIST_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.get(`${BASE_URL}${url.GET_STATE}`, config);
      const data = response.data;
      dispatch({ type: STATE_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: STATE_LIST_FAILURE,
        payload: error,
      });
    }
  };
};
export const getCity = (token, getcityasperstate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CITY_LIST_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}get_city_list/`,
        getcityasperstate,
        config
      );
      const data = response.data;
      dispatch({ type: CITY_LIST_SUCCESS, payload: data });
      return data;
    } catch (error) {
      dispatch({
        type: CITY_LIST_FAILURE,
        payload: error,
      });
    }
  };
};
export const addDistrict = (token, dis) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_DISTRICT_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}add_district/`,
        dis,
        config
      );
      const data = response.data;
      dispatch({ type: ADD_DISTRICT_SUCCESS, payload: data });
      return data;
    } catch (error) {
      dispatch({
        type: ADD_DISTRICT_FAILURE,
        payload: error,
      });
    }
  };
};

export const getSchoolOrganization = (token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ORGANIZATION_REQUIST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}get_organization/`,
        {},
        config
      );
      const data = response.data;
      dispatch({ type: GET_ORGANIZATION_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_ORGANIZATION_FAILD,
        payload: error,
      });
    }
  };
};

export const registerSchool = (token, getschool, clearField, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_SCHOOLREG_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        `${BASE_URL}register_school/`,
        getschool,
        config
      );
      const data = response.data;
      dispatch({ type: CREATE_SCHOOLREG_SUCCESS, payload: data });
      if (response.data.status === 200) {
        Swal.fire({
          text: response.data.msg,
          icon: "success",
          imageAlt: "success image",allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "SAVE PROCEED",
              text: "Do you want to Add Fee Head?",
              icon: "info",
              showDenyButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: "Yes",
              denyButtonText: "No",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                let schoolId = response.data.school_id
                navigate(`/admin/school-details`
                , { state: { id:schoolId } }
                );
              } else if (result.isDenied) {
                clearField();
                 window.location.href = "/admin/school-registration";
              }
            });
          }
        });
        return response.data;
      }

      if (response.data.status === 403) {
        Swal.fire({
          text: response.data.msg,
          icon: "error",
          imageAlt: "success image",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    } catch (error) {
      dispatch({
        type: CREATE_SCHOOLREG_FAILURE,
        payload: error,
      });
    }
  };
};

export const deleteSchool = (token, ID) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_SCHOOLREG_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}delete_school_details/`,
        ID,
        config
      );
      const data = response.data;
      dispatch({ type: DELETE_SCHOOLREG_SUCCESS, payload: data });
      if (response.data.status === 200) {
        Swal.fire({
          text: response.data.msg,
          icon: "success",
          imageAlt: "success image",allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            // window.location.href = "/admin/school-registration";
          }
        });
      }
      if (response.data.status === 403) {
        Swal.fire({
          text: response.data.msg,
          icon: "error",allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            // window.location.href = "/admin/school-registration";
          }
        });
      }
    } catch (error) {
      dispatch({
        type: DELETE_SCHOOLREG_FAILURE,
        payload: error,
      });
    }
  };
};

export const updateSchool = (token, getschool) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_SCHOOLREG_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          // "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}edi_school_details/`,
        getschool,
        config
      );
      const data = response.data;
      dispatch({ type: UPDATE_SCHOOLREG_SUCCESS, payload: data });
      if (response.data.status === 200) {
        Swal.fire({
          text: response.data.msg,
          icon: "success",
          imageAlt: "success image",allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            // window.location.href = "/admin/school-registration";
            // clearFormField();
          }
        });
      }
      if (response.data.status === 403) {
        Swal.fire({
          text: response.data.msg,
          icon: "error",
          imageAlt: "success image",allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            // window.location.href = "/admin/school-registration";
          }
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_SCHOOLREG_FAILURE,
        payload: error,
      });
    }
  };
};

export const createFee = (token, getschool, formClear) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FEES_REQUIST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}add_fee/`,
        getschool,
        config
      );
      const data = response.data;
      dispatch({ type: FEES_SUCCESS, payload: data });
      if (response.data.status === 200) {
        Swal.fire({
          text: response.data.msg,
          icon: "success",
          imageAlt: "success image",allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            formClear();
          }
        });
      }
      if (response.data.status === 403) {
        Swal.fire({
          text: response.data.msg,
          icon: "error",
          imageAlt: "success image",allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            // window.location.href = "/school-registration";
          }
        });
      }
    } catch (error) {
      dispatch({
        type: FEES_FAILD,
        payload: error,
      });
    }
  };
};
export const updateFee = (token, updatefee, SetInput, setEdit) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_FEES_REQUIST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}update_fee/`,
        updatefee,
        config
      );
      const data = response.data;
      dispatch({ type: UPDATE_FEES_SUCCESS, payload: data });
      if (response.data.status === 200) {
        Swal.fire({
          text: response.data.msg,
          icon: "success",
          imageAlt: "success image",allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            SetInput(null);
            setEdit(false);
          }
        });
      }
      if (response.data.status === 403) {
        Swal.fire({
          text: response.data.msg,
          icon: "error",
          imageAlt: "success image",allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_FEES_FAILD,
        payload: error,
      });
    }
  };
};

export const getFeeList = (token, fee) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FEES_LIST_REQUIST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}get_school_fee/`,
        fee,
        config
      );
      const data = response.data;
      dispatch({ type: FEES_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: FEES_LIST_FAILD,
        payload: error,
      });
    }
  };
};

export const deleteFee = (token, ID) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_FEES_REQUIST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(`${BASE_URL}delete_fee/`, ID, config);
      const data = response.data;
      dispatch({ type: DELETE_FEES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: DELETE_FEES_FAILD,
        payload: error,
      });
    }
  };
};

export const getClassSectionList = (token, classId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CLASS_LIST_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}class_with_section_list/`,
        classId,
        config
      );
      const data = response.data;
      dispatch({ type: CLASS_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CLASS_LIST_FAILURE,
        payload: error,
      });
    }
  };
};

export const createClass = (token, getclass, clearField) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_CLASS_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}add_update_class_and_section/`,
        getclass,
        config
      );
      const data = response.data;
      dispatch({ type: CREATE_CLASS_SUCCESS, payload: data });
      if (
        response.data.status === 403 ||
        response.data.status === 503 ||
        response.data.status === 200
      ) {
        if (response.data.status === 200) {
          Swal.fire({
            text: response.data.msg,
            icon: "success",allowOutsideClick: false,
          });
          clearField();
        } else {
          Swal.fire({
            text: response.data.msg,
            icon: "error",allowOutsideClick: false,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: CREATE_CLASS_FAILURE,
        payload: error,
      });
    }
  };
};

export const deleteClass = (token, ID) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_CLASS_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}delete_class_and_section/`,
        ID,
        config
      );
      const data = response.data;
      dispatch({ type: DELETE_CLASS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: DELETE_CLASS_FAILURE,
        payload: error,
      });
    }
  };
};

export const getFeeListStructure = (token, fdata) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_FEES_STRUCTURE_LIST_REQUIST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}get_fees_structure/`,
        fdata,
        config
      );
      const data = response.data;
      dispatch({ type: GET_FEES_STRUCTURE_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_FEES_STRUCTURE_LIST_FAILD,
        payload: error,
      });
    }
  };
};
export const createFeeStructure = (token, fdata) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_FEES_STRUCTURE_REQUIST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}add_update_fees_structure/`,
        fdata,
        config
      );
      const data = response.data;
      dispatch({ type: CREATE_FEES_STRUCTURE_SUCCESS, payload: data });

      if (response.data.status === 200) {
        Swal.fire({
          text: response.data.msg,
          icon: "success",
          imageAlt: "success image",allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    } catch (error) {
      dispatch({
        type: CREATE_FEES_STRUCTURE_FAILD,
        payload: error,
      });
    }
  };
};
export const deleteFeeStructure = (token, fdata) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_FEES_STRUCTURE_REQUIST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}delete_fees_structure/`,
        fdata,
        config
      );
      const data = response.data;
      dispatch({ type: DELETE_FEES_STRUCTURE_SUCCESS, payload: data });

      if (response.data.status === 200) {
        Swal.fire({
          text: response.data.msg,
          icon: "success",
          imageAlt: "success image",allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    } catch (error) {
      dispatch({
        type: DELETE_FEES_STRUCTURE_FAILD,
        payload: error,
      });
    }
  };
};
