import React, { useRef, useState } from "react";
import pic1 from "../../../assets/images/dummy.png";

const ImageComponent
 = (props) => {
  const { formik, fieldName, showImg, setShowImg, fieldName1 ,setImage } = props;
  const fileInputRef = useRef(null);
  const [showRemoveIcon, setShowRemoveIcon] = useState(false);


   const handleFileRead = (e) => {
    const file = e.target.files[0];
    console.log(file)
    formik.setFieldValue(fieldName, file);
    const imageURL = URL.createObjectURL(file);
    setShowImg(imageURL);
    setImage(file);

  };

  const handleRemoveImage = () => {
    formik.setFieldValue(fieldName, "");
    formik.setFieldValue(fieldName1, "True");
    setShowImg("");
    // setShowRemoveIcon(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  return (
    <>
   <div className="float-right col-12">
  <div className="row justify-content-end">
    <div
      className=""
      onMouseEnter={() => setShowRemoveIcon(true)}
      onMouseLeave={() => setShowRemoveIcon(false)}
    >
      <img
        alt="profile"
        src={showImg ? showImg : pic1}
        className="crop_img float-right" 
        width={80}
      />


      {showImg && (
        <span>
          {showRemoveIcon && (
            <span
              className="image-remove "
              onClick={handleRemoveImage}
              data-toggle="tooltip"
              data-placement="top"
              title={"Remove"}
            >
              <i className="fa fa-times"></i>
            </span>
          )}
        </span>
      )}
    </div>
    <div>
    <input
      style={{ width: "85%", marginTop: "20px" }}
      type="file"
      ref={fileInputRef}
      className="  mb-2 "
      name={fieldName}
      accept="image/*"
      onChange={handleFileRead}
    />
  </div></div>
</div>

    </>
  );
};

export default ImageComponent
;
