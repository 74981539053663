import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";

const ChangePasswordForm = ({apiFunction,navigateTo}) => {
  // this code is for the change password form
    const school_user = JSON.parse(localStorage.getItem("school_user"));
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const navigate = useNavigate();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      user_id: school_user?.id,
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("New Password is required.")
        .min(6, "New Password must be at least 6 characters long."),
      confirmPassword: Yup.string()
        .required("Confirm Password is required.")
        .oneOf(
          [Yup.ref("password"), null],
          "Password and confirmPassword should be same."
        ),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const data = new FormData();
        data.append("user_id", values.user_id);
        data.append("password", values.password);
        // const response = await AuthAPI.changePassword(data);
        const response = await apiFunction(data);
        let responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            allowOutsideClick: false,
          });
          resetForm();
          navigate(navigateTo);
        } else if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const handleClear = (event) => {
    event.preventDefault();
    validation.resetForm();
  };

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <div className="form-group">
          <label className="form-label" htmlFor="password">
            New Password
          </label>
          <input
            type="password"
            className={`form-control input-height ${
              validation.touched.password && validation.errors.password
                ? "is-invalid"
                : ""
            }`}
            id="password"
            name="password"
            value={validation.values.password}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            placeholder="Enter New Password"
            autoComplete="off"
          />
          {validation.touched.password && validation.errors.password ? (
            <div className="invalid-feedback">{validation.errors.password}</div>
          ) : null}
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="confirmPassword">
            Confirm Password
          </label>
          <input
            type="password"
            className={`form-control input-height ${
              validation.touched.confirmPassword &&
              validation.errors.confirmPassword
                ? "is-invalid"
                : ""
            }`}
            id="confirmPassword"
            name="confirmPassword"
            value={validation.values.confirmPassword}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            placeholder="Enter Confirm Password"
            autoComplete="off"
          />
          {validation.touched.confirmPassword &&
          validation.errors.confirmPassword ? (
            <div className="invalid-feedback">
              {validation.errors.confirmPassword}
            </div>
          ) : null}
        </div>

        <div className="col-md-12 d-flex justify-content-end align-item-center my-3">
          <button
            type="submit"
            className="btn btn-primary btn-sm px-3
                    "
          >
            {" "}
            <i className="fa fa-plus pr-1"></i>
            Save
          </button>
          <button
            type="button"
            onClick={(e) => handleClear(e)}
            className="btn btn-outline-danger btn-sm ml-2"
          >
            <i className="fa fa-times pr-1"></i>
            Clear
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordForm;
