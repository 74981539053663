import React from "react";
import { Table, Modal, ModalHeader, ModalBody } from "reactstrap";
import { convertDateInMMDDYY } from "../../CommonComponent/DateFormatUtil";
import EmptyListMessage from "../../CommonComponent/EmptyListMessage";

const FeeDetailsTable = ({ isOpen, toggle, feeData, title }) => {
  // this file is to setup the  student fee list table "location": "studentProfile" in header of school dashboard "dropdwon"
  const feeDataArray = Array.isArray(feeData) ? feeData : [];

  const closeBtn = (
    <button className="btn btn-sm close " onClick={toggle} type="button">
      <i className="fa fa-times" style={{ color: "red" }}></i>
    </button>
  );
  return (
    <Modal isOpen={isOpen} toggle={toggle} fullscreen className="mobel_box">
      <ModalHeader toggle={toggle} close={closeBtn}>
     
        {title}
      </ModalHeader>
      <ModalBody>
        <div className="table-responsive">
          {feeDataArray && feeDataArray?.length > 0 ? (
            <Table size="sm" className=" table-bordered table-hover">
              <thead className="table-dark">
                <tr className="text-center text-nowrap ">
                  <th>receipt no</th>
                  <th>receipt date</th>
                  <th>payment mode</th>
                  <th>total amount</th>
                  <th>late charge</th>
                  <th>bounce charge</th>
                  <th>paid amount</th>
                  <th>remark</th>
                </tr>
              </thead>
              <tbody>
                {feeDataArray?.map((stu, index) => (
                  <tr key={index} className="text-center clickable-row">
                    <td>{stu.receipt_no}</td>
                    <td>{convertDateInMMDDYY(stu.receipt_date)}</td>
                    <td>{stu.payment_mode}</td>
                    <td className="text-right">{stu.total_amount}</td>
                    <td className="text-right">{stu.late_charge}</td>
                    <td className="text-right">{stu.bounce_charge}</td>
                    <td className="text-right">{stu?.paid_amount}</td>
                    <td className="text-left">{stu?.remark}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <EmptyListMessage
              message={"Payment Details Not Available !"}
              Div="div"
            />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FeeDetailsTable;
