import "./App.css";
import { authProtectedRoutes, publicRoutes } from "../routes/Routes";
import { Route, Routes, useNavigate } from "react-router-dom";
import Authmiddleware from "../routes/Authmiddleware";
import NonAuthLayout from "../routes/NonAuthLayout";
import Layout from "../pages/Layout/Layout";
import { useEffect } from "react";
import { DualLoginApi} from '../api/DualLogin/DualLogin'
import Swal from 'sweetalert2'; // Import SweetAlert library




function App() {
  // this is the main App.js

  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let school_emp_id = school_user?.user_detail?.school_emp_id;
  const email = school_user?.email || "";
  const token = localStorage.getItem("token") || "";
  return (
    <>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}
        {school_user &&
          authProtectedRoutes.map((route, idx) => (
            <Route
              path={
                school_user?.user_type === "Admin"
                  ? `/admin${route.path}`
                  : `/school${route.path}`
              }
              element={
                <Authmiddleware>
                  <Layout>{route.component}</Layout>
                </Authmiddleware>
              }
              key={idx}
              exact={true}
            />
          ))}
      </Routes>
    </>
  );
}

export default App;
