import React, { useState, useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import WorkSheetList from "./WorkSheetList";

import {
  Col,
  Row,
  Form,
  Input,
  Button,
  FormFeedback,
  Container,
  Table,
} from "reactstrap";
import Select from "react-select";
import { SessionContext } from "../../../../context/sessionContext";
import {
  getClass,
  getSection,
} from "../../../../actions/Admission/StudentReport";

import {
  CreateUpdateHomeWork,
  GetHomeWorkList,
  DeleteWorkSheet,
} from "../../../../actions/TeacherDesk/WorkSheet";

import Loader from "../../../Loader/Loader";
import { validateFile } from "../../../CommonComponent/FileValidationUtil";
import {
  ClearButton,
  SaveButton,
} from "../../../CommonComponent/ReusableButton";
import { Heading } from "../../../CommonComponent/heading";

const WorkSheet = () => {
  // school user can maintain worksheet from here
  const calendarRef = useRef(null);
  const fileInputRef = useRef(null);
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { id, user_type, name, user_detail } = school_user;
  const { Session } = useContext(SessionContext);
  let session_id = Session || sessionStorage.getItem("SessionId");
  let school_id = school_user?.user_detail?.fk_school_id;
  let { school_emp_id } = school_user?.user_detail;
  const { showWorkSheetList } = useSelector((state) => state.GetHomeWorkList);
  const { deleteWorkSheetList } = useSelector((state) => state.DeleteWorkSheet);
  const dispatch = useDispatch();
  const [worksheetDate, setWorksheetDate] = useState(0);
  const [Class, setClass] = useState("");
  const [Section, setSection] = useState("");
  const { allClass } = useSelector((state) => state.getClass);
  const { allSection } = useSelector((state) => state.getSection);
  const [eventSource, setEventSource] = useState([]);
  const [reloadCalendar, setReloadCalendar] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [currentFileName, setCurrentFileName] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [ViewWorkSheetList, setViewWorkSheetList] = useState([]);
  const [EditSectionName, setEditSectionName] = useState([]);
  const [isDocumentRemoved, setIsDocumentRemoved] = useState(false);
  const monthsList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getWorkSheetData = async () => {
    const body = {
      academic_id: session_id,
      school_id: school_id,
      homework_date: worksheetDate,
    };
    const res = await dispatch(GetHomeWorkList(body));
  };

  useEffect(() => {
    if (showWorkSheetList) {
      setViewWorkSheetList(showWorkSheetList);
    }
  }, [showWorkSheetList]);

  useEffect(() => {
    setCreateWorkSheet({
      ...CreateWorkSheet,
      academic_id: session_id,
    });
  }, [session_id]);

  const [CreateWorkSheet, setCreateWorkSheet] = useState({
    academic_id: session_id,
    school_id: school_id,
    title: "",
    decription: "",
    homework_date: new Date().toISOString().substr(0, 10),
    class_id: 0,
    section_id: [],
    homework_doc: null,
    homeworkid: "",
    is_document_remove:"False"
  });

  useEffect(() => {
    if (worksheetDate) {
      const deleteDateElement = document.querySelector(".custom-circle");
      if (deleteDateElement) {
        deleteDateElement.classList.remove("custom-circle");
      }
      // Create a JavaScript Date object with the desired date
      const date = new Date(worksheetDate);
      // Extract the month, day, and year from the Date object
      const month = monthsList[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();
      // Format the date as "Month day, year"
      const formattedDate = `${month} ${day}, ${year}`;
      const element = document.querySelector(
        '[aria-label="' + formattedDate + '"]'
      );
      if (element) {
        // Do something with the element, e.g., change its text content
        element.classList.add("custom-circle");
      }
    }
  }, [worksheetDate, reloadCalendar, deleteWorkSheetList, showLoader]);

  useEffect(() => {
    if (session_id && worksheetDate) {
      getWorkSheetData();
    }
  }, [worksheetDate, session_id, reloadCalendar, deleteWorkSheetList]);

  const handleDateClick = (arg) => {
    // bind with an arrow function
    setCreateWorkSheet({
      ...CreateWorkSheet,
      homework_date: arg.dateStr,
    });
    setWorksheetDate(arg.dateStr);
    handleWorkSheetClear();
  };

  useEffect(() => {
    setWorksheetDate(new Date().toISOString().substr(0, 10));
  }, []);

  useEffect(() => {
    const body = {
      class_id: Number(Class?.value),
    };
    dispatch(getSection(body));
  }, [dispatch, Class]);

  const classList = allClass?.payload?.map((list) => {
    return {
      label: list.class_name,
      value: list.id,
    };
  });

  const sectionList = allSection?.payload?.map((list) => {
    return {
      label: list.section,
      value: list.id,
    };
  });

  useEffect(() => {
    if (sectionList) {
      const currectsection = CreateWorkSheet?.section_id?.map((list) => {
        return {
          label: sectionList.find((data) => data.value === list)?.label,
          value: list,
        };
      });
      setSection(currectsection);
    }
  }, [sectionList]);

  const handleWorkSheetClear = () => {
    setCreateWorkSheet({
      ...CreateWorkSheet,
      title: "",
      decription: "",
      class_id: "",
      section_id: [],
      homework_doc: null,
      homeworkid: "",
    });
    setSection("");
    setCurrentFileName("");
    setClass("");
    setReloadCalendar((reloadCalendar) => !reloadCalendar);
  };

  const handleWorkSheetClearData = (e) => {
    setCreateWorkSheet({
      ...CreateWorkSheet,
      title: "",
      decription: "",
      homework_date: new Date().toISOString().substr(0, 10),
      class_id: "",
      section_id: [],
      homework_doc: null,
      homeworkid: "",
      
    });
    setIsDocumentRemoved(false);
    setSection("");
    setCurrentFileName("");
    setWorksheetDate(new Date().toISOString().substr(0, 10));
    setClass("");
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCreateWorkSheet((values) => ({ ...values, [name]: value }));
  };

  const handleWorkSheetSaveUpdate = async (e) => {
    e.preventDefault();

    const isValid = await validateControl();
    if (isValid) {
      setShowLoader(true);
      const data = new FormData();
      data.append("homework_doc", CreateWorkSheet.homework_doc);
      data.append("academic_id", CreateWorkSheet.academic_id);
      data.append("school_id", CreateWorkSheet.school_id);
      data.append("class_id", CreateWorkSheet.class_id);
      data.append(
        "section_id",
        CreateWorkSheet.section_id
          ? JSON.stringify(CreateWorkSheet.section_id)
          : []
      );

      data.append("title", CreateWorkSheet.title);
      data.append("decription", CreateWorkSheet.decription);
      data.append("homework_date", worksheetDate);
      data.append("insert_by", school_emp_id);
      data.append("homework_id", CreateWorkSheet.homeworkid);
      const is_document_removed = isDocumentRemoved ? "True" : "False";
      data.append("is_document_remove", is_document_removed);
      dispatch(CreateUpdateHomeWork(data, handleLoader, handleWorkSheetClear));
    }
  };

  const handleLoader = () => {
    setShowLoader((showLoader) => !showLoader);
  };

  const validateControl = async () => {
    let validationStatus = true;
    if (!CreateWorkSheet.class_id) {
      validationStatus = false;
    }
    if (!CreateWorkSheet.section_id) {
      validationStatus = false;
    }
    if (!CreateWorkSheet.title) {
      validationStatus = false;
    }
    if (!CreateWorkSheet.decription) {
      validationStatus = false;
    }
    // if (!CreateWorkSheet.homework_doc) {
    //   validationStatus = false;
    // }

    if (!validationStatus) {
      setValidationError(true);
      setTimeout(() => {
        setValidationError(false);
      }, 5000);
    }
    return validationStatus;
  };

  const onDeleteFile = async (id) => {
    setCurrentFileName("");
    setIsDocumentRemoved(true);
    setCreateWorkSheet({
      ...CreateWorkSheet,
      homework_doc: null,
    });
  };

  const handlefilechange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (validateFile(file, fileInputRef)) {
      setCurrentFileName(e.target.value);
      setCreateWorkSheet({
        ...CreateWorkSheet,
        homework_doc: file,

      });
      setIsDocumentRemoved(false);
    } else {
      Swal.fire({
        text: "Please upload file with pdf extension.",
        icon: "warning",
        imageAlt: "warning image",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          e.target.value = null;
        }
      });
    }
  };

  const getFileExtension = (filename) => {
    // get file extension
    const extension = reverseString(filename);
    const ext = reverseString(extension.substring(0, extension.indexOf(".")));
    return ext;
  };

  const reverseString = (str) => {
    // Step 1. Use the split() method to return a new array
    var splitString = str.split("").reverse().join("");
    return splitString; // "olleh"
  };

  const editCurrentWorkSheet = async (id) => {
    const currentworksheet = showWorkSheetList.homework.find(
      (data) => data.fk_homework === id
    );
    if (currentworksheet) {
      const selectedclassdata = {
        label: currentworksheet.fk_homework__fk_class__class_name,
        value: currentworksheet.fk_homework__fk_class,
      };
      setClass(selectedclassdata);
      setCreateWorkSheet({
        ...CreateWorkSheet,
        title: currentworksheet.fk_homework__title,
        decription: currentworksheet.fk_homework__decription,
        class_id: currentworksheet.fk_homework__fk_class,
        section_id: currentworksheet.fk_section__id,
        homework_doc: currentworksheet.fk_homework__homework_doc,
        homeworkid: currentworksheet.fk_homework,
      });

      setCurrentFileName(currentworksheet.fk_homework__homework_doc);
    }
  };

  const deleteCurrentWorkSheet = async (id) => {
    Swal.fire({
      text: "Do you want to delete Home Work ?",
      icon: "warning",
      imageAlt: "warning image",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let body = {
          homework_id: id,
        };
        setShowLoader(true);
        dispatch(DeleteWorkSheet(body, handleLoader));
      }
    });
  };

  if (showLoader) return <Loader />;
  return (
    <Container fluid={true}>
        <Heading pageTitle={"Home Work"}></Heading>
      <div className="card mt-4">
        <div className="card-body">
          <div className="mb-3 px-lg-4 row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-12">
                  <FullCalendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin, interactionPlugin]}
                    headerToolbar={{
                      // left: "prev, today",
                      left: "prev",
                      center: "title",
                      right: "next",
                    }}
                    initialView="dayGridMonth"
                    selectable={true}
                    dateClick={handleDateClick}
                  />
                  
                </div>

                <div className="col-lg-12 mt-4">
                  <div className="row">
                    <div className="col-lg-3 col-sm-12 col-xs-12">
                      <label className="form-label">
                        Class <span style={{ color: "Red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9 col-sm-12 col-xs-12">
                      <Select
                        options={classList}
                        name="class_id"
                        placeholder="Select Class"
                        value={Class}
                        onChange={(e) => {
                          setCreateWorkSheet({
                            ...CreateWorkSheet,
                            class_id: e.value,
                          });

                          setClass(e);
                        }}
                      />
                      {validationError && CreateWorkSheet.class_id == "" ? (
                        <div class="popup-message">
                          <i class="fa fa-exclamation-circle"></i>
                          This field is required.
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-3 col-sm-12 col-xs-12">
                      <label className="form-label">
                        Section <span style={{ color: "Red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9 col-sm-12 col-xs-12">
                      <Select
                        isMulti
                        options={sectionList}
                        placeholder="Select Section"
                        value={Section}
                        onChange={(e) => {
                          setCreateWorkSheet({
                            ...CreateWorkSheet,
                            section_id: e.map((val) => val.value),
                          });
                          setSection(e);
                        }}
                      />
                      {validationError && CreateWorkSheet.section_id ? (
                        <div class="popup-message">
                          <i class="fa fa-exclamation-circle"></i>
                          This field is required.
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-3 col-sm-12 col-xs-12">
                      <label className="form-label">
                        Title <span style={{ color: "Red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9 col-sm-12 col-xs-12">
                      <Input
                        name="title"
                        rows="1"
                        placeholder="Title"
                        type="text"
                        className="border border-info form-control"
                        value={CreateWorkSheet.title}
                        onChange={handleChange}
                      />
                      {validationError && CreateWorkSheet.title == "" ? (
                        <div class="popup-message">
                          <i class="fa fa-exclamation-circle"></i>
                          This field is required.
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-3 col-sm-12 col-xs-12">
                      <label className="form-label">
                        Description <span style={{ color: "Red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-lg-9 col-sm-12 col-xs-12">
                      <Input
                        name="decription"
                        rows="2"
                        placeholder="Write Description"
                        type="textarea"
                        className="border border-info form-control"
                        value={CreateWorkSheet.decription}
                        onChange={handleChange}
                      />
                      {validationError && CreateWorkSheet.decription == "" ? (
                        <div class="popup-message">
                          <i class="fa fa-exclamation-circle"></i>
                          This field is required.
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-3 col-sm-12 col-xs-12">
                      <label className="form-label">
                        Document <span style={{ color: "Red" }}></span>
                      </label>
                    </div>
                    <div className="col-lg-9 col-sm-12 col-xs-12">
                      {currentFileName ? (
                        <div className="row">
                          <div className="col-lg-12 questionlist p-2">
                            <div className="row">
                              <div className="col-lg-11 col-sm-12 col-xs-12">
                                <b>
                                  {" "}
                                  {currentFileName.split("\\").length > 2
                                    ? currentFileName.split("\\")[2]
                                    : currentFileName.split("/")[1]}
                                </b>
                              </div>
                              <div className="col-lg-1 col-sm-12 col-xs-12">
                                <i
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete"
                                  className="fa fa-trash-o text-danger"
                                  onClick={onDeleteFile}
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Input
                          type="file"
                          className="border border-info form-control"
                          id="img"
                          accept=".jpg, .jpeg, .png, .gif, .pdf"
                          name="document"
                          onChange={handlefilechange}
                        />
                      )}
                      {/* {validationError &&
                      CreateWorkSheet.homework_doc == null ? (
                        <div class="popup-message">
                          <i class="fa fa-exclamation-circle"></i>
                          This field is required.
                        </div>
                      ) : (
                        ""
                      )} */}
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-lg-12 text-right">
                      <SaveButton
                        onClick={handleWorkSheetSaveUpdate}
                        isEdit={false}
                      />
                      <ClearButton
                        onClick={handleWorkSheetClearData}
                        className="ml-2"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6"
              style={{ height: "700px", overflowY: "auto" }}
            >
              <WorkSheetList
                WorkSheetList={ViewWorkSheetList}
                editWorkSheet={editCurrentWorkSheet}
                deleteWorkSheet={deleteCurrentWorkSheet}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default WorkSheet;
