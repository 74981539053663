import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  createOrganization,
  deleteOrganization,
  getAllOrganization,
  updateOrganization,
} from "../../../actions/super-user/OrganizationAction";
import { useAlert } from "react-alert";
import Loader from "../../Loader/Loader";
import Swal from "sweetalert2";
import { getTitle } from "../../CommonComponent/helper";

const Organization = () => {
  // this code is for edit and update the orginization
  getTitle("Organization");
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let super_admin_id = school_user?.id;
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { organization, error, loading } = useSelector(
    (state) => state.organization
  );

  useEffect(() => {
    if (token) {
      dispatch(getAllOrganization(token));
    }
  }, [dispatch, token]);

  const [editId, setEditId] = useState();
  var initialValue = {
    name: "",
    reg_no: "",
    since_date: "",
    super_admin_id,
  };
  const [input, SetInput] = useState(initialValue);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    SetInput((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (edit) {
        const editData = {
          ...input,
          organization_id: editId,
          super_admin_id,
        };
        let response = await dispatch(updateOrganization(token, editData));
        if (response.status === 200) {
          Swal.fire({
            text: response.msg,
            icon: "success",
            imageAlt: "success image",
          }).then((result) => {
            if (result.isConfirmed) {
              SetInput(initialValue);
              setEdit(false);
            }
          });
        }
        if (response.status === 403) {
          Swal.fire({
            text: response.msg,
            icon: "error",
            imageAlt: "success image",
          });
        }
        await dispatch(getAllOrganization(token));
      } else {
        let response = await dispatch(createOrganization(token, input));
        if (response.status === 200) {
          Swal.fire({
            text: response.msg,
            icon: "success",
            imageAlt: "success image",
          }).then((result) => {
            if (result.isConfirmed) {
              SetInput(initialValue);
            }
          });
        }
        if (response.status === 403) {
          Swal.fire({
            text: response.msg,
            icon: "error",
            imageAlt: "success image",
          });
        }
        await dispatch(getAllOrganization(token));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "You Cannot Delete This Organization",
      icon: "error",
      confirmButtonColor: "#3085d6",
      allowOutsideClick: false,
    });
    // const did = {
    //   organization_id: id,
    // };
    // Swal.fire({
    //   title: "Are you sure?",
    //   text: "You will not be able to revert this!",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "#f46a6a",
    //   confirmButtonText: "Yes, delete it!",
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     dispatch(deleteOrganization(token, did)).then(() => {
    //       dispatch(getAllOrganization(token));
    //       SetInput(initialValue);
    //       setEdit(false);
    //     });
    //   }
    // });
  };

  const onEdit = (data) => {
    setEdit(true);
    setEditId(data.id);
    SetInput({
      ...input,
      name: data.name,
      reg_no: data.reg_no,
      since_date: data.since_date,
    });
  };
  const currentDate = new Date().toISOString().split("T")[0];
  function convertDate(data) {
    const parts = data.split("-");
    const formattedDate = parts[2] + "/" + parts[1] + "/" + parts[0];
    return formattedDate;
  }

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Organization</h1>
            </div>
            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#">
                  Organisation List
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="card">
            {edit && (
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row clearfix">
                    <div className="col-lg-3  col-sm-12">
                      <div className="form-group">
                        <label htmlFor="name">
                          Name <span className="text-danger">*</span>
                        </label>
                        <input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Enter Your Organization Name"
                          value={input.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className=" col-lg-3  col-sm-12">
                      <div className="form-group">
                        <label>
                          Registration Number{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Registration Number"
                          name="reg_no"
                          value={input.reg_no || ""}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      <div className="form-group">
                        <label>
                          Since <span className="text-danger">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="since"
                          name="since_date"
                          value={input.since_date || ""}
                          onChange={handleChange}
                          max={currentDate}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-12">
                      <div className="d-flex flex-column">
                        <label className="visually-hidden">.</label>
                        <button type="submit" className="btn btn-primary">
                          {edit ? "UPDATE" : "+ ADD"}
                        </button>
                      </div>
                      {/* <button
                        type="submit"
                        className="btn btn-outline-secondary"
                      >
                        Cancel
                      </button> */}
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>

          <div className="tab-content mt-4">
            <div className="tab-pane active" id="Fees-all">
              {loading ? (
                <Loader />
              ) : (
                <div className="card">
                  <div className="card-body">
                    <div
                      className="table-responsive "
                      //  style={{height:"45vh"}}
                    >
                      <table className="table table-hover table-sm text-nowrap  table-striped">
                        <thead>
                          <tr>
                            <th>Sr.No.</th>
                            <th>Name</th>
                            <th>Reg No</th>
                            <th>Since</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {organization?.payload?.map((org, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{org.name}</td>
                              <td>{org.reg_no}</td>
                              <td>{convertDate(org.since_date)}</td>
                              <td className="d-flex gap-2">
                                {/* <NavLink
                                  to="#"
                                  className="card-options-remove mr-3"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete"
                                  onClick={() => onDelete(org.id)}
                                >
                                  <i className="fa fa-trash-o text-danger"></i>
                                </NavLink> */}
                                <NavLink
                                  className="text-muted"
                                  to="#"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit"
                                  onClick={() => {
                                    onEdit(org);
                                  }}
                                >
                                  <i className="fa fa-edit"></i>
                                </NavLink>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Organization;
