import React, { useEffect, useState } from "react";
import { Heading } from "../../../CommonComponent/heading";
import { MdModeEditOutline } from "react-icons/md";
import "./Styles.css";
import Select from "react-select";
import SearchStudent from "./SearchStudent";
import useSessionRedirect from "../../Comman/SessionChange";
import useLocalStorageData from "../../Comman/LocalStorageData";
import { BusFeeCollectionAPI } from "../../../../api/Transport/BusFeeCollection";
import { useAlert } from "react-alert";
import SearchRecipt from "./SearchRecipt";
import PrintFeeRecipt from "./PrintFeeRecipt";

const BusFeeReceipt = () => {
  // this file contains all the code related to bus fee collection
  const [PaymentMode, setPaymentMode] = useState("Cash");
  const [paidAmount, setPaidAmount] = useState(0);
  const [TotalPaidAmount, setTotalPaidAmount] = useState(0);
  const [receiptType, setReceiptType] = useState("New");
  const session_id = useSessionRedirect();
  const [paymentId, setPaymentId] = useState("");
  const [receiptDate, setreceiptDate] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [paid ,setPaid] = useState(0)

  const alert = useAlert();
  const [selectedId, setSelectedId] = useState(null);

  const [selectStudent, setSelectStudent] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const { school_id, token, school_emp_id ,schoolUser} = useLocalStorageData();

  let initialValue = {
    student_id: '',
    payment_id: "",
    receipt_date: receiptDate,
    payment_mode: "Cash",
    late_fee: 0,
    bounce_fee: 0,
    bank_name: "",
    pay_no: "",
    pay_date: receiptDate || "",
    paid_amount: "",
    total_amount: 0,
    remark: "",
  };

  let values = {
    student_route_id:selectStudent.route_id ||"",
    school_id: school_id,
  
    academic_id: session_id,
    insert_by: school_emp_id,

  }


  const handleClear = () => {
    setPaymentId("")
    setSelectStudent("");
    setPaymentInfo(initialValue);
    setSelectedId(null);
    setPaymentMode("Cash");
    setReceiptType("New");
    setPaid(0)
  };
  const [paymentInfo, setPaymentInfo] = useState(initialValue);


  useEffect(() => {
    setPaymentInfo((prev) => ({
      ...prev,
      payment_id: paymentId ?? "",
      student_id: selectStudent?.fk_student ,
      late_fee: selectStudent?.late_fee ?? 0,
      paid_amount: selectStudent?.receipt_no ?  Number(selectStudent?.paid_amount) :0,
      bounce_fee: selectStudent?.bounce_fee ?? 0,
      bank_name: selectStudent?.bank_name ?? "",
      total_amount: selectStudent?.receipt_no ?  selectStudent?.total_paid_amount : 0,
      pay_no: selectStudent?.pay_no ?? "",
      pay_date: selectStudent?.pay_date ?? receiptDate,
      remark: selectStudent?.remark ?? "",
      payment_mode: selectStudent?.payment_mode ?? "Cash",
    }));
    setPaid( Number(selectStudent.paid_amount) + Number(selectStudent.bounce_fee) + Number(selectStudent.late_fee))
    setPaymentMode(selectStudent?.payment_mode ?? "Cash");
    // setHostelId(selectStudent?.hostel_id);
  }, [selectStudent]);
  const getStudentData = async () => {
    try {
      let data = {
        school_id: school_id,
        student_code: "",
        academic_id: session_id,
      };
      const response = await BusFeeCollectionAPI.getStudentRouteMapped(data);
      const responseData = response.data;
      const FindStudent = responseData.student_route.find(
        (item) => item.id === Number(selectedId)
      );
      setSelectStudent(FindStudent);

    } catch (err) {
      console.log(err);
    }
  };

  const pay_mode = [
    {
      label: "Cash",
      value: "Cash",
    },
    {
      label: "Bank",
      value: "Bank",
    },
    {
      label: "UPI",
      value: "UPI",
    },
    {
      label: "DD",
      value: "DD",
    },
    {
      label: "Cheque",
      value: "Cheque",
    },
  ];

  const PaymentList = pay_mode?.map((item) => {
    return {
      label: item.label,
      value: item.value,
    };
  });
 console.log(paid)
 


  const handlePaymentMode = (select) => {
    setPaymentMode(select);
    setPaymentInfo((prevState) => ({
      ...prevState,
      payment_mode: select.value,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setPaymentInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {

    if(selectStudent.total_paid_amount){
      return;
    }else{ const totalAmount = Number(paymentInfo.paid_amount) + Number(paymentInfo.bounce_fee) + Number(paymentInfo.late_fee);
      setPaymentInfo(prevState => ({
        ...prevState,
        total_amount: totalAmount,
      }));
      setPaid(totalAmount)
    
    }
   
  
  }, [paymentInfo.paid_amount, paymentInfo.bounce_fee, paymentInfo.late_fee]);
  useEffect(() => {
    if (selectedId) {
      getStudentData();
    }
  }, [selectedId]);

  let BalanceFee = selectStudent?.balance_fees
    ? selectStudent?.balance_fees
    : selectStudent?.balance_fee;



    const CancelReceipt = () => {
      Swal.fire({
        title: "Confirmation",
        text: "Do you want to Cancel Receipt?",
        icon: "question",
        showDenyButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { value: cancelReason } = await Swal.fire({
            icon: "question",
            title: "Enter Cancel Reason ?",
            input: "text",
            inputPlaceholder: "Enter your cancel reason",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            allowOutsideClick: false,
          });
          if (cancelReason) {
            let body = {
              payment_id: selectStudent?.id,
              cancel_reason: cancelReason,
              insert_by: school_emp_id,
            };
            const { data } = await BusFeeCollectionAPI.BusCancelPaymentApi(body);
            if (data.status === 200) {
              Swal.fire({
                text: data.msg,
                icon: "success",
                imageAlt: "success image",
                allowOutsideClick: false,
              });
              handleClear();
              setReceiptType("New");
            } else if (data.status === 403) {
              Swal.fire({
                text: data.msg,
                icon: "error",
                imageAlt: "success image",
                allowOutsideClick: false,
              });
            }
          }
        }
      });
    };


  return (
    <>
      <div>
        <div className="m-3">
          <Heading pageTitle={"Bus Fee Receipt"}>
            <div className="row">
            {receiptType !== "New" && (
              <button
                type="button"
                className="btn btn-sm btn-danger mx-1"
                onClick={() => CancelReceipt()}
                disabled={selectStudent?.payment_status === "CANCELLED"}
              >
                <i className="fa fa-times px-1"></i>
                Cancel
              </button>
            )}
            <button
              type="button"
              className="btn btn-sm btn-outline-danger mx-1"
              onClick={handleClear}
            >
              <i className="fa fa-times px-1"></i>
              Clear
            </button>

              <SearchRecipt 
                setSelectStudent={setSelectStudent}
                school_id={school_id}
                setReceiptType={setReceiptType}
                setPaymentId={setPaymentId}
                session_id={session_id}
              />
            </div>
          </Heading>
        </div>
      </div>

      <div className="section-body">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-md-4 col-sm-6">
                  <div className="row">
                    <div className="col-lg-4 ">
                      <label className="col-form-label mx-2">
                        Student Code <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Student Code"
                          defaultValue={
                            selectStudent &&
                            selectStudent?.fk_student__fk_student__student_code
                              ? selectStudent?.fk_student__fk_student__student_code
                              : null
                          }
                        />
                        <SearchStudent setSelectedId={setSelectedId} handleClear={handleClear} />
                      </div>
                    </div>
                    <div className="col-lg-8 ">
                      <label className="col-form-label mx-2">
                        Student Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Student Name"
                        defaultValue={
                          selectStudent &&
                          selectStudent?.fk_student__fk_student__std_name
                            ? selectStudent?.fk_student__fk_student__std_name
                            : null
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <label className="col-form-label">
                        Receipt Date <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={selectStudent?.receipt_date ?? receiptDate}
                        onChange={(e) => setreceiptDate(e.target.value)}
                        disabled={selectStudent?.receipt_date ? true : false}
                      />
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <label className="col-form-label mx-2">
                        Receipt No <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={
                          selectStudent?.receipt_no
                            ? selectStudent?.receipt_no
                            : null
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-8">
              {selectStudent ? (
                <div className="table-responsive card">
                  <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
                    <thead>
                      <tr className="text-center">
                        <th>Sr.No.</th>
                        <th>Fees</th>
                        <th className="text-center">Total Fees</th>
                        <th className="text-center">Fees Paid</th>
                        <th className="text-center">Amount</th>
                        <th className="text-center">FEES DUE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{1}</td>
                        <td>Transport Fees</td>
                        <td>
                          <input
                            type="number"
                            className="form-control text-right"
                            defaultValue={
                              selectStudent?.total_amount
                                ? selectStudent?.total_amount
                                : null
                            }
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            readOnly
                            type="number"
                            className="form-control text-right"
                            defaultValue={
                            selectStudent?.total_paid_amount || selectStudent.paid_amount
                               
                            }
                          />
                        </td>
                        <td>
                          <input
                            name="paid_amount"
                            type="number"
                            className="form-control text-right"
                            value={paymentInfo.paid_amount}
                            onChange={(e) => {
                              const numericValue = e.target.value;
                              if (numericValue <= BalanceFee) {
                                if (numericValue < 0) {
                                  alert.info(
                                    "Amount can not be less than zero"
                                  );
                                } else {
                                  handleInputChange(e);
                                }
                              } else {
                                alert.info(
                                  "Amount can not be greater than Balance Amount"
                                );
                              }
                            }}
                            disabled={selectStudent?.receipt_no ? true : false}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control text-right"
                            defaultValue={
                              selectStudent?.balance_fee
                                ? selectStudent?.balance_fee
                                : 0
                            }
                            readOnly
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <h6 className="page-title">Payment Information</h6>

                  <div className="d-flex py-1">
                    <label className="givenWigth m-auto">
                      Payment Mode <span className="text-danger">*</span>
                    </label>
                    <Select
                      options={PaymentList}
                      placeholder="Payment Mode"
                      value={PaymentList?.find(
                        (pay) => pay.value === PaymentMode
                      )}
                      //   defaultValue={PaymentList[0]}
                      onChange={(select) => {
                        handlePaymentMode(select);
                      }}
                      isSearchable={true}
                      className="form-control m-0 p-0"
                    />
                  </div>
                  {paymentInfo.payment_mode === "Cash" ? null : (
                    <>
                      {paymentInfo.payment_mode === "UPI" ? null : (
                        <div className="d-flex py-1">
                          <label className="givenWigth m-auto">Bank Name</label>
                          <input
                            disabled={
                              PaymentMode?.value === "Cash" ? true : false
                            }
                            className="form-control"
                            type="text"
                            placeholder="Bank Name"
                            name="bank_name"
                            value={paymentInfo.bank_name}
                            onChange={handleInputChange}
                          />
                        </div>
                      )}
                      <div className="d-flex py-1">
                        <label className="givenWigth m-auto">
                          {paymentInfo.payment_mode === "Bank"
                            ? "Account"
                            : paymentInfo.payment_mode}
                          Number
                        </label>
                        <input
                          disabled={
                            PaymentMode?.value === "Cash" ? true : false
                          }
                          className="form-control"
                          type="number"
                          name="pay_no"
                          placeholder={
                            paymentInfo.payment_mode === "Bank"
                              ? "Account Number"
                              : paymentInfo.payment_mode + " Number"
                          }
                          value={paymentInfo.pay_no}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="d-flex py-1">
                        <label className="givenWigth m-auto">Payment Date</label>
                        <input
                          disabled={
                            PaymentMode?.value === "Cash" ? true : false
                          }
                          className="form-control"
                          type="date"
                          placeholder="Payment Date"
                          name="pay_date"
                          value={paymentInfo.pay_date}
                          onChange={handleInputChange}
                        />
                      </div>
                    </>
                  )}
                  <div className="d-flex py-1">
                    <label className="givenWigth m-auto">Total Amount</label>
                    <input
                      className="form-control text-right"
                      type="number"
                      placeholder="Total Amount"
                      // value={(paymentInfo.total_amount = Sum_annual_fee())}
                      defaultValue={paymentInfo.paid_amount}
                      //   onChange={handleInputChange}
                      readOnly
                    />
                  </div>
                  <div className="d-flex py-1">
                    <label className="givenWigth m-auto">Late Charges</label>
                    <input
                      className="form-control text-right"
                      type="number"
                      placeholder="Late Charges"
                      value={paymentInfo.late_fee}
                      onChange={(event) => {
                        if (/^-?\d*(\.\d*)?$/.test(event.target.value)) {
                          const numericValue = Number(event.target.value);
                          // if (numericValue <= paymentInfo.total_amount) {
                          if (numericValue < 0) {
                            alert.info("Amount can not be less than zero");
                          } else {
                            setPaymentInfo((prevState) => ({
                              ...prevState,
                              late_fee: numericValue.toString(),
                            }));
                          }
                          // } else {
                          //   alert.info(
                          //     "Discount can not be greater than Total Amount"
                          //   );
                          // }
                        }
                      }}
                      disabled={selectStudent?.receipt_no ? true : false}
                    />
                  </div>
                  <div className="d-flex py-1">
                    <label className="givenWigth m-auto">Bounce Charges</label>
                    <input
                      className="form-control text-right"
                      type="number"
                      placeholder="Bounce Charges "
                      value={paymentInfo.bounce_fee}
                      onChange={(event) => {
                        if (/^-?\d*(\.\d*)?$/.test(event.target.value)) {
                          const numericValue = Number(event.target.value);
                          // if (numericValue <= paymentInfo.total_amount) {
                          if (numericValue < 0) {
                            alert.info("Amount can not be less than zero");
                          } else {
                            setPaymentInfo((prevState) => ({
                              ...prevState,
                              bounce_fee: numericValue.toString(),
                            }));
                          }
                          // } else {
                          //   alert.info(
                          //     "Discount can not be greater than Total Amount"
                          //   );
                          // }
                        }
                      }}
                      disabled={selectStudent?.receipt_no ? true : false}
                    />
                  </div>
                  <div className="d-flex py-1">
                    <label className="givenWigth m-auto">Paid Amount</label>
                    <input
                      className="form-control text-right"
                      type="number"
                      placeholder="Paid Amount"
                      // value={selectStudent?.receipt_no ? paymentInfo.total_amount: paid }
                      value={paid}
                      
                      
                      readOnly
                    />
                  </div>
                  <div className="d-flex py-1">
                    <label className="givenWigth m-auto">Remark</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Remark"
                      name="remark"
                      value={paymentInfo.remark}
                      onChange={handleInputChange}
                    />
                  </div>
                  {selectStudent?.payment_status !== "CANCELLED" && (
                    <PrintFeeRecipt
                      selectStudent={selectStudent}
                      receiptDate={receiptDate}
                      paymentInfo={paymentInfo}
                      schoolUser={schoolUser}
                      values={values}
                      // validateControl={validateControl}
                      handleClear={handleClear}
                      receiptType={receiptType}
                      paid={paid}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusFeeReceipt;
