import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './style.css'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Table,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { TimeTableAPI } from "../../../../api/TimeTable/timeTableAPI";
import Swal from "sweetalert2";
import useSessionRedirect from "../../Comman/SessionChange";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import TimetableTable from "./TimetableTable";
import {
  ClearButton,
  SaveButton,
  ViewButton,
} from "../../../CommonComponent/ReusableButton";
import { Heading } from "../../../CommonComponent/heading";
const GenerateTimeTable = () => {
  const token = localStorage.getItem("token");
  const session_id = useSessionRedirect();
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let school_id = school_user?.user_detail?.fk_school_id;
  let { school_emp_id } = school_user?.user_detail;
  const dispatch = useDispatch();
  const { classsectionList } = useSelector((state) => state.attendance);
  const [selectClass, setSelectClass] = useState(null);
  const [isClassName, setIsClassName] = useState(null);
  const [isSubjectData, setIsSubjectData] = useState([]);
  const [isButtonView, setIsButtonView] = useState(true);
  const [isButtonSave, setIsButtonSave] = useState(true);
  const [isTimetableData, setIsTimetableData] = useState([]);

  var subjectOptions = isSubjectData?.map((item) => ({
    value: item.fk_subject__id,
    label: item.fk_subject__subject_name,
  }));
  const teacherOption = isSubjectData?.map((item) => ({
    value: item.id,
    label: `${item.fk_staff__first_name} ${item.fk_staff__last_name}`,
  }));
  let classListOption = classsectionList?.payload?.map((c) => {
    return {
      label: `${c.class_name} ${c.section}`,
      value: c.id,
    };
  });

  let handleClear = () => {
    setSelectClass(null);
    setIsClassName(null);
    setIsButtonView(true);
    setIsButtonSave(true);
    setIsTimetableData([]);
    // setEdit(false);
  };
  let handleChangeClass = (select) => {
    setSelectClass(select.value);
    setIsClassName(select.label);
    setIsButtonView(false);
    setIsButtonSave(true);
    setIsTimetableData([]);
  };

  const handleSubjectChange = (selectedOption, rowIndex, dayIndex) => {
    const newData = [...isTimetableData];
    const timeSlot = Object.keys(newData[rowIndex])[0];
    const matchingTeacher = isSubjectData.find(
      (item) => item.fk_subject__id === selectedOption.value
    );

    const selectedSubject = {
      ...newData[rowIndex][timeSlot][dayIndex],
      Day: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][
        dayIndex
      ],
      subject_teacher_id: matchingTeacher ? matchingTeacher.id : "",
      teacher_name: matchingTeacher ? matchingTeacher.fk_staff__first_name : "",
      subject_id: selectedOption.value,
      subject_name: selectedOption.label,
    };
    newData[rowIndex][timeSlot][dayIndex] = selectedSubject;
    setIsTimetableData(newData);
  };

  const sendDataToServer = async () => {
    let data = isTimetableData.map((timeSlot) => {
      let dayData = timeSlot[Object.keys(timeSlot)[0]];
      return dayData.map((d) => {
        return {
          Day: d.Day,
          subject_teacher_id: d.subject_teacher_id,
          period_conf_id: d.period_conf_id,
        };
      });
    });

    let tableData = {
      academic_id: session_id,
      school_id: school_id,
      section_id: selectClass,
      insert_by: school_emp_id,
      timetable_data: data,
    };
    let response = await TimeTableAPI.createTimetableTeacherwise(tableData);
    let responseData = response.data;
    if (responseData.status === 200) {
      Swal.fire({
        text: responseData.msg,
        icon: "success",
        allowOutsideClick: false,
      });
    } else if (responseData.status === 403) {
      Swal.fire({
        text: responseData.msg,
        icon: "error",
        allowOutsideClick: false,
      });
    }
  };

  const handleFatchTimetableData = async () => {
    try {
      let data = {
        academic_id: session_id,
        school_id: school_id,
        section_id: selectClass,
      };
      const response = await TimeTableAPI.getTimetable(data);
      const responseSubject = await TimeTableAPI.getSubjectTeacher(data);
      const responseData = response.data;
      if (responseData.status === 200) {
        let timetableData = responseData.timetable;
        setIsTimetableData(timetableData);
        setIsSubjectData(responseSubject?.data?.teacher_list);
        setIsButtonSave(false);
      } else if (responseData.status === 403) {
        Swal.fire({
          text: responseData.msg,
          icon: "error",
          allowOutsideClick: false,
        });
      } else if (responseData.status === 500) {
        Swal.fire({
          text: responseData.msg,
          icon: "warning",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <Heading pageTitle={"Generate Time Table"}>
            {isTimetableData?.length > 0 && (
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button  btn btn-success"
                color="success"
                table="table-to-xls"
                filename="Timetable"
                sheet="tablexls"
                buttonText="EXPORT"
              />
            )}

            <ViewButton
              className="mx-1"
              onClick={handleFatchTimetableData}
              disabled={isButtonView}
            />

            <SaveButton
              className="mx-1"
              onClick={() => sendDataToServer()}
              disabled={isButtonSave}
              isEdit={false}
            />
            <ClearButton className="mx-1" onClick={() => handleClear()} />
          </Heading>
        </div>
      </div>
      <Card className="mt-4">
        <CardBody className="">
          <Row>
            <Col lg={4} md={6}>
              <Label className="form-check-label" htmlFor="inlineFormCheck">
                Select Class
              </Label>
              <Select
                name="class_id"
                placeholder="Select Class"
                options={classListOption}
                value={
                  selectClass
                    ? classListOption?.find((c) => c.value === selectClass)
                    : null
                }
                onChange={handleChangeClass}
                isSearchable={true}
              />
            </Col>
          </Row>
        </CardBody>
        <TimetableTable
          isTimetableData={isTimetableData}
          isClassName={"d-none"}
          selectedClassName={isClassName}
        />
        <CardBody className="">
          <div className="">
          <div style={{ overflowX: "auto", height: "400px" }}>
            <Table className="table table-striped text-nowrap  ">
              <thead>
                <tr className="bg-gray text-white">
                  <th className="text-white">Period Time / Day(s)</th>
                  <th className="text-center text-white">Monday</th>
                  <th className="text-center text-white">Tuesday</th>
                  <th className="text-center text-white">Wednesday</th>
                  <th className="text-center text-white">Thursday</th>
                  <th className="text-center text-white">Friday</th>
                  <th className="text-center text-white">Saturday</th>
                </tr>
              </thead>
              <tbody>
                {isTimetableData?.map((time, rowIndex) => {
                  let timeSlot = Object.keys(time)[0];
                  const schedule = time[timeSlot];
                  return (
                    <tr key={rowIndex}>
                      <td className="align-middle text-center" key={rowIndex}>
                        <span className="obj_bolder">{timeSlot}</span>
                      </td>
                      {schedule.map((t, dayIndex) => (
                        <td
                          className="align-middle text-center "
                          key={dayIndex}
                        >
                          {t.period_conf_id ? (
                            <>
                              <Select
                                className=" hh mb-1 "   
                                name="class_id "
                                placeholder="Subject" 
                                options={subjectOptions}
                                value={
                                  t.subject_id
                                    ? subjectOptions?.find(
                                        (c) => c.value === Number(t.subject_id)
                                      )
                                    : null
                                }
                                onChange={(selectedOption) =>
                                  handleSubjectChange(
                                    selectedOption,
                                    rowIndex,
                                    dayIndex
                                  )
                                }
                                isSearchable={true}
                               
                              />
                              <Select
                                className="hh "
                                name="class_id"
                                placeholder="Teacher"
                                options={
                                  t.subject_teacher_id
                                    ? [
                                        {
                                          value: t.subject_teacher_id,
                                          label: t.teacher_name,
                                        },
                                      ]
                                    : []
                                }
                                value={
                                  t.subject_teacher_id
                                    ? teacherOption?.find(
                                        (tec) =>
                                          tec.value ===
                                          Number(t.subject_teacher_id)
                                      )
                                    : null
                                }
                                isSearchable={true}
                              />
                            </>
                          ) : (
                            "-NA-"
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default GenerateTimeTable;
