import React, { useEffect } from "react";
import { useState } from "react";
import { BusReportAPI } from "../../../../api/Transport/Report";
import useSessionRedirect from "../../Comman/SessionChange";
import useLocalStorageData from "../../Comman/LocalStorageData";
import { convertDate } from "../../../CommonComponent/DateFormatUtil";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Pagination from "react-js-pagination";

const DailyBusFeeReport = () => {
  const [CurrentPage, setCurrentPage] = useState(1);
  const [Page, setPage] = useState(1);
  const resultPerPage = 10;
  const { school_id, token, school_emp_id, schoolUser } = useLocalStorageData();
  const session_id = useSessionRedirect();
  const currentDate = new Date();
  const oneMonthAgo = new Date();
  const [from_date, setFrom_date] = useState(
    oneMonthAgo.toISOString().substr(0, 10)
  );
  const [to_date, setTo_date] = useState(
    new Date().toISOString().substr(0, 10)
  );

  const [BusFeeData, setBusFeeData] = useState([]);

  const GetBusReportData = async () => {
    try {
      let data = {
        school_id: school_id,
        from_date: from_date,
        to_date: to_date,
        academic_id: session_id,
      };
      const response = await BusReportAPI.Dailybusfeereportapi(
        data,
        CurrentPage
      );
      const responseData = response.data;
      setBusFeeData(responseData.daily_fee_receipt);
      setPage(responseData.fee_receipt_count);

    } catch (err) {
      console.error(err);
    }
    console.log(BusFeeData);
  };
  useEffect(() => {
    GetBusReportData();
  }, [from_date, to_date , CurrentPage]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center ">
        <div className="header-action m-3">
          <h1 className="page-title ">Bus Fee Report</h1>
        </div>
        {/* <button className="btn btn-primary" onClick={GetBusReportData} type="button">Generate</button> */}
      </div>

      <div className="section-body mt-4">
          <div className="container-fluid">
      <div className="card mt-3">
        <div className="card-body">
          <div className="col-lg-12">
            <div className="row ">
              <div className="col-lg-5 col-md-6 col-sm-12">
                <div className={`input-group`}>
                  <label className="col-form-label col-6">
                    From Date<span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={from_date}
                    onChange={(e) => setFrom_date(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <div className={`input-group`}>
                  <label className="col-form-label col-6">
                    To Date<span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={to_date}
                    onChange={(e) => setTo_date(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-form-label  text-right">
                {BusFeeData?.length > 0 && (
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn-sm btn btn-success  "
                    color="success"
                    table="table-to-xls1"
                    filename="fee_Collection"
                    sheet="tablexls"
                    buttonText="EXPORT"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <table
              className="table table-striped table-sm text-nowrap text-center"
              id="table-to-xls1"
            >
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Receipt No.</th>
                  <th>Name</th>
                  <th>Receipt Date</th>
                  <th>Payment Mode</th>
                  <th>Payment Status</th>
                  <th>Paid Amount</th>
                </tr>
              </thead>
              <tbody>
                {BusFeeData?.map((list, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${
                        list.payment_status === "CANCELLED"
                          ? "table-danger"
                          : ""
                      } `}
                    >
                      <td>{(CurrentPage - 1) * 10 +index + 1}</td>
                      <td>{list.receipt_no}</td>
                      <td className="text-left">
                        {list.fk_student__fk_student__std_name}
                      </td>
                      <td>{convertDate(list.receipt_date)}</td>
                      <td className="">{list.payment_mode}</td>
                      <td className="">{list.payment_status}</td>
                      <td className="text-right">{list.paid_amount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <ul className="pagination justify-content-center">
              {BusFeeData?.length > 0 && (
                <Pagination
                  activePage={CurrentPage}
                  itemsCountPerPage={resultPerPage}
                  totalItemsCount={Page}
                  hideFirstLastPages={false}
                  onChange={(e) => setCurrentPage(e)}
                  nextPageText="Next"
                  prevPageText="Previous"
                  lastPageText="Last"
                  firstPageText="1st"
                  itemClass="page-item"
                  linkClass="page-link"
                  activeClass="active"
                  activeLinkClass="active"
                  hideDisabled={false}
                />
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default DailyBusFeeReport;
