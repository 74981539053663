import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./style.css";
import html2pdf from "html2pdf.js";
import { useParams } from "react-router-dom";
import { gradeMasterAPI } from "../../api/Examination/Grade";
import Card from "../schooladmin/Examination/GradeCard/Card";
const DownloadGradeCard = () => {
  document.title = "Grade Card";
  const [gradeCard, setGradeCard] = useState([]);
  const [cardInfo, setCardInfo] = useState([]);
  const [grade, setGrade] = useState([]);
  const { studentId } = useParams();

  //  /grade-card/1118/79/173/164/42
  const [
    studentIdPart,
    academicIdPart,
    schoolIdPart,
    sectionIdPart,
    examIdPart,
  ] = studentId.split("-");

  // Convert parts to integers if needed
  const stu_id = parseInt(studentIdPart);
  const academic_id = parseInt(academicIdPart);
  const school_id = parseInt(schoolIdPart);
  const section_id = parseInt(sectionIdPart);
  const exam_id = parseInt(examIdPart);

  const generateGradeCard = async () => {
    // this code is for external gragde card
    try {
      const data = {
        academic_id: academic_id,
        school_id: school_id,
        section_id: section_id,
        exam_id: exam_id,
        student_list: `[${stu_id}]`,
      };

      const response = await gradeMasterAPI.createGradeCard(data);
      let responseData = response.data;
      if (responseData.status === 200) {
        setGradeCard(responseData.GradeCard_list);
        setCardInfo(responseData.Info_dict);
        setGrade(responseData.grade_details);
        // toggle();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleConvertToPdf = async () => {
    gradeCard?.forEach(async (s, index) => {
      const element = document.getElementById(`divToConvert-${index}`);
      if (element) {
        const opt = {
          margin: 15,
          filename: `generated_${index}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 1 },
          jsPDF: { unit: "mm", format: "legal", orientation: "portrait" },
        };
        const pdfDataUri = await html2pdf()
          .from(element)
          .set(opt)
          .outputPdf("datauristring");
        const pdfBlob = await fetch(pdfDataUri).then((res) => res.blob());
        const link = document.createElement("a");
        link.href = URL.createObjectURL(pdfBlob);
        link.download = `generated_${index}.pdf`;
        link.click();
        URL.revokeObjectURL(link.href);
      }
    });
    setGradeCard([]);
  };
  useEffect(() => {
    generateGradeCard();
  }, [studentId]);

  return (
    <>
      <div
        className="d-flex justify-content-center align-item-center"
        style={{ height: "100vh" }}
      >
        <div className="align-self-center text-center">
          {gradeCard && gradeCard.length > 0 && (
            <>
              {" "}
              <p className="text-center grade-card-msg">
                {" "}
                Your Grade Card is ready. Please click the button below to
                download.
                <br />
                <i
                  className="fa fa-hand-o-down animated"
                  aria-hidden="true"
                  style={{ color: "#17a2b8", fontSize: "1.5rem" }}
                ></i>
              </p>
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm m-auto"
                onClick={handleConvertToPdf}
              >
                <i className="fa fa-download px-1"></i>Download
              </button>
            </>
          )}
        </div>
        <div className="d-none">
          {gradeCard &&
            gradeCard?.map((g, index) => (
              <Card
                key={index}
                g={g}
                id={`divToConvert-${index}`}
                grade={grade}
                cardInfo={cardInfo}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default DownloadGradeCard;
