import React from "react";
import Select from "react-select";

export const SmallSelectField = ({

  // all the small dropdown fields
  name,
  placeholder,
  options,
  value,
  onChange,
  ...props
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "25.5px",
      fontSize: "9px",
      minHeight: "25.5px",
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: "9px",
      minHeight: "25px",
      zIndex: "999999999",
    }),
  };
  return (
    <Select
      name={name}
      placeholder={placeholder}
      options={options}
      value={value ? options?.find((option) => option.value === value) : null}
      onChange={onChange}
      isSearchable={true}
      {...props}
      styles={{
        control: (provided) => ({ 
          ...provided,
          height: "25.5px",
          fontSize: "9px",
          minHeight: "25.5px",
        }),
        menu: (prev) => ({
          ...prev,
          backgroundColor: "#fff",
          fontSize: "9px",
          minHeight: "25px",
          zIndex: "999",
        }),
      }}
    />
  );
};
export const SelectField = ({
  name,
  placeholder,
  options,
  value,
  onChange,
  ...props
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "25.5px",
      fontSize: "9px",
      minHeight: "25.5px",
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: "9px",
      minHeight: "25px",
      zIndex: "999999999",
    }),
  };
  return (
    <Select
      name={name}
      placeholder={placeholder}
      options={options}
      value={value ? options?.find((option) => option.value === value) : null}
      onChange={onChange}
      isSearchable={true}
      menuPosition="fixed"
      {...props}
      styles={{
        menu: (prev) => ({
          ...prev,
          backgroundColor: "#fff",
          zIndex: 9999,
        }),
      }}
    />
  );
};
