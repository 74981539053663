import React, { useEffect, useState } from "react";
import CustomScrollbar from "../../../CommonComponent/PerfectScrollbar";
import { SMSNotificationAPI } from "../../../../api/Administration/SMSNotification";
import useLocalStorageData from "../../Comman/LocalStorageData";
import useSessionRedirect from "../../Comman/SessionChange";
import useLoading from "../../../CommonComponent/useLoading";
import Loader from "../../../Loader/Loader";
import EmptyListMessage from "../../../CommonComponent/EmptyListMessage";
import Swal from "sweetalert2";

const SMSNotification = (props) => {
  const { school_id, token, school_emp_id, designation_id } = props;
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [menuList, setMenuList] = useState([]);
  const fetchMenuList = async () => {
    startLoading();
    try {
      let data = {
        school_id: school_id,
        designation_id: designation_id,
      };
      let response = await SMSNotificationAPI.getSMSModuleList(data);
      let responseData = response.data;
      setMenuList(responseData.sms_mododuleList);
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };
  useEffect(() => {
    fetchMenuList();
  }, [designation_id]);


  
  const handleAddMenu = (index, fildName) => {
    const updateMenuList = [...menuList];
    updateMenuList[index][fildName] =
      updateMenuList[index][fildName] === "True" ? "False" : "True";
    setMenuList(updateMenuList);
  };
  const handleChangeSettings = async () => {
    startLoading();
    try {
      let list = menuList?.map(({ menu_name, ...rest }) => rest);
      const data = {
        school_id: school_id,
        insert_by: school_emp_id,
        menu: JSON.stringify(list),
      };
      let response = await SMSNotificationAPI.addUpdateSMSSetting(data);
      let responseData = response.data;
      if (responseData.status === 200) {
        Swal.fire({
          text: responseData.msg,
          icon: "success",
          imageAlt: "success image",
        });
        // fetchData();
        // handleClear();
      }
      if (responseData.status === 403) {
        Swal.fire({
          text: responseData.msg,
          icon: "error",
          imageAlt: "error image",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };
  if (isLoading) return <Loader />;
  return (
    <>
      <div className="card">
        <div className="card-body">
          <CustomScrollbar
            style={{
              height: "53vh",
              paddingRight: "1rem",
              boxSizing: "content-box",
            }}
          >
            <div className="table-responsive">
              {menuList && menuList?.length > 0 ? (
                <table className="table table-hover table-vcenter table-striped table-sm text-nowrap">
                  <thead>
                    <tr className="text-center">
                      <th>Sr No</th>
                      <th>menu name</th>
                      <th>sms</th>
                      <th>notification</th>
                    </tr>
                  </thead>
                  <tbody>
                    {menuList?.map((menu, index) => (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td>{menu.menu_name}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={menu.sms === "True"}
                            className="form-control"
                            style={{ cursor: "pointer" }}
                            onChange={() => handleAddMenu(index, "sms")}
                          />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            checked={menu.notification === "True"}
                            className="form-control"
                            style={{ cursor: "pointer" }}
                            onChange={() =>
                              handleAddMenu(index, "notification")
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <EmptyListMessage message={"Menu Not Available !"} Div="div" />
              )}
            </div>
          </CustomScrollbar>
          <div className="d-flex justify-content-end align-item-center">
            <button
              onClick={handleChangeSettings}
              type="button"
              className="mr-1 btn btn-primary px-3"
            >
              <i className="fa fa-plus px-1"></i>Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SMSNotification;
