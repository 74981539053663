// export const BASE_URL = "http://13.202.144.0:8000/";
// export const MEDIA_BASE_URL = "http://13.202.144.0:8000/media/";
// export const BASE_URL_ROUTER = "http://13.202.144.0:8000/router/";


// export const BASE_URL = "http://13.235.243.187:8040/";
// export const MEDIA_BASE_URL = "http://13.235.243.187:8040/media/";
// export const BASE_URL_ROUTER = "http://13.235.243.187:8040/router/";

export const BASE_URL = "https://api.campuslink.pro/";
export const MEDIA_BASE_URL = "https://api.campuslink.pro/media/";
export const BASE_URL_ROUTER = "https://api.campuslink.pro/router/";