import React from "react";
import useLocalStorageData from "../Comman/LocalStorageData";
import SchoolDashboard from "./SchoolDashboard";
import TeacherDashboard from "./TeacherDashboard";

const Dashboard = () => {
  // this code containes 2 different dashboard for teacher and school
  const { school_id, token, school_emp_id, schoolUser } = useLocalStorageData();
  const { user_type, name, user_detail } = schoolUser;
  const { user_type_dashboard } = user_detail;

  return (
    <>
      {user_type_dashboard !== "Teacher" ? (
        <SchoolDashboard />
      ) : (
        <TeacherDashboard />
      )}
    </>
  );
};

export default Dashboard;
