import React from "react";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Header from "./Header";

const Layout = (props) => {
  // this contains all the layout of the page with sidebar , header , hero section and footer
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  return (
    <div id="main_content">
      <Sidebar />
      <div className="page">
        <Header school_user={school_user} />
        {props.children}
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
