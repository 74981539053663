import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Row, Table } from "reactstrap";
import { SmallSelectField as SelectField } from "../../CommonComponent/SelectField";
import { SmallSelectField as SelectField1 } from "../../CommonComponent/SelectField1";
import BarChart from "./BarChart";
import img1 from "../../../assets/image/img1.png";
import img2 from "../../../assets/image/img3.png";
import img3 from "../../../assets/image/staff.png";
import Event from "./Event";
import HolidayCarousel from "./Holiday";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "../../../actions/Dashboard/Dashboard";
import useLocalStorageData from "../Comman/LocalStorageData";
import useLoading from "../../CommonComponent/useLoading";
import useSessionRedirect from "../Comman/SessionChange"; 
import { SessionContext } from "../../../context/sessionContext";
import { useClass } from "../../../context/attendanceContext";
import EmptyListMessage from "../../CommonComponent/EmptyListMessage";
import { getTeacherList } from "../../../actions/school_user/StaffMaster";
import { DayList } from "../../../data/optionData";
import Select from "react-select";
import {
  BirthdayList,
  CustomCard,
  Notice,
  PresidantMessage,
  TestScheduleList,
  TopPerformers,
  Slideshow
} from "./CustomCard";
import Loader from "../../Loader/Loader";
import TimeTableList from "./TimeTableList";
import DonutChart from "./DonutChart";
import { NavLink } from "react-router-dom";

const SchoolDashboard = () => {
  const { school_id, token, school_emp_id, schoolUser } = useLocalStorageData();
  const { user_type, name, user_detail } = schoolUser;
  const { user_type_dashboard } = user_detail;
  // const session_id = useSessionRedirect();
  const { Session } = useContext(SessionContext);
  const session_id = Session || sessionStorage.getItem("SessionId");
  const { isLoading, startLoading, stopLoading } = useLoading();
  const { dashboard, loading } = useSelector((state) => state.Dashboard);
  const { staffdata } = useSelector((state) => state.staffMaster);
  const dispatch = useDispatch();
  const { allClassList } = useClass();
  const [selectClass, setSelectClass] = useState("");
  const [selectDay, setSelectDay] = useState("Monday");
  const [selectedTeacher, setSelectedTeacher] = useState(""); 

  //   const base64Image = useSelector((state) => state.image.base64Image)

  //   const fetchData = async () => {
  //     dispatch(fetchImageRequest());
  //     try {
  //       const imageBase64 = await SchoolImage.fetchImageFromAPI(school_id);
  //       if (imageBase64) {
  //         dispatch(fetchImageSuccess(imageBase64));
  //       } else {
  //         console.error("Image fetching failed");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching image:", error);
  //       dispatch(fetchImageFailure(error));
  //     } finally {
  //       // ...
  //     }
  //   };

  // console.log(base64Image)

  const {
    student_count,
    other_staffcount,
    teacher_count,
    holiday,
    staff_birthday,
    fees_summary_list,
    attendance_list,
    birth_daylist,
    upcomming_list,
    time_tabtable_list,
    circulars,
    eventactivity,
    top_performance,
    banner_images,
    allpresent_count,
    allabsent_count,
    is_holiday_school_admin,
    school_count,
    addmitated_student,
    organization_count,
    staff_count,
    school_profile_list,
    president_message,
    president_image,
    president_introduction,
    school_banner,

  } = dashboard?.payload || [];


  useEffect(() => {
    let body = {
      school_id: school_id,
      academic_id: session_id,
      class_id: selectClass,
      section_id: "",
      staff_id: selectedTeacher,
      day: selectDay,
      user_type: user_type_dashboard,
    };

    dispatch(getDashboardData(body));
  }, [
    school_id,
    session_id,
    user_type_dashboard,
    selectClass,
    selectedTeacher,
    selectDay,
  ]);

  let feeSummary = fees_summary_list?.reduce(
    (acc, current) => {
      acc.paid_fee.push(current.paid_fee);
      acc.balance_fee.push(current.balance_fee);
      acc.fee_heads_name.push(current.fee_heads_name);
      return acc;
    },
    { paid_fee: [], balance_fee: [], fee_heads_name: [] }
  );

  useEffect(() => {
    if (token) {
      dispatch(getTeacherList(token, school_id));
    }
  }, [dispatch, token]);

  let staffList = staffdata?.payload
    ?.map((school) => {
      return {
        label: `${school.first_name} ${school.last_name}`,
        value: school.id,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
  useEffect(() => {
    if (allClassList?.length > 0) {
      setSelectClass(selectClass ? selectClass : allClassList[0].value);
    }
    if (staffList?.length > 0) {
      setSelectedTeacher(
        selectedTeacher ? selectedTeacher : staffList[0].value
      );
    }
  }, [allClassList, staffList]);
  //   if (loading) return <Loader />;
  return (

    <div className="section-body ">
      <div className="container-fluid ">
        <Row className="mt-3">
          <Col lg={4} md={6} sm={6}>
            <NavLink to="/school/student-reports">
              <CustomCard
                heading={"Total Students"}
                count={student_count}
                colorBg={"#6587C1"}
                nameClass={""}
                img={img1}
              />
            </NavLink>
          </Col>
          <Col lg={4} md={6} sm={6}>
            {/* <CustomCard
              heading={"Total Teachers"}
              count={teacher_count}
              colorBg={"#F0DBAF"}
              nameClass={"bg-b-yellow "}
              img={img2}
            /> */}
          </Col>
          <Col lg={4} md={6} sm={6}>
            <NavLink to="/school/staff-master">
              <CustomCard
                heading={"Total Staff"}
                count={other_staffcount}
                colorBg={"#7ED7C1"}
                nameClass={"bg-b-pink"}
                img={img3}
              />
            </NavLink>
          </Col>
        </Row>
        <div className="row">
        <div className="col-lg-4 col-md-12 col-sm-12">
            <Card style={{minHeight:"98%"}}>
              <CardBody>
                <div className="form-group">
              <Event eventactivity={eventactivity}/>
              </div>
              <div className="form-group">
                <h3 className="card-title ">
                  Today's Birthday
                  <i className="fa fa-user-circle-o px-2"></i>(Staff)
                  <hr className="m-1" />
                </h3>
                <BirthdayList data={staff_birthday} />

                <h5 className="card-title ">
                  <i className="fa fa-bullhorn"></i> upcoming Holiday
                  <hr className="m-1" />
                </h5>
                {holiday && holiday.length > 0 ? (
                  <HolidayCarousel holiday={holiday} />
                ) : (
                  <EmptyListMessage
                    message={"Holiday Not Available !"}
                    Div="div"
                    size={"1.2rem"}
                  />
                )}
                </div>

                
              </CardBody>
            </Card>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12">
            <Card>
              <CardBody>
                <>
                  {" "}
                  <Row className="mb-2">
                    <Col lg={8} md={6} sm={6}>
                      <h3 className="card-title"> Fee Summary</h3>
                    </Col>
                    <Col lg={4} md={6} sm={6} className="dash-select">
                      <SelectField1
                        placeholder={"Select Class"}
                        options={allClassList}
                        value={selectClass}
                        onChange={(select) => setSelectClass(select.value)}
                      />
                    </Col>

                   
                  </Row>
                  <div>
                    <BarChart
                      dataColors='["#9D7EE0","#ffcb80"]'
                      balance={feeSummary?.balance_fee}
                      paid={feeSummary?.paid_fee}
                      heads_name={feeSummary?.fee_heads_name}
                      chartFor={"FeeSummery"}
                    />
                  </div>{" "}
                </>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>Notice</CardTitle>
                <Notice circulars={circulars} />
              </CardBody>
            </Card>
          </div>
         
        </div>
        <Row>
        <Col lg={6} >  
            <Card style={{minHeight:"98%"}}>
              <CardBody>
                <CardTitle>President Message</CardTitle>
                <PresidantMessage
                  presidentData={{
                    president_message,
                    president_image,
                    president_introduction,
                  }}
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg={6} > 
           
          
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h3 className="card-title"> Top Performers </h3>
                  <div className="dash-select">
                    <SelectField1
                      placeholder={"Select Class"}
                      options={allClassList}
                      value={selectClass}
                      onChange={(select) => setSelectClass(select.value)}
                    />
                  </div>
                </div>
                <TopPerformers top_performance={top_performance} />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>Upcoming Online Test</CardTitle>
                <TestScheduleList data={upcomming_list} />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle className="mb-4"> Attendance</CardTitle>
                <DonutChart
                  dataColors='["#9D7EE0","#59e0c5", "#808080"]'
                  allpresent_count={allpresent_count}
                  allabsent_count={allabsent_count}
                  is_holiday_school_admin={is_holiday_school_admin || ""}
                />
              </CardBody>
            </Card>
          </Col>
        
        </Row>
       
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Card>
              <CardBody>
                <Row className="mb-2">
                  <Col lg={6}>
                    <h3 className="card-title"> Time-Table Summary </h3>
                  </Col>
                  <Col lg={3} className="dash-select">
                    {" "}
                    <SelectField1
                      placeholder={"Select Teacher"}
                      options={staffList}
                      value={selectedTeacher}
                      onChange={(selectedOption) =>
                        setSelectedTeacher(selectedOption.value)
                      }
                    />{" "}
                  </Col>
                  <Col lg={3} className="dash-select">
                    {" "}
                    <SelectField1
                      placeholder={"Select Day"}
                      options={DayList}
                      value={selectDay}
                      onChange={(select) => setSelectDay(select.value)}
                    />{" "}
                  </Col>
                </Row>
                <TimeTableList time_tabtable_list={time_tabtable_list} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {school_banner && school_banner.length > 0 && (
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div >
              <div className="hh">
                <Slideshow images={school_banner} />
              </div>
            </div>
          </Col>
        </Row>
      )}
      </div>
    </div>
 
  );
};

export default SchoolDashboard;
