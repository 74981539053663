import React, { useEffect } from "react";
import { useState } from "react";
import { BusReportAPI } from "../../../../api/Transport/Report";
import useSessionRedirect from "../../Comman/SessionChange";
import useLocalStorageData from "../../Comman/LocalStorageData";
import { convertDate } from "../../../CommonComponent/DateFormatUtil";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Pagination from "react-js-pagination";
import Select from "react-select";

const BusRouteReport = () => {
  const [CurrentPage, setCurrentPage] = useState(1);
  const [Page, setPage] = useState(1);
  const resultPerPage = 10;
  const { school_id, token, school_emp_id, schoolUser } = useLocalStorageData();
  const [selectBus, setSelectBus] = useState([]);
  const [regNo, setRegNo] = useState("");
  const [studentData, setStudentData] = useState([]);
  const [RouteOptions, setRouteOptions] = useState([]);
  const session_id = useSessionRedirect();

  const getRouteConfiguration = async () => {
    let data = {
      school_id: school_id,
    };
    const response = await BusReportAPI.getRouteConfigurationapi(
      data,
      CurrentPage
    );
    const responseData = response.data.route_configuration;

    const filteredBusOptions = responseData.map((bus) => ({
      value: bus.id,
      label: bus.route_name,
      BusName: bus.fk_busMaster__registration_no,
    }));

    setRouteOptions(filteredBusOptions);
  };
  useEffect(() => {
    getRouteConfiguration();
  }, []);

  const getStudentRouteData = async () => {
    let data = {
      school_id: school_id,
      route_id: selectBus || "",
      academic_id: session_id,
      bus_no: regNo || "",
    };

    const response = await BusReportAPI.BusDetailReport(data, CurrentPage);
    const responseData = response.data;
    setStudentData(responseData.bus_detail);
  };
  console.log(studentData);

  let handleChangeClass = (select) => {
    setSelectBus(select.value);
    setRegNo(select.BusName);
  };
  const handelClear = () => {
    setSelectBus(null);
    setRegNo("");
    setStudentData([]);
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center mx-1">
          <div className="header-action m-3">
            <h1 className="page-title">Bus Route Report</h1>
          </div>
          <div className="d-flex">
            <button
              className="btn btn-outline-secondary m-1 "
              onClick={getStudentRouteData}
            >
              Generate
            </button>
            <button
              className="btn btn-outline-danger m-1"
              onClick={handelClear}
            >
              {" "}
              <i class="fa fa-times px-1"></i>Clear
            </button>
          </div>
        </div>
        </div>

        <div className="section-body mt-4">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-5 col-md-6 col-sm-6 my-1">
                    <div className="row">
                      <label className="col-form-label col-md-3">Route Name</label>
                      <div className="col-md-9">
                      <Select
                        options={RouteOptions}
                        placeholder="Route name"
                        value={
                          selectBus
                            ? RouteOptions.find((c) => c.value === selectBus)
                            : null
                        }
                        isSearchable={true}
                        onChange={handleChangeClass}
                      />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 col-sm-6 my-1">
                    <div className={`input-group`}>
                      <label className="col-form-label  mr-2">Bus No</label>
                      <input
                        type="text"
                        className="form-control"
                        value={regNo}
                        placeholder="Bus no"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-form-label text-right">
                    {studentData?.length > 0 && (
                      <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button btn-sm btn btn-success"
                        color="success"
                        table="table-to-xls1"
                        filename="Report"
                        sheet="tablexls"
                        buttonText="EXPORT"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
       
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Bus Details</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  {studentData && studentData?.length > 0 ? (
                    <>
                      <table
                        className="table table-bordered table-hover text-nowrap table-sm"
                        id="table-to-xls1"
                      >
                        <thead>
                          <tr className="text-center">
                            <th> Sr No</th>
                            <th>Pick Up Point</th>
                            <th>Bus Number</th>
                            <th>Distance(KM)</th>
                            <th>Fee Amount</th>
                            <th>Pickup Time</th>
                            <th>Drop Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {studentData?.map((st, dIndex) => {
                            return (
                              <tr key={dIndex}>
                                <td>{dIndex + 1}</td>
                                <td>{st.pickup_point}</td>
                                <td>
                                  {st.fk_route__fk_busMaster__registration_no}
                                </td>
                                <td>{st.distance}</td>
                                <td>{st.fee_amount}</td>
                                <td>{st.pickup_time}</td>
                                <td>{st.drop_time}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <ul className="pagination justify-content-center">
                        {studentData?.length > 10 && (
                          <Pagination
                            activePage={CurrentPage}
                            itemsCountPerPage={resultPerPage}
                            totalItemsCount={Page}
                            hideFirstLastPages={false}
                            onChange={(e) => setCurrentPage(e)}
                            nextPageText="Next"
                            prevPageText="Previous"
                            lastPageText="Last"
                            firstPageText="1st"
                            itemClass="page-item"
                            linkClass="page-link"
                            activeClass="active"
                            activeLinkClass="active"
                            hideDisabled={false}
                          />
                        )}
                      </ul>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
      
    </div>
  );
};

export default BusRouteReport;
