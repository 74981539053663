import React, { useEffect, useState } from "react";
import StudentPickUpModel from "./Models/StudentPickUpModel";
import ChangeModal from "./Models/ChangeModal";
import StudentSessionWiseList from "../Comman/StudentListSessionWise";
import Select from "react-select";
import { useSelector } from "react-redux";
import useSessionRedirect from "../Comman/SessionChange";
import { StudentBusMappingAPI } from "../../../api/Transport/StudentBussMapping";
import useLocalStorageData from "../Comman/LocalStorageData";
import { Link, useLocation } from "react-router-dom";

const StudentPickup = () => {
// this file is for the student mapping to the picakup point
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const [selectedId, setSelectedId] = useState(null);
  const [studentList, setStudentList] = useState([]);
  const [data, setData] = useState(false);
  const [student_uid, setstudent_uid] = useState();
  const { classsectionList } = useSelector((state) => state.attendance);
  const [selectedOption, setSelectedOption] = useState("");
  const session_id = useSessionRedirect();
  const data1 = classsectionList?.payload?.map((c) => ({
    value: c.id,
    label: `${c.class_name} - ${c.section}`,
  }));
 const location = useLocation();
  const { SelectedClassId } = location.state || {}; 
 

useEffect(() => {
  if(SelectedClassId){
    setSelectedId(SelectedClassId)
  }
},[])
  useEffect(() => {
    if (session_id && selectedId || SelectedClassId) {
      getStudentListForBusMapping();
    }
  }, [session_id, selectedId]);
  const handleOptionChange = (selectedOption) => {
    setSelectedId(selectedOption.value);
  };

  const getStudentListForBusMapping = async () => {
    let data = {
      school_id: school_id,
      academic_id: session_id,
      section_id: SelectedClassId? SelectedClassId : selectedId,
    };
    const response = await StudentBusMappingAPI.studentlistforRouteMapping(
      data
    );
    const responseData = response.data;
    const Data = responseData.student_list;
    console.log(Data ,'Data')
    setStudentList(Data);
  };

  // console.log(selectedId)
  console.log()
  const handleDelete = (id)=>{
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let data = {
            
              route_mapped_id:id,
              insert_by:school_emp_id
            
          };
          let response = await StudentBusMappingAPI.studentStopBusApi( 
            data
          );
          let responseData = response.data;
          if (responseData.status === 200) {
            Swal.fire({
              title: "Success",
              text: responseData.msg,
              icon: "success",
            })
            getStudentListForBusMapping();
          }
        } catch (err) {
          console.error(err);
          Swal.fire({
            title: "Error",
            text: err.response.data.msg,
            icon: "error",
          });
        }
      }
    });
  }



  return (
    <>
      <div className="container-fluid mt-2">
        <div className="d-flex justify-content-between align-items-center ">
          <div className="header-action">
            <h1 className="page-title">Student Pickup Point Mapping</h1>  
          </div>
        </div>
      </div>

      <div className="section-body mt-4">
          <div className="container-fluid">
        <div className="card ">
          <div className="card-body ">

          <div className="col-lg-5 col-md-6 col-sm-12 my-1">
                  <div className="row">
                    <label className="col-form-label col-md-3">Class</label>
                    <div className="col-md-9">
                      <Select
                        options={data1}
                        placeholder="Select class"
                        value={
                          selectedId
                            ? data1?.find(
                                (option) => option.value == selectedId
                              )
                            : null
                        }
                        onChange={handleOptionChange}
                      />
                    </div>
                  </div>
                </div>

            </div>
          </div>

          <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Student List</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-striped text-nowrap">
                    <thead>
                      <tr className="text-left">
                        <th>Sr No</th>
                        <th>Student No</th>
                        <th>Student Name</th>
                        <th>Class</th>
                        <th>Fee Amount</th>
                        <th>Assign Date</th>
                        <th>Bus Start Date</th>
                        <th>Route Pickup Point</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {studentList &&
                        studentList.map((student, index) => (
                        
                          <tr key={student.student_id} className="text-center">
                            <td className=" text-left">{index + 1}</td>
                            <td className="text-left">
                              {student.student_code}
                            </td>
                            <td className="text-left">
                              {student.student_name}
                              {console.log(student)}
                            </td>
                            <td className="text-left">
                              {student.class}
                            </td>
                            <td className="text-left">
                              {student.total_amount}
                            </td>
                            <td className="text-left">
                              {student.assign_date}
                            </td>
                            <td className="text-left">
                              {student.bus_start_date}
                            </td>
                            <td className="text-left">
                              {student.pickup_point}
                            </td>
                            <td className="text-center d-flex">
                              {student.bus_assign === "False" ? (
                                <Link
                                  className="btn btn-primary btn-sm"
                                  to="/school/Add-Assign-Pickup-Point"
                                  state={{ id: student.student_id ,section:selectedId}}
                                >
                                  <i className="fa fa-plus-square pr-1"></i>
                              <p style={{fontSize:'11px', display:'inline',}}>Add Pick Up Point</p>    
                                </Link>
                              ) : (
                                <>
                                  <button className="btn btn-outline-danger btn-sm m-1" 
                                   onClick={() => handleDelete(student.student_route_map_id)}>
                                    <i className="fa fa-trash"></i>
                                  </button>
                                  <Link className="btn btn-outline-secondary btn-sm m-1"
                                   to="/school/Add-Assign-Pickup-Point"
                                   state={{ id: student.student_id ,studentRouteMapId:student.student_route_map_id ,section:selectedId }}>
                                    <i className="fa fa-edit"></i>
                                  </Link>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              </div>
              </div>
          
        </div>
      </div>
      </div>
    </>
  );
};

export default StudentPickup;
