import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  getClass,
  getSection,
} from "../../../../actions/Admission/StudentReport";
import Swal from "sweetalert2";
import { feeDiscountAPI } from "../../../../api/Admission/feeDiscountAPI";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import useSessionRedirect from "../../Comman/SessionChange";
import useLoading from "../../../CommonComponent/useLoading";
import Loader from "../../../Loader/Loader";
import { ExportButton } from "../../../CommonComponent/ReusableButton";
import { Heading } from "../../../CommonComponent/heading";
const FeeOutstandingReport = () => {
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { email, id, user_type, name, user_detail } = school_user;
  const { school_emp_id } = user_detail;
  let school_id = school_user?.user_detail?.fk_school_id;
  const session_id = useSessionRedirect();
  const { allClass } = useSelector((state) => state.getClass);
  const { allSection } = useSelector((state) => state.getSection);
  const dispatch = useDispatch();
  const [feeHeadsList, setFeeHeadsList] = useState([]);
  const [outstandingReport, setOutstandingReport] = useState([]);
  const [Class, setClass] = useState("");
  const [Section, setSection] = useState("");
  const { isLoading, startLoading, stopLoading } = useLoading();

  const handleClear = () => {
    setClass("");
    setFeeHeadsList([]);
    setOutstandingReport([]);
  };

  const classList = allClass?.payload?.map((list) => {
    return {
      label: list.class_name,
      value: list.id,
    };
  });

  const sectionList = allSection?.payload?.map((list) => {
    return {
      label: list.section,
      value: list.id,
    };
  });

  useEffect(() => {
    const body = {
      class_id: Number(Class?.value),
    };
    dispatch(getSection(body));
  }, [dispatch, Class]);

  const fetchData = async () => {
    if (!Class?.label) {
      Swal.fire({
        title: "",
        text: "Please Select Class.",
        icon: "warning",
        allowOutsideClick: false,
      });
      return;
    }

    const body = {
      academic_id: session_id,
      school_id: school_id,
      class_id: Class?.value,
    };
    startLoading();
    try {
      let response = await feeDiscountAPI.getFeeOutstandingReport(body);
      if (response.data.status === 200) {
        setFeeHeadsList(response.data.fees_heads_data);
        setOutstandingReport(response.data.outstanding_report);
        if (response.data.outstanding_report.length === 0) {
          Swal.fire({
            text: "No record found.",
            icon: "warning",
            title: "ALERT",
            allowOutsideClick: false,
          });
        }
      }
      if (response.data.status === 500) {
        Swal.fire({
          text: response.data.msg,
          icon: "warning",
          allowOutsideClick: false,
        });
      }
      if (response.data.status === 403) {
        Swal.fire({
          text: response.data.msg,
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const handleFetchData = () => {
    fetchData();
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const today = new Date();
  const formattedDate = formatDate(today);

  const totalColumnTotals = new Array(feeHeadsList?.length).fill(0);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <Heading pageTitle={"Fee Outstanding Report"}>
            <button
              type="button"
              className="btn btn-outline-secondary mx-2"
              onClick={handleFetchData}
            >
              GENERATE
            </button>

            <button
              type="button"
              className="btn btn-outline-danger mx-2"
              onClick={handleClear}
            >
              <i className="fa fa-times px-1"></i>
              CLEAR
            </button>
          </Heading>
        </div>
      </div>
      <br />
      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-5 col-md-6 col-sm-12 my-1">
                  <div className="row">
                    <label className="col-form-label col-md-3">
                      Class<span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <Select
                        options={classList}
                        placeholder="Select Class"
                        value={Class}
                        onChange={(e) => setClass(e)}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6 my-1 text-right">
                  {outstandingReport?.length > 0 && (
                    <ExportButton id="test-table-xls-button" />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Fee Summary</h3>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    {outstandingReport && outstandingReport?.length > 0 ? (
                      <table
                        className="table table-bordered table-hover text-nowrap table-sm"
                        id="table-to-xls"
                      >
                        <thead>
                          <tr className="text-center">
                            <th> Sr No</th>
                            <th>Student Name</th>
                            <th>Student Status</th>
                            <th>Concession</th>
                            {feeHeadsList?.map((head, index) => (
                              <th key={index}>{head.fk_feeshead__name}</th>
                            ))}
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {outstandingReport?.map((stu, index) => {
                            {
                              console.log(stu);
                            }
                            let Class_Name = Object.keys(stu)[0];
                            let class_students = stu[Class_Name];
                            const columnTotals = new Array(
                              feeHeadsList.length
                            ).fill(0);

                            return (
                              <>
                                <tr className="table-secondary text-success">
                                  <td colSpan={5 + feeHeadsList.length}>
                                    Class Section:{" "}
                                    <span className="">{`${Class?.label}-${Class_Name} `}</span>
                                  </td>
                                </tr>

                                {class_students?.map((st, dIndex) => {
                                   const rowClassName =
                                   st.student_status === "Cancelled"
                                     ? "table-danger"
                                     : "";
                                  if (st.student_status !== "Cancelled") {
                                    st.fees_heads_data.forEach((s, cIndex) => {
                                      if (!isNaN(Number(s.balance_fee))) {
                                        columnTotals[cIndex] += Number(
                                          s.balance_fee
                                        );
                                      }
                                    });
                                  } 
                                  return (
                                    <tr className={`text-center ${rowClassName}`} key={dIndex} >
                                      <td>{dIndex + 1}</td>
                                      <td className="text-left">
                                        {st.stu_name}
                                      </td>
                                      <td>{st.student_status}</td>
                                      <td>{st.concession}</td>
                                      {st.fees_heads_data.map((s) => (
                                        <td className="text-right">
                                          {s.balance_fee}
                                        </td>
                                      ))}
                                      <td className="text-right">
                                        {st.total_balance_amount}
                                      </td>
                                    </tr>
                                  );
                                })}
                                <tr className="text-right text-primary">
                                  <td colSpan="4">Total:</td>
                                  {columnTotals?.map((total, cIndex) => (
                                    <td key={cIndex}>{`${total}`}</td>
                                  ))}
                                  {columnTotals?.forEach((total, cIndex) => {
                                    totalColumnTotals[cIndex] += total;
                                  })}
                                </tr>
                              </>
                            );
                          })}
                          <tr className="text-right text-danger">
                            <td colSpan="4">Total:</td>
                            {totalColumnTotals?.map((total, cIndex) => (
                              <td key={cIndex} className="fw-bolder h5">
                                <b>{`${total}`}</b>
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeeOutstandingReport;
