import React, { useEffect } from "react";
import { useState } from "react";
import { BusReportAPI } from "../../../../api/Transport/Report";
import useSessionRedirect from "../../Comman/SessionChange";
import useLocalStorageData from "../../Comman/LocalStorageData";
import { convertDate } from "../../../CommonComponent/DateFormatUtil";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Pagination from "react-js-pagination";
import Select from "react-select";
const StudentBusRouteReport = () => {
  const [CurrentPage, setCurrentPage] = useState(1);
  const [Page, setPage] = useState(1);
  const resultPerPage = 10;
  const { school_id, token, school_emp_id, schoolUser } = useLocalStorageData();
  const [selectBus, setSelectBus] = useState([]);
  const [regNo, setRegNo] = useState("");
  const [studentData, setStudentData] = useState([]);
  const [RouteOptions, setRouteOptions] = useState([]);
  const session_id = useSessionRedirect();

  const getRouteConfiguration = async () => {
    let data = {
      school_id: school_id,
    };
    const response = await BusReportAPI.getRouteConfigurationapi(
      data,
      CurrentPage
    );
    const responseData = response.data.route_configuration;

    const filteredBusOptions = responseData.map((bus) => ({
      value: bus.id,
      label: bus.route_name,
      BusName: bus.fk_busMaster__registration_no,
    }));

    setRouteOptions(filteredBusOptions);
  };
  useEffect(() => {
    getRouteConfiguration();
  }, []);

  const getStudentRouteData = async () => {
    let data = {
      school_id: school_id,
      route_id: selectBus || "",
      academic_id: session_id,
      bus_no: regNo || "",
    };

    const response = await BusReportAPI.studentbusroutereport(
      data,
      CurrentPage
    );
    const responseData = response.data;
    setStudentData(responseData.stu_bus_report);
  };

  let handleChangeClass = (select) => {
    setSelectBus(select.value);
    setRegNo(select.BusName);
  };
  const handelClear = () => {
    setSelectBus(null);
    setRegNo("");
    setStudentData([]);
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center mx-1">
          <div className="header-action m-3">
            <h1 className="page-title">Student Bus Route Report</h1>
          </div>
          <div className="d-flex">
            <button
              className="btn btn-outline-secondary m-1 "
              onClick={getStudentRouteData}
            >
              Generate
            </button>
            <button
              className="btn btn-outline-danger m-1"
              onClick={handelClear}
            >
              {" "}
              <i class="fa fa-times px-1"></i>Clear
            </button>
          </div>
        </div>
        <div className="section-body mt-4">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-5 ">
                    <div className="input-group">
                      <label className="col-form-label col-lg-3  ">Route Name</label>
                      <Select
                        options={RouteOptions}
                        placeholder="Route name"
                        value={
                          selectBus
                            ? RouteOptions.find((c) => c.value === selectBus)
                            : null
                        }
                        isSearchable={true}
                        onChange={handleChangeClass}
                        className="col-lg-8"
                        // className="form-control m-0 p-0"
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 ">
                    <div className={`input-group`}>
                      <label className="col-form-label col-lg-3 ">Bus No</label>
                      <input
                        type="text"
                        className="form-control col-lg-8"
                        value={regNo}
                        placeholder="Bus no"
                        
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-form-label text-right">
                    {studentData?.length > 0 && (
                      <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button btn-sm btn btn-success"
                        color="success"
                        table="table-to-xls1"
                        filename="Student_Bus_Details"
                        sheet="tablexls"
                        buttonText="EXPORT"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {studentData && studentData.length > 0 ? (
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table
                    className="table table-striped table-sm text-nowrap text-center"
                    id="table-to-xls1"
                  >
                    <thead>
                      <tr>
                        <th>Sr No</th>
                        <th>Student Name</th>
                        {/* <th>Student Code</th> */}
                        <th>Class</th>
                        <th>Contact no</th>
                        <th>Bus No</th>
                        <th>Pick up Point</th>
                        <th>Bus Start Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {studentData?.map((list, index) => {
                        return (
                          <tr
                            key={index}
                            className={`${
                              list.payment_status === "CANCELLED"
                                ? "table-danger"
                                : ""
                            } `}
                          >
                            <td>{index + 1}</td>

                            <td className="text-left">
                              {list.fk_student__fk_student__std_name}
                            </td>
                            {/* <td>{String(list.fk_student__fk_student__student_code)}</td> */}
                            <td className="">
                              {list.fk_student__fk_section__fk_class__class_name +
                                "-" +
                                list.fk_student__fk_section__section}
                            </td>
                            <td className="">
                              {list.fk_student__fk_student__f_contact_no}
                            </td>
                            <td className="">
                              {
                                list.fk_pickup_point__fk_route__fk_busMaster__registration_no
                              }
                            </td>
                            <td className="">
                              {list.fk_pickup_point__pickup_point}
                            </td>
                            <td>{list.bus_start_date}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <ul className="pagination justify-content-center">
                    {studentData?.length > 10 && (
                      <Pagination
                        activePage={CurrentPage}
                        itemsCountPerPage={resultPerPage}
                        totalItemsCount={Page}
                        hideFirstLastPages={false}
                        onChange={(e) => setCurrentPage(e)}
                        nextPageText="Next"
                        prevPageText="Previous"
                        lastPageText="Last"
                        firstPageText="1st"
                        itemClass="page-item"
                        linkClass="page-link"
                        activeClass="active"
                        activeLinkClass="active"
                        hideDisabled={false}
                      />
                    )}
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <p className="no-list-message" style={{ fontSize: "2.5rem" }}>
              No Data Found !{" "}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentBusRouteReport;
