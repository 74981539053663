// import React, { useState, useEffect } from 'react';
// import { Document, Page, Text, Image, StyleSheet } from '@react-pdf/renderer';
// import { PDFDownloadLink } from '@react-pdf/renderer';

// const styles = StyleSheet.create({
//   page: {
//     padding: 20,
//   },
//   image: {
//     width: 100,
//     height: 100,
//   },
// });

// const MyPDF = () => {
//   const [imageUrl, setImageUrl] = useState(null); // Preset URL for demonstration
//   const [isLoading, setIsLoading] = useState(false);

//   useEffect(() => {
//     setIsLoading(true);

//     // Handle potential errors during image fetching
//     
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }
//         return response.blob();
//       })
//       .then((imageBlob) => {
//         const imageSrc = URL.createObjectURL(imageBlob);
//         setImageUrl(imageSrc); // Update with the fetched image data
//       })
//       .catch((error) => {
//         console.error('Error loading image:', error);
//         setIsLoading(false); // Set loading state to false even on error
//       })
//       .finally(() => {
//         setIsLoading(false);
//       });
//   }, [imageUrl]); // Re-run useEffect if imageUrl changes

//   const generatePDF = async () => {
//     if (!imageUrl) {
//       console.error('Image URL not found');
//       return;
//     }

//     const pdfDoc = (
//       <Document>
//         <Page size="A4" style={styles.page}>
//           <Text>hello11</Text>
//           <Image src={imageUrl} style={styles.image} alt="Logo" />
//         </Page>
//       </Document>
//     );

//     return <PDFDownloadLink document={pdfDoc} fileName="my-pdf.pdf">
//       {({ loading }) => (loading ? 'Loading PDF...' : 'Download PDF')}
//     </PDFDownloadLink>;
//   };

//   return (
//     <div>
//       {isLoading ? (
//         <p>Loading image...</p>
//       ) : (
//         <PDFDownloadLink document={generatePDF()} fileName="my-pdf.pdf">
//           {({ loading }) => (loading ? 'Loading PDF...' : 'Download PDF')}
//         </PDFDownloadLink>
//       )}
//     </div>
//   );
// };

// export default MyPDF;



import React from 'react'
import Loader from '../../../Loader/Loader'

const Dummy = () => {
  return (
    <div>
      <Loader/>
    </div>
  )
}

export default Dummy

