import React, { useEffect, useState } from "react";
import { Heading } from "../../../CommonComponent/heading";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { CiLight, CiSearch } from "react-icons/ci";
import Select from "react-select";
import {
  ClearButton,
  SaveButton,
  ViewButton,
} from "../../../CommonComponent/ReusableButton";
import { BusMasterApi } from "../../../../api/Transport/BusMasterAPI";
import useLocalStorageData from "../../Comman/LocalStorageData";
import { DriverMasterAPI } from "../../../../api/Transport/DriverMasterAPI";
import {RouteconfigurationAPI} from '../../../../api/Transport/RouteConfiguration'
import { NavLink, useParams, useNavigate, useLocation, Link } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormGroup,
  Form,
} from "reactstrap";
const AddRoute = (props) => {
  // school user can add route from this file
  const navigate = useNavigate();
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const [open, setOpen] = useState(false);
  const [focusAfterClose, setFocusAfterClose] = useState(true);
  const [DataFor1DropdownData, setDataFor1DropdownData] = useState("");
  const [BusOptions, setBusOptions] = useState([]);
  const [selectBus, setselectBus] = useState(null);
  const [driverData,setDriverData] = useState([])
  const [driverInfo,setDriverInfo] = useState([])
  const [isChecked, setIsChecked] = useState(false);
  const [attendantInfo ,setAttendantInfo] = useState([])
  const [busRoute, setBusRoute] = useState([]);


  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  // const toggle = () => {
  //   setOpen(!open);
  //   get_Busmaster_data();
  // };
  useEffect(() => {
    get_Busmaster_data();
  }, []);
 

  const getRouteData = async () => {
    try {
      let data = {
        school_id: school_id,
      };
      let response = await RouteconfigurationAPI.getRouteconfigurations(data);
      let responseData = response.data;
      setBusRoute(responseData.route_configuration);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getRouteData();
  }, []);
  const get_Busmaster_data = async () => {
    try {
      let data = {
        school_id: school_id,
      };
      const response = await BusMasterApi.BusMasterListData(data);
      const responseData = response.data;
      const busResponse = responseData.bus_data;
      const filteredBusOptions = busResponse.map((bus) => ({
        value: bus.id,
        label: bus.registration_no,
      }));
      setBusOptions(filteredBusOptions);
    } catch (err) {
      console.error(err);
    }
  };

 
  const getDriverMasterData = async (selectBus) => {
    try {
      let data = {
        school_id: school_id,
      };
      const response = await DriverMasterAPI.getDrivermasterdata(data);
      const responseData = response.data;
      const busResponse = responseData.driver_master_data;
  
      // Filter driver data corresponding to the selected bus ID
      const selectedDriverData = busResponse.filter(driver => driver.fk_busMaster_id === selectBus);
     
  
      // Separate drivers and attendants based on emp_type
      const drivers = selectedDriverData.filter(driver => driver.emp_type === 'Driver');
      const attendants = selectedDriverData.filter(driver => driver.emp_type === "Attendant");
 
      // Extract names and IDs of drivers and attendants
      const driverInfo = drivers.map(driver => ({ id: driver.id, name: driver.driver_attendant_name }));
      const attendantInfo = attendants.map(attendant => ({ id: attendant.id, name: attendant.attendant_name })); 

  
      // Save names and IDs in different states
      setDriverInfo(driverInfo);
      setAttendantInfo(attendantInfo);
    } catch (err) {
      console.error(err);
      // Handle errors as needed
    }
  };

  




  const handleChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value, // Access the property dynamically
    }));
  };


  const handleClear = () => {
    formik.resetForm();
    setDataFor1DropdownData("");
    setselectBus(null); // Set selectBus to null instead of an empty array
    setDriverInfo([]);
    setAttendantInfo([]);
    setIsChecked(false);
    setIsChecked(false);
};

  const handleClose = () => {
    onClose();
  };
  let handleChangeClass = (select) => {
    const selectedOption = select.value;
    setselectBus(selectedOption); 
    formik.setFieldValue('bus_id', selectedOption);
    getDriverMasterData(selectedOption)
      .then(empType => {
        if (empType) {
          setDriverData(empType);
        }
      })
      .catch(error => {
        console.error("Error retrieving emp_type:", error);
      });
  };

  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      school_id: school_id, 
      bus_id: "",
      route_id: "",
      route_name: "",
      start_time: "",
      end_time: "",
      driver_id:  "",
      attendant_id: "",
      is_active: "",
      insert_by: school_emp_id,
    },
    validationSchema: Yup.object().shape({
      route_name: Yup.string().required("Route name is required"),

      bus_id: Yup.string().required(" Bus no is required"),
    }),
    onSubmit:  async (values) => {
    try{
      let data = {
        ...values,
        is_active: isChecked ? "True" : "False",
      }

      const response = await RouteconfigurationAPI.addRouteconfigurations(data);
      const responseData = response.data;
      if (responseData.status === 200) {
        getRouteData();
        Swal.fire({
          text: responseData.msg,
          icon: "success",
        }).then(() => {
          Swal.fire({
            icon: 'question',
            text: "Want to add Pickup Point?",
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
          }).then((result) => {
            if(result.isConfirmed){
              navigate('/school/Pickup-Point', { state: { id: responseData.route_id } }); 
            }
          })
        })
      } else {
        Swal.fire({
          text: responseData.msg,
          icon: "error",
        });
      }
    }
    catch (error) {
      console.error(error);
    }
    },
  });
  useEffect(() => {
    formik.setValues(values => ({
      ...values,
      driver_id: driverInfo.length > 0 ? driverInfo[0].id : '',
      attendant_id: attendantInfo.length > 0 ? attendantInfo[0].id : '',
    }));
  }, [driverInfo, attendantInfo]);

  const handelBack=()=>{
    navigate('/school/route-configuration')
  }
  const deleteRoute = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let data = {
            route_id: id,
          };
          let response = await RouteconfigurationAPI.deleteRouteconfigurations(
            data
          );
          let responseData = response.data;
          if (responseData.status === 200) {
            // Show success message if deletion is successful
            Swal.fire({
              title: "Success",
              text: "Route deleted successfully",
              icon: "success",
            }).then(() => {
              // After user clicks OK, refresh the route data
              getRouteData();
            });
          }
        } catch (err) {
          console.error(err);
          // Show error message if deletion fails
          Swal.fire({
            title: "Error",
            text: "Failed to delete route",
            icon: "error",
          });
        }
      }
    });
  };

  const [text ,setText] = useState("Add Pick Up Point")


  useEffect(() => {
    if(window.innerWidth < 500){
      setText("") 
  }
  }, [])


  return (
    <>
      <div>    
          <div className="section-body">
        <div className="container-fluid">
    <div className="m-3"> 
        <Heading pageTitle={"Route-Configuration"} >
            </Heading>
            </div>
            <div className="card ">
              <div className="card-body">
                <form
                  className="card-body form-horizontal"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="form-group row mt-4 ">
                    <label className="col-form-label col-3 text-left">
                      Route Name <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        type="Text"
                        placeholder="Enter route name"
                        className="form-control"
                        name="route_name"
                        value={formik.values.route_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.route_name &&
                        formik.errors.route_name && (
                          <div className="text-danger">
                            {formik.errors.route_name}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-3 col-form-label text-left">
                      Bus Number <span className="text-danger">*</span>
                    </label>

                    <div className="col-md-8">
                      <Select
                        options={BusOptions}
                        name="bus_id"
                        placeholder="Select an option"
                        value={
                          selectBus
                            ? BusOptions.find((c) => c.value === selectBus)
                            : null
                        }
                        onChange={handleChangeClass}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.bus_id && formik.errors.bus_id && (
                        <div className="text-danger">
                          {formik.errors.bus_id}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-3 col-form-label text-left">
                      Start Time
                    </label>
                    <div className="col-md-3">
                      <input
                        type="text"
                        className="form-control custom-time-input"
                        placeholder="HH:MM:SS"
                        name="start_time"
                        value={formik.values.start_time}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <label className="col-md-2 col-form-label text-left">
                      End Time
                    </label>
                    <div className="col-md-3">
                      <input
                        type="text"
                        placeholder="HH:MM:SS"
                        className="form-control custom-time-input"
                        name="end_time"
                        value={formik.values.end_time}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-3 col-form-label text-left">
                      Driver
                    </label>
                    <div className="col-md-8">
                      <input
                        type="text"
                        // placeholder="Enter Driver Name"
                        className="form-control"
                        name=" driver_id"
                        value={driverInfo[0]?.name ||""}
             
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-3 col-form-label text-left">
                      Attendant
                    </label>
                    <div className="col-md-8">
                      <input
                        type="text"
                        className="form-control"
                        name="attendant_id"
                        value={attendantInfo[0]?.name || ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group d-flex">
                    <label className="col-md-3 col-form-label text-left">
                      Active
                    </label>
                    <div className="ml-4 ">
                    <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                    </div>
                  </div>
                  <button
                    className="btn btn-primary btn-sm mx-1 m-1"
                    type="submit"
                  >
                    <i className="fa fa-plus px-1"></i>
                    Save
                  </button>

                  <ClearButton className={"mx-1 m-1"} onClick={handleClear} />
                </form>
              </div>
            </div>
      </div>
      </div>
      </div> 
          <div className="section-body">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table
                  className="table table-striped table-bordered table-hover table-sm text-nowrap"
                  id="table-to-xls"
                >
                  <thead>
                    <tr className="text-center text-nowrap">
                      <th>Sr No</th>
                      <th>Route Name</th>
                      <th>Bus Number</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Driver</th>
                      <th>Attendant</th>
                      <th>Active</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {busRoute.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.route_name}</td>
                        <td>{item.bus_no}</td>
                        <td>{item.start_time}</td>
                        <td>{item.end_time}</td>
                        <td>{item.driver_attendant_name}</td>
                        <td>{item.attendant_name}</td>
                        <td>{item.is_active ? "Yes" : "No"}</td>
                        <td className="text-center d-flex">
                          <button
                            className="btn btn-outline-danger btn-sm m-1"
                            onClick={() => deleteRoute(item.id)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                          <Link
                            className="btn btn-outline-secondary btn-sm m-1"
                            to="/school/Edit-RouteConfiguration"
                            state={{ id: item.id }}
                          >
                            <i className="fa fa-edit"></i> 
                          </Link>
                          <Link
                            className="btn btn-primary btn-sm m-1"
                            to="/school/Pickup-Point"
                            state={{ id: item.id }}
                          >
                            <i className="fa fa-plus-square pr-1"></i>
                            {text} 
                          </Link>

                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          </div>
          </div>
    </>
  );
};

export default AddRoute;
