const pay_mode = [
  {
    label: "Cash",
    value: "Cash",
  },
  {
    label: "Bank",
    value: "Bank",
  },
  {
    label: "UPI",
    value: "UPI",
  },
  {
    label: "DD",
    value: "DD",
  },
  {
    label: "Cheque",
    value: "Cheque",
  },
];
const holidayType = [
  { value: "Vacation", label: "Vacation" },
  { value: "Event", label: "Event" },
  { value: "School Exam", label: "School Exam" },
  { value: "Government Holiday", label: "Government Holiday" },
  { value: "School Holiday", label: "School Holiday" },
];
holidayType.sort((a, b) => a.label.localeCompare(b.label));

const bloodGroupList = [
  { value: "A+", label: "A+" },
  { value: "A-", label: "A-" },
  { value: "B+", label: "B+" },
  { value: "B-", label: "B-" },
  { value: "O+", label: "O+" },
  { value: "O-", label: "O-" },
  { value: "AB+", label: "AB+" },
  { value: "AB-", label: "AB-" },
];
bloodGroupList.sort((a, b) => a.label.localeCompare(b.label));
const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
];
const countaryOptions = [{ value: "India", label: "India" }];
const DayList = [
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
];

const TypeList = [
  { label: "Period", value: "Period" },
  { label: "Non Period", value: "Non Period" },
];
let subjectTypeList = [
  {
    label: "1st Language",
    value: "1st Language",
  },
  {
    label: "2nd Language",
    value: "2nd Language",
  },
  {
    label: "Other Language",
    value: "Other Language",
  },
];
export {TypeList,DayList, pay_mode, holidayType, bloodGroupList,countaryOptions, genderOptions,subjectTypeList };
