import React, { useState, useEffect } from "react";
import { Heading } from "../../CommonComponent/heading";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import Select from "react-select";
import useLocalStorageData from "../Comman/LocalStorageData";
import { DriverMasterAPI } from "../../../api/Transport/DriverMasterAPI";
import { BusMasterApi } from "../../../api/Transport/BusMasterAPI";
import DriverSearch from "./DriverSearch";
import { SelectField } from "../../CommonComponent/SelectField";

const DriverMaster = () => {
  // user can add driver or attendent from here
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const [isEdit, setIsEdit] = useState(false);
  const [driverId, setDriverId] = useState({});
  const [BusOptions, setBusOptions] = useState([]);
  const [driverApp, setDriverApp] = useState("");
  const [DataFor1DropdownData, setDataFor1DropdownData] = useState("");
  const [driverData, setDriverDate] = useState([]);
  const [isDriverAppLoginChecked, setIsDriverAppLoginChecked] = useState(false);
  const [selectBus, setselectBus] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [selectType, setSelectType] = useState(null);
  const [curent, setCurent] = useState(null);
  const currentDate = new Date().toISOString().split("T")[0];
  const [isDriver, setIsDriver] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const get_Busmaster_data = async () => {
    try {
      let data = {
        school_id: school_id,
      };

      const response = await BusMasterApi.BusMasterListData(data);
      const responseData = response.data;
      const busResponse = responseData.bus_data;

      // Extracting id and registration_no and updating state
      const filteredBusOptions = busResponse.map((bus) => ({
        value: bus.id,
        label: bus.registration_no,
      }));
      setBusOptions(filteredBusOptions);
    } catch (err) {
      console.error(err);
    }
  };
  const getDriverMasterData = async () => {
    try {
      let data = {
        school_id: school_id,
      };

      const response = await DriverMasterAPI.getDrivermasterdata(data);
      const responseData = response.data;

      if (responseData.status === 200) {
        const selectedBus = responseData.driver_master_data.find(
          (bus) => bus.id == driverId
        );
        if (selectedBus) {
          console.log(selectedBus, "cascasscassss");
          setDriverDate(selectedBus);
          setselectBus(selectedBus.fk_busMaster_id);
          setSelectType(selectedBus.emp_type);
          if(selectedBus.emp_type == "Driver"){
            setIsChecked(true);
          }
          setCurent(selectedBus.emp_type);
          if(selectedBus.emp_type === "Attendant"){
            setIsChecked(true)
          }
          setIsChecked(selectedBus.driver_app_login);
          setIsEdit(true);
        } else {
        }
      } else {
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    get_Busmaster_data();
  }, []);

  useEffect(() => {
    getDriverMasterData();
  }, [driverId]);

  const handleChange1 = (event) => {
    const { id, checked } = event.target;

    // Assuming you have state variables for SMS and Notification
    if (id === "driverAppLoginCheckbox") {
      setIsDriverAppLoginChecked(checked ? "True" : "False");
    }
  };
  const onDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          driver_master_id: driverId,
        };
        DriverMasterAPI.deleteDrivermaster(data)
          .then((response) => {
            const responseData = response.data;
            if (responseData.status === 200) {
              Swal.fire({
                text: responseData.msg,
                icon: "success",
              });
              handleClear();
            } else {
              Swal.fire({
                text: "Failed to delete item.",
                icon: "error",
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              text: "An error occurred while processing your request.",
              icon: "error",
            });
          });
      }
    });
  };
  const handleClear = () => {
    formik.resetForm();
    setDataFor1DropdownData("");
    setselectBus("");
    setSelectType("");
    setIsDriverAppLoginChecked(false);
    setIsEdit(false);
    setDriverId(null);
    setDriverDate([]);
    setIsChecked(false);
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      school_id: school_id,
      bus_id: isEdit ? driverData.fk_busMaster_id : "",
      driver_master_id: driverData.id || "",
      driver_attendant_name:
     driverData.driver_attendant_name || driverData.attendant_name || "",
      emp_type: isEdit ? driverData.emp_type : "",
      dob: isEdit ? driverData.dob : "",
      joining_date: isEdit ? driverData.joining_date : "",
      license_issue_date: isEdit ? driverData.license_issue_date : "",
      license_expiry_date: isEdit ? driverData.license_expiry_date : "",
      emergency_contact_no: isEdit ? driverData.emergency_contact_no : "",
      phone_no: isEdit ? driverData.phone_no : "",
      address: isEdit ? driverData.address : "",
      license_no: isEdit ? driverData.license_no : "",
      releaving_date: isEdit ? driverData.releaving_date : "",
      driver_app_login: driverData.driver_app_login ? "True" : "False",
      insert_by: school_emp_id,
    },

    validationSchema: Yup.object().shape({
      driver_attendant_name: Yup.string().required(
        "Driver/Attendant name is required"
      ),
      emergency_contact_no: Yup.string()
        .required("Emergency contact number is required")
        .matches(
          /^[0-9]{10}$/,
          "Emergency contact number must be exactly 10 digits"
        ),
      // type: Yup.string().required("Type is required"),
      phone_no: Yup.string().required(" Contact number is required"),
      emp_type: Yup.string().required("Type is required"),
      bus_id: Yup.string().required("Bus is required"),
    }),
    onSubmit: async (values) => {
      if (!values.driver_attendant_name) {
        Swal.fire({
          text: "Driver/Attendant name is required",
          icon: "error",
          allowOutsideClick: false,
        });
        return;
      }

      try {
        const data = {
          ...values,
          bus_id: selectBus,
          emp_type: selectType,
          driver_app_login: isChecked ? "True" : "False",
        };

        const response = await DriverMasterAPI.saveUpdateDrivermaster(data);
        let responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
          });
          handleClear();
        } else {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
  const DataFor1Dropdown = [
    { id: 1, label: "Driver", value: "Driver" },
    { id: 2, label: "Attendant", value: "Attendant" },
  ];
  let handleChangeClass = (select) => {
    setselectBus(select.value);
    formik.setFieldValue("bus_id", select.value);
  };

  let handletypeChange = (select) => {
    setSelectType(select.label);
    formik.setFieldValue("emp_type", select.label);
  if(select.label == "Attendant" ){
    setIsChecked(false);
  };
}
  // console.log(formik.values.emp_type)

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <form onSubmit={formik.handleSubmit}>
            <Heading pageTitle={"Driver Master"}>
              <div className="row">
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm mx-1"
                  onClick={handleClear}
                >
                  <i className="fa fa-pencil px-1"></i>
                  New
                </button>
                <button className="btn btn-primary btn-sm mx-1" type="submit">
                  <i className="fa fa-plus px-1"></i>
                  {isEdit ? "Update" : "Save"}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger btn-sm mx-1 "
                  onClick={onDelete}
                  disabled={isEdit === false}
                >
                  <i className="fa fa-trash px-1"></i>
                  Delete
                </button>

                <DriverSearch setDriverId={setDriverId} school_id={school_id} />
              </div>
            </Heading>

            {/* Body section start here  */}

            <div className="col-lg-12 col-lg-12 col-sm-12 mt-4">
              <div className="card">
                <div className="card-body row">
                  <div className="col-lg-12 col-lg-12">
                    <div className="form-group  row ">
                      <label className="col-lg-3 col-form-label">
                        Driver/Attendant Name{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-8 ">
                        <input
                          type="Text"
                          className="form-control"
                          placeholder="Driver/Attendant name"
                          name="driver_attendant_name"
                          value={formik.values.driver_attendant_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.driver_attendant_name &&
                          formik.errors.driver_attendant_name && (
                            <div className="text-danger">
                              {formik.errors.driver_attendant_name}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-3 col-form-label">
                        Bus <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-3">
                        <Select
                          options={BusOptions}
                          name="bus_id"
                          placeholder="Select an option"
                          value={
                            selectBus
                              ? BusOptions.find((c) => c.value === selectBus)
                              : null
                          }
                          onChange={handleChangeClass}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.bus_id && formik.errors.bus_id ? (
                          <div className="text-danger">
                            {formik.errors.bus_id}
                          </div>
                        ) : null}
                      </div>

                      <label className="col-lg-2 col-form-label">
                        Type <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-3 ml-2">
                        <Select
                          options={DataFor1Dropdown}
                          placeholder="Select an option"
                          value={
                            formik.values.emp_type
                              ? DataFor1Dropdown?.find(
                                  (option) =>
                                    option.value === formik.values.emp_type
                                )
                              : null
                          }
                          onChange={handletypeChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.emp_type && formik.errors.emp_type && (
                          <div className="text-danger">
                            {formik.errors.emp_type}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label">DOB</label>
                      <div className="col-lg-3">
                        <input
                          type="date"
                          className="form-control"
                          name="dob"
                          value={formik.values.dob}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          max={currentDate} // Set max attribute to current date
                        />
                      </div>
                      <label className="col-lg-2  col-form-label">
                        Joining Date
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="date"
                          className="form-control"
                          name="joining_date"
                          value={formik.values.joining_date}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label">
                        License Issue Date
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="date"
                          className="form-control"
                          name="license_issue_date"
                          value={formik.values.license_issue_date}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      <label className="col-lg-2  col-form-label">
                        License Expiry Date
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="date"
                          className="form-control"
                          name="license_expiry_date"
                          value={formik.values.license_expiry_date}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label">
                        Emergency Contact No{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Emergency contact no"
                          name="emergency_contact_no"
                          value={formik.values.emergency_contact_no}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (
                              /^\d*$/.test(inputValue) &&
                              inputValue.length <= 10
                            ) {
                              formik.handleChange(e);
                            }
                          }}
                          maxLength={10}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.emergency_contact_no &&
                          formik.errors.emergency_contact_no && (
                            <div
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              {formik.errors.emergency_contact_no}
                            </div>
                          )}
                      </div>

                      <label className="col-lg-2 col-form-label">
                        Contact No <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="text"
                          className="form-control"
                          name="phone_no"
                          placeholder="Contact no"
                          value={formik.values.phone_no}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (
                              /^\d*$/.test(inputValue) &&
                              inputValue.length <= 10
                            ) {
                              formik.handleChange(e);
                            }
                          }}
                          maxLength={10}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.phone_no && formik.errors.phone_no && (
                          <div className="text-danger">
                            {formik.errors.phone_no}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label">Address</label>
                      <div className="col-lg-8">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Address"
                          name="address"
                          value={formik.values.address}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                    <label className="col-lg-3 col-form-label mr-2">Driver App Login</label>
                   
                        {/* <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          disabled={true}
                        /> */}
                         <label className="custom-switch">
                      <input
                        type="checkbox"
                        className="custom-switch-input"
                        name="custom-switch-checkbox"
                         id="flexCheckDefault"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          disabled={formik.values.emp_type !== 'Driver'}
                      />
                      <span className="custom-switch-indicator mr-2"></span>  
                    </label>
                       
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label">
                        Driving License No
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Driving license no"
                          name="license_no"
                          value={formik.values.license_no}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>

                      <label className="col-lg-2 col-form-label">
                        Relieving Date
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="date"
                          className="form-control"
                          name="releaving_date"
                          value={formik.values.releaving_date}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>

                   
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DriverMaster;
