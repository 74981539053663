import {
  ADD_ADMISSION,
  ADMISSION_FAIELD,
  ADMISSION_REQUIST,
  GET_STUDENT_NAME_REQUEST,
  GET_STUDENT_NAME,
  GET_STUDENT_NAME_FAIELD,
  GET_STUDENT_COUNT_REQUEST,
  GET_STUDENT_COUNT,
  GET_STUDENT_COUNT_FAIELD,
  GET_REGULAR_FEES_REQUEST,
  GET_REGULAR_FEES,
  GET_REGULAR_FEES_FAIELD,
  GET_REGULAR_FEES_RECEIPT_REQUEST,
  GET_REGULAR_FEES_RECEIPT,
  GET_REGULAR_FEES_RECEIPT_FAIELD,
  GET_SPECIAL_FEES_RECEIPT_REQUEST,
  GET_SPECIAL_FEES_RECEIPT,
  GET_SPECIAL_FEES_RECEIPT_FAIELD,
  GET_SPECIAL_FEES_TYPE_REQUEST,
  GET_SPECIAL_FEES_TYPE,
  GET_SPECIAL_FEES_TYPE_FAIELD,
  ADMISSION_CANCEL_REQUIST,
  ADMISSION_CANCEL_SUCCESS,
  ADMISSION_CANCEL_FAIELD,
  ADMISSION_STUDENT_DATA_REQUIST,
  ADMISSION_STUDENT_DATA_SUCCESS,
  ADMISSION_STUDENT_DATA_FAIELD,
  ADMISSION_UPDATE_REQUIST,
  ADMISSION_UPDATE_SUCCESS,
  ADMISSION_UPDATE_FAIELD,
  Academic_Filter_Student_List_REQUIST,
  Academic_Filter_Student_List_SUCCESS,
  Academic_Filter_Student_List_FAIELD,
  CLEAR_ERROR,
} from "../../Constants/Admission/Admission";

const initialState = {
  payload: null,
  admission: null,
  studentName: null,
  admissionCount: null,
  admissionCancel: null,
  studentData: null,
  regularFees: null,
  feesReceipt: null,
  specialFeesTypes: null,
  academicStudentList: null,
  loading: false,
  error: null,
};

export const addAdmissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMISSION_REQUIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_ADMISSION:
      return {
        ...state,
        admission: action.payload,
        loading: false,
        error: null,
      };
    case ADMISSION_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const UpdateAdmission = (state = initialState, action) => {
  switch (action.type) {
    case ADMISSION_UPDATE_REQUIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADMISSION_UPDATE_SUCCESS:
      return {
        ...state,
        admission: action.payload,
        loading: false,
        error: null,
      };
    case ADMISSION_UPDATE_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getStudentName = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDENT_NAME_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        errorCode: action.errorCode,
        message: action.message,
      };

    case GET_STUDENT_NAME:
      return {
        ...state,
        studentName: action.payload,
        loading: false,
        error: null,
        errorCode: action.errorCode,
        message: action.message,
      };
    case GET_STUDENT_NAME_FAIELD:
      return {
        ...state,
        loading: false,
        error: action.payload,
        errorCode: action.errorCode,
        message: action.message,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        errorCode: action.errorCode,
        message: action.message,
        studentName: null,
      };

    default:
      return state;
  }
};

export const getStudentCount = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDENT_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_STUDENT_COUNT:
      return {
        ...state,
        admissionCount: action.payload,
        loading: false,
        error: null,
      };
    case GET_STUDENT_COUNT_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getRegularFees = (state = initialState, action) => {
  switch (action.type) {
    case GET_REGULAR_FEES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_REGULAR_FEES:
      return {
        ...state,
        regularFees: action.payload,
        loading: false,
        error: null,
      };
    case GET_REGULAR_FEES_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const regularfeesReceipt = (state = initialState, action) => {
  switch (action.type) {
    case GET_REGULAR_FEES_RECEIPT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_REGULAR_FEES_RECEIPT:
      return {
        ...state,
        feesReceipt: action.payload,
        loading: false,
        error: null,
      };
    case GET_REGULAR_FEES_RECEIPT_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getspecialFeesTypes = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPECIAL_FEES_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_SPECIAL_FEES_TYPE:
      return {
        ...state,
        specialFeesTypes: action.payload,
        loading: false,
        error: null,
      };
    case GET_SPECIAL_FEES_TYPE_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const specialfeesReceipt = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPECIAL_FEES_RECEIPT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_SPECIAL_FEES_RECEIPT:
      return {
        ...state,
        feesReceipt: action.payload,
        loading: false,
        error: null,
      };
    case GET_SPECIAL_FEES_RECEIPT_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const studentDataByCode = (state = initialState, action) => {
  switch (action.type) {
    case ADMISSION_STUDENT_DATA_REQUIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADMISSION_STUDENT_DATA_SUCCESS:
      return {
        ...state,
        studentData: action.payload,
        loading: false,
        error: null,
      };
    case ADMISSION_STUDENT_DATA_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
      case CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        errorCode: action.errorCode,
        message: action.message,
        studentData: null,
      };

    default:
      return state;
  }
};

export const cancelAdmission = (state = initialState, action) => {
  switch (action.type) {
    case ADMISSION_CANCEL_REQUIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADMISSION_CANCEL_SUCCESS:
      return {
        ...state,
        admissionCancel: action.payload,
        loading: false,
        error: null,
      };
    case ADMISSION_CANCEL_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getAcademicFilterStudentList = (state = initialState, action) => {
  switch (action.type) {
    case Academic_Filter_Student_List_REQUIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case Academic_Filter_Student_List_SUCCESS:
      return {
        ...state,
        academicStudentList: action.payload,
        loading: false,
        error: null,
      };
    case Academic_Filter_Student_List_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};
