import React from "react";
import { useState } from "react";
import {
  Breadcrumb,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap";

const TestResult = () => {
  const [quizinfo, setquizinfo] = useState({});
  // school user can see the test results

  return (
    <Container fluid={true}>
      <Card className="p-0 m-0">
        <CardBody className="border-bottom">
          <div className="m-3">
            <Row className="mb-3">
              <Col>
                <h6 color="dark" className="">
                  Test Name
                </h6>
                <Input
                  disabled
                  className="form-control-auto "
                  type="text"
                  value={quizinfo.test_name}
                />
              </Col>
              <Col>
                <h6 color="dark" className="">
                  Subject Name
                </h6>
                <Input
                  disabled
                  className="form-control-auto "
                  type="text"
                  value={quizinfo.subject}
                />
              </Col>
            </Row>
          </div>
          <div className="">
            <div className="">
              <Row className="">
                <Col lg={6}>
                  <tr className="ms-20">
                    <h6>
                      <p className="mb-0 mx-3">Exam Date : </p>
                    </h6>
                    <td>
                      <p className="mb-0 mx-3 ">{quizinfo.test_date}</p>
                    </td>
                  </tr>
                  <tr>
                    <h6>
                      <p className="mb-0 mx-3">Number of Ques. : </p>
                    </h6>
                    <td>
                      <p className="mb-0  mx-3">
                        {quizinfo.noofquestion} Questions
                      </p>
                    </td>
                  </tr>
                </Col>
                <Col lg={6}>
                  <tr>
                    <h6>
                      <p className="mb-0 mx-3">Test Duration : </p>
                    </h6>
                    <td>
                      <p className="mb-0 mx-3 ">{quizinfo.duration} Minutes</p>
                    </td>
                  </tr>
                  <tr>
                    <h6>
                      <p className="mb-0 mx-3">Student Appeared : </p>
                    </h6>
                    <td>
                      <p className="mb-0 mx-3 ">
                        {quizinfo.present_count} Student
                      </p>
                    </td>
                  </tr>
                </Col>
              </Row>
            </div>
          </div>
        </CardBody>
      </Card>

      <Row>
        <div className="mt-3">
          <Col lg={12}>
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <thead>
                      <tr className="fs-6 text-center">
                        <th>Sr. No</th>
                        <th>Roll No</th>
                        <th>Name</th>
                        <th>Attendance</th>
                        <th>Marks Obtained</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {Student &&
                            Student.map(
                              (
                                {
                                  st_code,
                                  st_name,
                                  mark_obtain,
                                  attendance,
                                  stuquizid,
                                },
                                i
                              ) => {
                                return (
                                  <tr
                                    key={st_code}
                                    className="fs-6 text-center"
                                  >
                                    <td className="fs-6 center">{i + 1}</td>
                                    <td className="fs-6 center">{st_code}</td>
                                    <td className="fs-6">{st_name}</td>
                                    <td className="fs-6">{attendance}</td>
                                    <td>
                                      <h6 className="fs-5 text-primary">
                                        {mark_obtain}
                                        {stuquizid && (
                                          <a href={`/test-answer/${stuquizid}`}>
                                            <i
                                              className={`far fa-eye text-success fs-6 mx-3`}
                                            ></i>
                                          </a>
                                        )}
                                      </h6>
                                    </td>
                                  </tr>
                                );
                              }
                            )} */}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </div>
      </Row>
    </Container>
  );
};

export default TestResult;
