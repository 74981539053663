import React, { useState, useEffect } from "react";
import Select from "react-select";
import useSessionRedirect from "../../Comman/SessionChange";
import useLocalStorageData from "../../Comman/LocalStorageData";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";
import useLoading from "../../../CommonComponent/useLoading";
import Loader from "../../../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  examDetailsAPI,
  markEntryAPI,
} from "../../../../api/Examination/MarkEntry";
import { Table } from "reactstrap";

const ExamDetails = () => {
  // this code if for the exam wize details 
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const session_id = useSessionRedirect();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const dispatch = useDispatch();
  const { classsectionList } = useSelector((state) => state.attendance);
  const [classWiseExamList, setClassWiseExamList] = useState([]);
  const [StudentList, setStudentList] = useState([]);
  let [validationError, setValidationError] = useState(false);

  let classListOption = classsectionList?.payload?.map((c) => ({
    label: `${c.class_name} ${c.section}`,
    value: c.id,
  }));
 

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      academic_id: session_id,
      school_id: school_id,
      insert_by: school_emp_id,
      exam_id: null,
      section_id: null,
      student_data: [],
    },
    validationSchema: Yup.object().shape({
      section_id: Yup.string().required("This field is required."),
      exam_id: Yup.string().required("This field is required."),
    }),
    onSubmit: async (values) => {
      let studentData = StudentList?.map((stu) => ({
        student_id: stu.student_id,
        remark: stu.remark,
      }));
      startLoading();
      try {
        const data = new FormData();
        data.append("academic_id", values.academic_id);
        data.append("school_id", values.school_id);
        data.append("insert_by", values.insert_by);
        data.append("exam_id", values.exam_id);
        data.append("section_id", values.section_id);
        data.append("student_data", JSON.stringify(studentData));
        const response = await examDetailsAPI.createExamDetail(data);
        let responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            allowOutsideClick: false,
          });

          handleClear();
        } else if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        stopLoading();
      }
    },
  });

  const handleClear = () => {
    formik.resetForm();
    setStudentList([]);
    setClassWiseExamList([]);
  };

  const fetchExamClassWise = async () => {
    try {
      let body = {
        school_id: school_id,
        academic_id: session_id,
        section_id: formik.values.section_id,
      };
      let response = await markEntryAPI.getClassWiseExamsList(body);
      let responseData = response.data;
      if (responseData.status === 200) {
        let list = responseData?.exam_list2?.map((c) => ({
          label: `${c.exam_name1} `,
          value: c.exam_id1,
        }));
        setClassWiseExamList(list);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (formik.values.section_id) {
      fetchExamClassWise();
    }
  }, [formik.values.section_id]);

  let validationControl = async () => {
    let Validationstatus = true;
    if (!formik.values.exam_id) {
      Validationstatus = false;
    } else if (!formik.values.section_id) {
      Validationstatus = false;
    }
    if (!Validationstatus) {
      setValidationError(true);
      setTimeout(() => {
        setValidationError(false);
      }, 5000);
    }
    return Validationstatus;
  };

  const fetchStudents = async () => {
    let isValid = await validationControl();
    if (!isValid) return;
    startLoading();
    try {
      let body = {
        school_id: school_id,
        academic_id: session_id,
        section_id: formik.values.section_id,
        exam_id: formik.values.exam_id,
      };
      let response = await examDetailsAPI.getExamDetails(body);
      let responseData = response.data;
      if (responseData.status === 200) {
        setStudentList(responseData.exam_student_list);
      }
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };

  const handleInputChange = (stuIndex, value) => {
    const updateSubject = [...StudentList];
    updateSubject[stuIndex].remark = value;
    setStudentList(updateSubject);
  };
  const handleClearMark = () => {
    setStudentList([]);
  };
  const handleSelectSection = (selectedOptions) => {
    formik.setFieldValue("section_id", selectedOptions?.value || "");
    setStudentList([]);
    setClassWiseExamList([]);
    formik.setFieldValue("exam_id", null);
  };
  const onDelete = () => {
    let delete_id = {
      academic_id: session_id,
      school_id: school_id,
      section_id: formik.values.section_id,
      exam_id: formik.values.exam_id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await examDetailsAPI.deleteExamDetail(delete_id);
        let responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            imageAlt: "success image",
          });

          handleClear();
        }
        if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            imageAlt: "error image",
          });
        }
      }
    });
  };

  if (isLoading) return <Loader />;
  return (
    <div className="section-body">
      <div className="container-fluid">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Exam Details</h1>
            </div>
            <div className=" mt-2">
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm mx-1"
                onClick={() => fetchStudents()}
              >
                <i className="fa fa-eye  px-1"></i>
                View
              </button>
              <button
                className="btn btn-primary btn-sm mx-1"
                type="submit"
                disabled={StudentList?.length > 0 ? false : true}
              >
                <i className="fa fa-plus px-1"></i>
                Save
              </button>
              <button
                type="button"
                className="btn btn-outline-danger btn-sm mx-1"
                onClick={() => onDelete()}
                disabled={StudentList?.length > 0 ? false : true}
              >
                <i className="fa fa-trash px-1"></i>
                Delete
              </button>
              <button
                type="button"
                className="btn btn-outline-danger btn-sm mx-1"
                onClick={() => handleClear()}
              >
                <i className="fa fa-times px-1"></i>
                Clear
              </button>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="card">
              <div className="card-body row">
                <div className="col-lg-6 ">
                  <div className="form-group col-md-12 row">
                    <label className="col-md-4 col-form-label">
                      Class <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-8">
                      <Select
                        name="section_id"
                        placeholder="Select  Class"
                        options={classListOption}
                        value={
                          formik.values.section_id
                            ? classListOption?.find(
                                (g) => g.value === formik.values.section_id
                              )
                            : null
                        }
                        onChange={handleSelectSection}
                        onBlur={formik.handleBlur}
                        isSearchable={true}
                      />
                      {formik.touched.section_id && formik.errors.section_id ? (
                        <ErrorDisplay errorMsg={formik.errors.section_id} />
                      ) : null}
                      {validationError && !formik.values.section_id ? (
                        <ErrorDisplay errorMsg={"Class is required"} />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="form-group col-md-12 row">
                    <label className="col-md-4 col-form-label">
                      Examination <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-8">
                      <Select
                        name="exam_id"
                        placeholder="Select Exam "
                        options={classWiseExamList}
                        value={
                          formik.values.exam_id
                            ? classWiseExamList?.find(
                                (g) => g.value === formik.values.exam_id
                              )
                            : null
                        }
                        onChange={(selectedOptions) => {
                          formik.setFieldValue(
                            "exam_id",
                            selectedOptions?.value || ""
                          );
                          handleClearMark();
                        }}
                        onBlur={formik.handleBlur}
                        isSearchable={true}
                      />
                      {formik.touched.exam_id && formik.errors.exam_id ? (
                        <ErrorDisplay errorMsg={formik.errors.exam_id} />
                      ) : null}
                      {validationError && !formik.values.exam_id ? (
                        <ErrorDisplay errorMsg={"Exam is required"} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="mt-4 table-responsive">
                <Table
                  size="sm"
                  className="table-bordered table-vcenter table-striped text-nowrap"
                  striped
                >
                  <thead className="">
                    <tr className="text-center text-nowrap ">
                      <th>Sr No.</th>
                      <th>Roll No</th>
                      <th>Student Name</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {StudentList?.map((stu, stuIndex) => (
                      <tr
                        key={stuIndex}
                        className="text-center clickable-row-color"
                      >
                        <td>{stuIndex + 1}</td>
                        <td>{stu.roll_no}</td>
                        <td className="text-left">{stu.student_name}</td>
                        <td>
                          <input
                            className="form-control"
                            type="text"
                            value={stu.remark}
                            onChange={(e) => {
                              handleInputChange(stuIndex, e.target.value);
                            }}
                            placeholder={`Remark`}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExamDetails;
