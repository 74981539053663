import React from "react";
import { Heading } from "../CommonComponent/heading";

const Help = () => {
  // this is the help page
  return (
    <div className="section-body">
      <div className="container-fluid">
        <Heading pageTitle={"Help"}></Heading>
        <div className="col-lg-12 col-md-8 col-sm-12 mt-4">
          <div className="card">
            <div className="card-body">Help</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
