import { useContext, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SessionContext } from "../../../context/sessionContext";

const useSessionRedirect = () => {
  // this is use for redirect to login page if session is expired
  const { Session } = useContext(SessionContext);
  const session_id = Session || sessionStorage.getItem("SessionId");
  const navigate = useNavigate();
  const sessionRef = useRef(session_id);
  

  useEffect(() => {
    if (sessionRef.current !== session_id) {
      // window.location.href = "/School/dashboard";
    }
  }, [session_id]);

  return session_id;
};

export default useSessionRedirect;
