import React, { useEffect, useState } from "react";
import { Heading } from "../../CommonComponent/heading";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { CiSearch } from "react-icons/ci";
import useLocalStorageData from "../Comman/LocalStorageData";
import Select from "react-select";
import { BusMasterApi } from "../../../api/Transport/BusMasterAPI";
import SearchBus from "./SearchBus";

const BusMaster = () => {
  // user can add buses from here 
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const [busDetails, setBusDetails] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [busDetailsId, setBusDetailsId] = useState("");
  const [isSelected, setIsSelected] = useState(false);

  const get_Busmaster_data = async () => {
    try {
      let data = {
        school_id: school_id,
      };

      const response = await BusMasterApi.BusMasterListData(data);
      const responseData = response.data;
      const busResponse = responseData.bus_data;

      if (responseData.status === 200) {
        const selectedBus = busResponse.find((bus) => bus.id === busDetailsId);

        if (selectedBus) {
          setBusDetails(selectedBus);
          // setIsChecked(selectedBus.gps_device);
          let inUse = selectedBus.In_use === true ? "yes" : "no";
          // console.log(inUse, "Api value");
          setIsSelected(inUse);
          setIsEdit(true);
        } else {
        }
      } else {
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    get_Busmaster_data();
  }, [busDetailsId]);


  const [formData, setFormData] = useState({
    bus_registration_id: "",
    school_id: school_id,
    registration_no: "",
    year: "",
    engineNo: "",
    chesis_no: "",
    batteryNo: "",
    manufacturer: "",
    model: "",
    inUse: "",
    showDateTime: "",
    // gpsMapping: "False",
    pucNo: "",
  });

  const onDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          bus_registration_id: busDetailsId,
        };
        BusMasterApi.DeleteBusMasterdata(data)
          .then((response) => {
            const responseData = response.data;
            if (responseData.status === 200) {
              Swal.fire({
                text: responseData.msg,
                icon: "success",
              });
              handleClear();
            } else {
              Swal.fire({
                text: "Failed to delete item.",
                icon: "error",
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              text: "An error occurred while processing your request.",
              icon: "error",
            });
          });
      }
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleChange1 = (event) => {
    setIsSelected(event.target.value); // Update state based on value
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleClear = () => {
    formik.resetForm();
    setIsEdit(false);
    setBusDetailsId([]);
    setBusDetails([]);
    setIsChecked(false);
    setIsSelected(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      bus_registration_id: busDetailsId || "",
      school_id: school_id,
      registration_no: isEdit ? busDetails.registration_no : "",
      registration_year: isEdit ? busDetails.registration_year : "",
      engine_no: isEdit ? busDetails.engine_no : "",
      chesis_no: isEdit ? busDetails.chesis_no : "",
      battery_no: isEdit ? busDetails.battery_no : "",
      manufacturer: isEdit ? busDetails.manufacturer : "",
      model: isEdit ? busDetails.model : "",
      // In_use: isEdit ? busDetails.In_use : true,
      last_use_date: isEdit ? busDetails.last_use_date : "",
      // gps_device: isEdit ? busDetails.gps_device : "",
      puc_no: isEdit ? busDetails.puc_no : "",
      insert_by: school_emp_id,
    },
    validationSchema: Yup.object().shape({
      registration_no: Yup.string().required("Registration No is required"),
      registration_year: Yup.string().required("Registration Year is required"),
    }),
    onSubmit: async (values) => {
      console.log(values.In_use, "Formki value");
      if (!values.registration_no) {
        Swal.fire({
          text: "Registration No is required",
          icon: "error",
          allowOutsideClick: false,
        });
        return;
      }

      try {
        const data = {
          ...values,
          // gps_device: isChecked ? "True" : "False",
          In_use: isSelected === "yes" ? "True" : "False",
        };
        console.log(data.In_use);

        const response = await BusMasterApi.BusMasterSave(data);
        let responseData = response.data;
        if (responseData.status === 200) {
          handleClear();
          Swal.fire({
            text: responseData.msg,
            icon: "success",
          });
        } else {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <form onSubmit={formik.handleSubmit}>
            <Heading pageTitle={"Bus Master"}>
            {/* <i class="fa-solid fa-file"></i> */}
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm mx-1"
                  onClick={() => handleClear()}
                >
                  <i className="fa fa-pencil px-1"></i>
                  New
                </button>
                <button className="btn btn-primary btn-sm mx-1" type="submit">
                  <i className="fa fa-plus px-1"></i>
                  {isEdit ? "Update" : "Save"}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger btn-sm mx-1"
                  onClick={onDelete}
                  disabled={isEdit === false}
                >
                  <i className="fa fa-trash px-1"></i>
                  Delete
                </button>
                <SearchBus setBusDetailsId={setBusDetailsId} />
              </div>
            </Heading>

            <div className="col-lg-12 col-lg-12 col-sm-12 mt-4">
              <div className="card">
                <div className="card-body row">
                  <div className="col-lg-11 col-lg-12">
                    <div className="form-group col-lg-12 row">
                      <label className="col-lg-3 col-form-label">
                        Registration No <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="text"
                          placeholder="Registration no"
                          className={`form-control custom-input ${
                            formik.touched.registration_no &&
                            formik.errors.registration_no
                              ? "is-invalid"
                              : ""
                          }`}
                          name="registration_no"
                          value={formik.values.registration_no}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.registration_no &&
                          formik.errors.registration_no && (
                            <div className="text-danger">
                              {formik.errors.registration_no}
                            </div>
                          )}
                      </div>

                      <label className="col-lg-1 col-form-label">
                        Year <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="text" 
                          className="form-control custom-input"
                          placeholder="Enter year"
                          name="registration_year"
                          value={formik.values.registration_year}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (
                              /^\d*$/.test(inputValue) &&
                              inputValue.length <= 4
                            ) {
                              formik.handleChange(e);
                            }
                          }}
                          maxLength={4}
                        />
                        {formik.touched.registration_year &&
                          formik.errors.registration_year && (
                            <div className="text-danger">
                              {formik.errors.registration_year}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="form-group col-lg-12 row">
                      <label className="col-lg-3 col-form-label">
                        Engine No
                      </label>
                      <div className="col-lg-7">
                        <input
                          type="text"
                          className="form-control custom-input" // Add your own CSS class
                          placeholder="Engine no"
                          name="engine_no"
                          value={formik.values.engine_no}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>
                    <div className="form-group col-lg-12 row">
                      <label className="col-lg-3 col-form-label">
                        Chesis No
                      </label>
                      <div className="col-lg-7">
                        <input
                          type="text"
                          className="form-control custom-input" // Add your own CSS class
                          placeholder="Chesis no"
                          name="chesis_no"
                          value={formik.values.chesis_no}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>

                    <div className="form-group col-lg-12 row">
                      <label className="col-lg-3 col-form-label">
                        Battery No
                      </label>
                      <div className="col-lg-7">
                        <input
                          type="text"
                          className="form-control custom-input" // Add your own CSS class
                          placeholder="Battery no"
                          name="battery_no"
                          value={formik.values.battery_no}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>

                    <div className="form-group col-lg-12 row">
                      <label className="col-lg-3 col-form-label">
                        Manufacturer
                      </label>
                      <div className="col-lg-7">
                        <input
                          type="text"
                          className="form-control custom-input" // Add your own CSS class
                          placeholder="Manufacturer"
                          name="manufacturer"
                          value={formik.values.manufacturer}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>

                    <div className="form-group col-lg-12 row">
                      <label className="col-lg-3 col-form-label">Model</label>
                      <div className="col-lg-7">
                        <input
                          type="text"
                          className="form-control custom-input" // Add your own CSS class
                          placeholder="Model"
                          name="model"
                          value={formik.values.model}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>

                    {/* <div className="form-group col-lg-12 row">
                      <label className="col-lg-3 col-form-label">
                        Battery No <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-7">
                        <input
                          type="text"
                          className="form-control custom-input" // Add your own CSS class
                          placeholder="battery_No"
                          name="battery_no"
                          value={formik.values.battery_no}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        />
                      </div>
                    </div> */}

                    {/* <div className="form-group col-lg-12 row">
                      <label className="col-lg-3 col-form-label">
                        <b>In Use</b>
                      </label>
                      <div className="col-lg-1">
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="In_use"
                            checked={isSelected === "yes"} // Set checked based on state
                            onChange={handleChange1}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="radioOption1"
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-1">
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="In_use"
                            checked={isSelected === "no"} // Set checked based on state
                            onChange={handleChange1}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="radioOption2"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div> */}

                    <div className="form-group col-lg-12 d-flex">
                      <label className="col-lg-3 col-form-label">In Use</label>
                      <div className="form-group d-flex">
                        <label className="col-lg-12">
                          <input
                            type="radio"
                            name="answer" // Group name for radio buttons
                            value="yes"
                            className="m-1"
                            checked={isSelected === "yes"} // Set checked based on state
                            onChange={handleChange1}
                          />
                          Yes
                        </label>
                        <label className="col-lg-12">
                          <input
                            type="radio"
                            name="answer" // Group name for radio buttons
                            value="no"
                            className="m-1"
                            checked={isSelected === "no"} // Set checked based on state
                            onChange={handleChange1}
                          />
                          No
                        </label>
                      </div>
                    </div>

                    <div className="form-group col-lg-12 row">
                      <label className="col-lg-3 col-form-label">
                        Last Use Date
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="date"
                          className="form-control custom-input"
                          placeholder="Last use date"
                          name="last_use_date"
                          value={formik.values.last_use_date}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {/* <div className="col-lg-1"></div>
                      <div className="mr-3 m-1">
                        <input
                          type="checkbox"
                          name="gps_device"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      <label htmlFor="gps_device">
                        <b>GPS Device mapping</b>
                      </label> */}
                    </div>
                    <div className="form-group col-lg-12 row">
                      <label className="col-lg-3 col-form-label">PUC No</label>
                      <div className="col-lg-7">
                        <input
                          type="text"
                          className="form-control custom-input" // Add your own CSS class
                          placeholder="PUC no"
                          name="puc_no"
                          value={formik.values.puc_no}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BusMaster;
