import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
const token = localStorage.getItem("token");
export const TimeTableAPI = {
  addPeriodConfiguration: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}add_update_period_configuration/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },

  getPeriodiConfigurationList: async (data) => {
    let axiosC = {
      method: "POST",
      url: `${BASE_URL}show_period_configuration_details/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };

    return await axios(axiosC);
  },
  //

  getTimetable: async (data) => {
    let axiosConfig = {
      method: "post",
      url: `${BASE_URL}show_timetable_api/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig)
  },
  getSubjectTeacher: async (data) => {
    let axiosConfig = {
      method: "post",
      url: `${BASE_URL}get_subject_teacher/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig)
  },
  createTimetableTeacherwise: async (data) => {
    let axiosConfig = {
      method: "post",
      url: `${BASE_URL}generate_time_table_teacherwise/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig)
  },
  getTeacherList: async (data) => {
    let axiosConfig = {
      method: "post",
      url: `${BASE_URL}get_teacher_list_api/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig)
  },
  getTimetableTeacherWise: async (data) => {
    let axiosConfig = {
      method: "post",
      url: `${BASE_URL}get_timetable_teacher_wise/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig)
  },
};
