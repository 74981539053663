import React from "react";
import { NavLink } from "react-router-dom";
import { Table } from "reactstrap";
import EmptyListMessage from "../../../CommonComponent/EmptyListMessage";

const ExamTable = (props) => {
  // this code is for the exam table  where you and do edit and delete
  const { onEdit, onDelete, data, headers, tableName } = props;
  return (
    <>
      <Table
        size="sm"
        className="table table-hover table-vcenter table-striped mb-0 text-nowrap"
        bordered
      >
        <thead>
          <tr>
            <th>Sr No</th>
            {headers?.map((header, i) => (
              <th key={i}>{header}</th>
            ))}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data?.map((exam, index) => (
              <tr key={index} className="text-center">
                <td>{index + 1}</td>
                {tableName === "Exam" && (
                  <>
                    <td className="text-left">{exam.exam_name}</td>
                    <td>{exam.appear_for_grade ? "Yes" : ""}</td>
                  </>
                )}
                {tableName === "Assessment" && (
                  <>
                    <td className="text-left">{exam.assessment}</td>
                    <td className="text-left">{exam.fk_exam__exam_name}</td>
                  </>
                )}
                <td>
                  <NavLink
                    to="#"
                    className="card-options-remove mr-3"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Delete"
                    onClick={() => onDelete(exam.id)}
                  >
                    <i className="fa fa-trash-o text-danger"></i>
                  </NavLink>
                  <NavLink
                    className="text-muted"
                    to="#"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Edit"
                    onClick={() => onEdit(exam)}
                  >
                    <i className="fa fa-edit"></i>
                  </NavLink>
                </td>
              </tr>
            ))
          ) : (
            <EmptyListMessage
              Table="table"
              message={`${tableName} Not Available !` }
              Span={4}
            />
          )}
        </tbody>
      </Table>{" "}
    </>
  );
};

export default ExamTable;
