  import React, { useState, useEffect } from "react";
  import "./style.css";
  import { convertDateInMMDDYY } from "../../../CommonComponent/DateFormatUtil";
  import { SchoolImage } from "../../../../api/SchoolImage/SchoolLogoAPI";
  import useLocalStorageData from "../../Comman/LocalStorageData";
  import useLoading from "../../../CommonComponent/useLoading";
  import Loader from "../../../Loader/Loader";
  import GradeCardPrint from "./GradeCardPrint";
  import { useSelector } from "react-redux";
  import www from "./www.png"



  const Card = (props) => {
    // this is the html to print the card and make it pdf 
    const { g, id, cardInfo, grade, stu_Ranking, isLoading ,base64Image ,loading} = props; // Added setLoading as a prop
    const { user_detail } = useLocalStorageData();
    const schoolId = user_detail.fk_school_id;
    const [rank ,setRank] = useState(stu_Ranking)

  console.log(rank)


if(loading) return <Loader/>
  
    return (
      <div id={id} key={id}>
        {/* <GradeCardPrint isLoading={true} /> */}
        <div className="d-flex bottom-line">
          <div className="pb-1">
        
      
          {base64Image ? (
        <img
          src={`data:image/png;base64,${base64Image}`}
          alt="school_logo"
          width={100}
          height={100}
          style={{ borderRadius: '50%' }}
        />
      ) : (
      
        <img
          src={www}
          alt="fallback_image"
          width={100}
          height={100}
          style={{ borderRadius: '50%' }}
        />
      )}
          
          </div>
          <div className="flex-grow-1 pb-2">
            <div className="certificate-header">{cardInfo.school_name}</div>
            <div className="text-center">
              <h6>
                {cardInfo.school_address},Pincode- {cardInfo.pincode}, Ph:{" "}
              
              </h6>
              <div className="d-flex justify-content-around font-bold">
                {cardInfo.exam_name}
              </div>
            </div>
          </div>
          <div className="pb-1"></div>
        </div>

        <div className="content1">
          <table className="table table-bordered table-sm">
            <thead>
              <tr
                className="text-center student-table costom-header"
                style={{ fontWeight: "bold", color: "black" }}
              >
                <th>STD./DIV</th>
                <th>ROLL NO</th>
                <th>STUDENT'S NAME</th>
                <th> G.R.NO.</th>
                <th> DoB</th>
              </tr>
            </thead>
            <tbody>
              <tr className="costom-body">
                <td>{`${g.class} - ${g.section}`}</td>
                <td>{g.roll_no}</td>
                <td className="text-capitalize">{g.student_name}</td>
                <td></td>
                <td>{convertDateInMMDDYY(g.dob)}</td>
              </tr>
            </tbody>
          </table>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <table className="table table-bordered table-sm">
                <thead>
                  <tr className="text-center student-table">
                    <th rowSpan={2} className="text-center costom-header">
                      SUBJECT
                    </th>
                    <th
                      colSpan={g.headers?.length}
                      className="text-center costom-header"
                    >
                      Assessment{" "}
                    </th>
                    <th className="text-center costom-header">TOTAL </th>
                    <th rowSpan={2} className="costom-header">
                      GRADE
                    </th>
                  </tr>
                  <tr className="text-center student-table">
                    {g.headers?.map((a, headerIndex) => (
                      <th
                        key={headerIndex}
                        className="text-center costom-header2"
                      >
                        {a.header_name}
                        <br />
                        {/* MAX <br />
                                  {a.max_marks} */}
                      </th>
                    ))}
                    <th className="text-center costom-header2">
                      Marks <br />
                      {/* {g.total_maxMarks} */}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {g?.subject?.map((sub, subIndex) => (
                    <tr key={subIndex} className="text-center costom-body">
                      <td className="text-left">{sub.subject_name}</td>
                      {/* {console.log(g)} */}
                      {g.headers?.map((heade, index) => {
                        const obtainMark =
                          sub[heade.header_name] &&
                          sub[heade.header_name][0]?.obtain_MarkGrade;

                        const markFlag =
                          sub[heade.header_name] &&
                          sub[heade.header_name][0]?.min_marks_status;

                        return (
                          <td key={index}>
                            {markFlag == 1 && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                            {obtainMark || "-"}
                          </td>
                        );
                      })}

                      <td className="">
                        {sub.subject_totalMarks}/{sub.subjectMaxmarks}
                        {/* {console.log(sub)} */}
                      </td>
                      <td className="">{sub.grade}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="total-color">
                    <td rowSpan={2} className="t-bold">
                      TOTAL
                    </td>
                    {g.headers?.map((header, hIndex) => {
                      const totalMarks = g.subject.reduce(
                        (total, subject) =>
                          total +
                          (subject[header.header_name] &&
                          subject[header.header_name][0].obtain_MarkGrade
                            ? parseFloat(
                                subject[header.header_name][0].obtain_MarkGrade
                              )
                            : 0),
                        0
                      );
                      return (
                        <td className="t-bold" key={hIndex}>
                          {totalMarks}
                        </td>
                      );
                    })}

                    <td className="t-bold">{`${g.total_obtainMarks}/ ${g.total_maxMarks}`}</td>
                    <td className="t-bold">{g.obtain_grade}</td>
                  </tr>
                  <tr className="total-color">
                    <td colSpan={g.headers?.length} className="t-bold"></td>
                    <td className="t-bold ">
                      <span> Percentage :- {g.obtain_percentage}%</span>
                    </td>
                    {g.student_rank || rank? (
                      <td className="t-bold rank text-left">
                        <span className="ml-5">Rank :- {g.student_rank} {rank} {console.log(g.student_rank, 'g.student_rank')}  {console.log(rank, 'rank')}</span>
                      </td>
                    ) : (
                      <td className="t-bold total-color text-left">
                        <span className="ml-5"></span>
                      </td>
                    )}
                   
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div className="d-flex">
            <b className="font-weight-bold">Remark</b>{" "}
            <p className="pl-2 text-capitalize ">:- {g.remark} </p>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <table className="table table-bordered table-sm">
                <thead>
                  <tr className="text-center student-table costom-header">
                    <th colSpan={4}>GRADE CHART</th>
                  </tr>
                  <tr className="text-center student-table costom-header2">
                    <th>Class interval of Marks</th>
                    <th>Grade </th>
                  </tr>
                </thead>
                <tbody>
                  {grade?.map((grade, index) => (
                    <tr className="text-center costom-body" key={index}>
                      <td>{`${grade.min_percent} - ${grade.max_percent}`}</td>
                      <td>{grade.grade}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-lg-3 col-md-6"></div>
            <div className="col-lg-3 col-md-6"></div>
          </div>
        </div>
        <div className="footer"></div>
        {/* {studentImage ? (
          <img
            src={`${MEDIA_BASE_URL}/${studentImage}`}
            width={100}
            height={100}
            style={{ borderRadius: "50%" }}
            alt="Student Image"
          />
        ) : (
          <img
            src={pic1}
            width={100}
            height={100}
            style={{ borderRadius: "50%" }}
            alt="Dummy Logo"
          />
        )} */}
        <p className="pl-2 text-capitalize mb-2">
          <span className="font-weight-bold">Note</span>{" "}
          <span style={{ color: "red" }}>*</span> indicates Student has failed in
          the subject.
        </p>
        <div className="mb-5"></div>
      </div>
      
    
    );
  };

  export default Card;
