import React, { useEffect, useState } from "react";
import Select from "react-select";
import HostelAPI from "../../../../api/Hostel/HostelRegistration";
import useLocalStorageData from "../../Comman/LocalStorageData";
import useLoading from "../../../CommonComponent/useLoading";
import Loader from "../../../Loader/Loader";
import FeesReceiptList from "./FeesReceiptList";
import PrintHostelFeesReceipt from "./HostelFeesReceiptPrint";
import ValidationComponent from "../../Comman/Validation";
import ValidationMessage from "../../../CommonComponent/ValidationMessage";
import HostelReceiptList from "./HostelReceiptList";
import Swal from "sweetalert2";
import { useAlert } from "react-alert";
const HostelFeesReceipt = () => {
  const { school_id, token, school_emp_id, schoolUser } = useLocalStorageData();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [receiptDate, setreceiptDate] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const alert = useAlert();
  const [hostelId, setHostelId] = useState("");
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [hostelData, setHostelData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [selectStudent, setSelectStudent] = useState("");
  const [receiptType, setReceiptType] = useState("New");
  const [PaymentMode, setPaymentMode] = useState("Cash");


  let BalanceFee = selectStudent?.balance_fees
    ? selectStudent?.balance_fees
    : selectStudent?.balance_fee;

  const fetchData = async () => {
    startLoading();
    try {
      let data = {
        school_id: school_id,
      };
      const response = await HostelAPI.getHostelList(data);
      let responseData = response.data;
      let list = responseData.hostelList?.map((hostel) => ({
        value: hostel.id,
        label: hostel.hostelName,
      }));
      setHostelData(list);
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };
  useEffect(() => {
    fetchData();
  }, []);


  const fetchHostelWiseStudent = async () => {
    try {
      let data = {
        hostel_id: hostelId,
      };
      const response = await HostelAPI.getHostelWiseStudentList(data);
      let responseData = response.data;
      setStudentData(responseData.student_data);
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    if (hostelId) {
      fetchHostelWiseStudent();
    }
  }, [hostelId]);

  let initialValue = {
    payment_id: "",
    alloted_id: selectStudent?.alloted_id ?? "",
    student_id: selectStudent?.student_id ?? "",
    student_type: selectStudent?.student_type ?? "",
    school_id: school_id,
    receipt_date: receiptDate,
    payment_mode: "Cash",
    bank_name: "",
    pay_no: "",
    pay_date: "",
    paid_amount: "",
    remark: "",
    insert_by: school_emp_id,
  };
  const [paymentInfo, setPaymentInfo] = useState(initialValue);
  const pay_mode = [
    {
      label: "Cash",
      value: "Cash",
    },
    {
      label: "Bank",
      value: "Bank",
    },
    {
      label: "UPI",
      value: "UPI",
    },
    {
      label: "DD",
      value: "DD",
    },
    {
      label: "Cheque",
      value: "Cheque",
    },
  ];
  const PaymentList = pay_mode?.map((item) => {
    return {
      label: item.label,
      value: item.value,
    };
  });
  const handlePaymentMode = (select) => {
    setPaymentMode(select);
    setPaymentInfo((prevState) => ({
      ...prevState,
      payment_mode: select.value,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setPaymentInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    setPaymentInfo((prev) => ({
      ...prev,
      payment_id: selectStudent?.id ?? "",
      alloted_id: selectStudent?.alloted_id ?? selectStudent?.fk_allotedroom_id,
      student_id: selectStudent?.student_id ?? selectStudent?.fk_student_id,
      student_type: selectStudent?.student_type ?? "",
      bank_name: selectStudent?.bank_name ?? "",
      paid_amount: selectStudent?.paid_amount ?? "",
      pay_no: selectStudent?.pay_no ?? "",
      pay_date: selectStudent?.pay_date ?? "",
      remark: selectStudent?.remark ?? "",
      payment_mode: selectStudent?.payment_mode ?? "Cash",
    }));

    setPaymentMode(selectStudent?.payment_mode ?? "Cash");
    setHostelId(selectStudent?.hostel_id);
  }, [selectStudent]);
  const handleClear = () => {
    setSelectStudent("");
    setHostelId("");
    setPaymentInfo(initialValue);
    setPaymentMode("Cash");
    setStudentData([]);
    setReceiptType("New");
  };
  const { validationError, validateControl } = ValidationComponent({ 
    data: initialValue,
    requiredFields: selectStudent?.id ? [] : ["alloted_id"],
  });

  const CancelReceipt = () => {
    Swal.fire({
      title: "Confirmation",
      text: "Do you want to Cancel Receipt?",
      icon: "question",
      showDenyButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes",
      denyButtonText: "No",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { value: cancelReason } = await Swal.fire({
          icon: "question",
          title: "Enter Cancel Reason ?",
          input: "text",
          inputPlaceholder: "Enter your cancel reason",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          allowOutsideClick: false,
        });
        if (cancelReason) {
          let body = {
            payment_id: selectStudent?.id,
            cancel_reason: cancelReason,
            insert_by: school_emp_id,
          };
          const { data } = await HostelAPI.cancelHostelPayment(body);
          if (data.status === 200) {
            Swal.fire({
              text: data.msg,
              icon: "success",
              imageAlt: "success image",
              allowOutsideClick: false,
            });
            handleClear();
            setReceiptType("New");
          } else if (data.status === 403) {
            Swal.fire({
              text: data.msg,
              icon: "error",
              imageAlt: "success image",
              allowOutsideClick: false,
            });
          }
        }
      }
    });
  };

  if (isLoading) return <Loader />;
  return (
    <div>
      <div className="section-body">
        <div className="d-flex justify-content-between align-items-center">
          <div className="header-action">
            <h1 className="page-title">Hostel Fees Receipt</h1>
          </div>
          <div className="mt-2 input-group-append">
            {receiptType !== "New" && (
              <button
                type="button"
                className="btn btn-sm btn-outline-danger mx-1"
                onClick={() => CancelReceipt()}
                disabled={selectStudent?.payment_status === "CANCELLED"}
              >
                <i className="fa fa-times px-1"></i>
                Cancel
              </button>
            )}

            <button
              type="button"
              className="btn btn-sm btn-outline-danger mx-1"
              onClick={handleClear}
            >
              <i className="fa fa-times px-1"></i>
              Clear
            </button>
            <HostelReceiptList
              setSelectStudent={setSelectStudent}
              school_id={school_id}
              setReceiptType={setReceiptType}
            />
          </div>
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-md-8">
                  <div className="form-group  row">
                    <label className="col-md-4 col-form-label">
                      Select Hostel <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-8">
                      <Select
                        name="hostelTypeId"
                        placeholder="Select Hostel"
                        options={hostelData}
                        value={
                          hostelId
                            ? hostelData?.find(
                                (option) => option.value === hostelId
                              )
                            : null
                        }
                        onChange={(selectedOptions) => {
                          setHostelId(selectedOptions.value);
                        }}
                        isSearchable={true}
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            backgroundColor: "#fff",
                            zIndex: 999,
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8 col-md-6 col-sm-12">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <label className="col-form-label">
                        Student Code <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Student Code"
                          defaultValue={
                            selectStudent && selectStudent?.student_code
                              ? selectStudent?.student_code
                              : null
                          }
                          //   onChange={(e) => setstudent_uid(e.target.value)}
                        />
                        <FeesReceiptList
                          studentList={studentData}
                          setSelectStudent={setSelectStudent}
                          hostelId={hostelId}
                          setHostelId={setHostelId}
                        />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-6 col-sm-6">
                      <label className="col-form-label">
                        Student Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Student Name"
                        defaultValue={
                          selectStudent && selectStudent?.student_name
                            ? selectStudent?.student_name
                            : null
                        }
                        readOnly
                      />
                      <ValidationMessage
                        condition={
                          validationError && !selectStudent?.alloted_id
                        }
                        errorMsg={"Please Select Student."}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <label className="col-form-label">
                        Receipt Date <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={selectStudent?.receipt_date ?? receiptDate}
                        onChange={(e) => setreceiptDate(e.target.value)}
                        disabled={
                          selectStudent?.fk_allotedroom_id ? true : false
                        }
                      />
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <label className="col-form-label">
                        Receipt No <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Receipt No"
                        defaultValue={
                          selectStudent?.receipt_no
                            ? selectStudent?.receipt_no
                            : null
                        }
                        // onChange={(e) => setreceiptDate(e.target.value)}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              {selectStudent?.alloted_id || selectStudent?.fk_allotedroom_id ? (
                <div className="table-responsive card">
                  <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
                    <thead>
                      <tr className="text-center">
                        <th>Sr.No.</th>
                        <th>Fees</th>
                        <th className="text-center">Total Fees</th>
                        <th className="text-center">Fees Paid</th>
                        <th className="text-center">Amount</th>
                        <th className="text-center">FEES DUE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{1}</td>
                        <td>Hostel Fees</td>
                        <td>
                          <input
                            type="number"
                            className="form-control text-right"
                            defaultValue={
                              selectStudent?.total_fees
                                ? selectStudent?.total_fees
                                : selectStudent?.hostel_total_fees
                            }
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            readOnly
                            type="number"
                            className="form-control text-right"
                            defaultValue={
                              selectStudent?.totalpaid_fees
                                ? selectStudent?.totalpaid_fees
                                : selectStudent?.totalpaid_fee
                            }
                          />
                        </td>
                        <td>
                          <input
                            name="paid_amount"
                            type="number"
                            className="form-control text-right"
                            value={paymentInfo.paid_amount}
                            onChange={(e) => {
                              const numericValue = e.target.value;
                              if (numericValue <= BalanceFee) {
                                if (numericValue < 0) {
                                  alert.info(
                                    "Amount can not be less than zero"
                                  );
                                } else {
                                  handleInputChange(e);
                                }
                              }else{
                                alert.info(
                                  "Amount can not be greater than Balance Amount"
                                );
                              }
                            }}
                            disabled={
                              selectStudent?.fk_allotedroom_id ? true : false
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control text-right"
                            defaultValue={BalanceFee}
                            readOnly
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <h6 className="page-title">Payment Information</h6>

                  <div className="d-flex py-1">
                    <label className="givenWigth m-auto">
                      Payment Mode <span className="text-danger">*</span>
                    </label>
                    <Select
                      options={PaymentList}
                      placeholder="Payment Mode"
                      value={PaymentList?.find(
                        (pay) => pay.value === PaymentMode
                      )}
                      //   defaultValue={PaymentList[0]}
                      onChange={(select) => {
                        handlePaymentMode(select);
                      }}
                      isSearchable={true}
                      className="form-control m-0 p-0"
                    />
                  </div>
                  {paymentInfo.payment_mode === "Cash" ? null : (
                    <>
                      {paymentInfo.payment_mode === "UPI" ? null : (
                        <div className="d-flex py-1">
                          <label className="givenWigth m-auto">Bank Name</label>
                          <input
                            disabled={
                              PaymentMode?.value === "Cash" ? true : false
                            }
                            className="form-control"
                            type="text"
                            placeholder="Bank Name"
                            name="bank_name"
                            value={paymentInfo.bank_name}
                            onChange={handleInputChange}
                          />
                        </div>
                      )}
                      <div className="d-flex py-1">
                        <label className="givenWigth m-auto">
                          {paymentInfo.payment_mode === "Bank"
                            ? "Account"
                            : paymentInfo.payment_mode}
                          Number
                        </label>
                        <input
                          disabled={
                            PaymentMode?.value === "Cash" ? true : false
                          }
                          className="form-control"
                          type="number"
                          name="pay_no"
                          placeholder={
                            paymentInfo.payment_mode === "Bank"
                              ? "Account Number"
                              : paymentInfo.payment_mode + " Number"
                          }
                          value={paymentInfo.pay_no}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="d-flex py-1">
                        <label className="givenWigth m-auto">Payment Date</label>
                        <input
                          disabled={
                            PaymentMode?.value === "Cash" ? true : false
                          }
                          className="form-control"
                          type="date"
                          placeholder="Payment Date"
                          name="pay_date"
                          value={paymentInfo.pay_date}
                          onChange={handleInputChange}
                        />
                      </div>
                    </>
                  )}
                  <div className="d-flex py-1">
                    <label className="givenWigth m-auto">Total Amount </label>
                    <input
                      className="form-control text-right"
                      type="number"
                      placeholder="Total Amount"
                      // value={(paymentInfo.total_amount = Sum_annual_fee())}
                      defaultValue={paymentInfo.paid_amount}
                      //   onChange={handleInputChange}
                      readOnly
                    />
                  </div>

                  <div className="d-flex py-1">
                    <label className="givenWigth m-auto">Paid Amount</label>
                    <input
                      className="form-control text-right"
                      type="number"
                      placeholder="Paid Amount"
                      defaultValue={paymentInfo.paid_amount}
                      //   onChange={handleInputChange}
                      readOnly
                    />
                  </div>
                  <div className="d-flex py-1">
                    <label className="givenWigth m-auto">Remark</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Remark"
                      name="remark"
                      value={paymentInfo.remark}
                      onChange={handleInputChange}
                    />
                  </div>
                  {selectStudent?.payment_status !== "CANCELLED" && (
                    <PrintHostelFeesReceipt
                      selectStudent={selectStudent}
                      receiptDate={receiptDate}
                      paymentInfo={paymentInfo}
                      schoolUser={schoolUser}
                      validateControl={validateControl}
                      handleClear={handleClear}
                      receiptType={receiptType}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostelFeesReceipt;
