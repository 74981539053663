import React, { useState, useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  FormFeedback,
  Container,
  Table,
} from "reactstrap";

import { MEDIA_BASE_URL } from "../../../../Config/BaseUrl";
const WorkSheetList = ({ WorkSheetList, editWorkSheet, deleteWorkSheet }) => {
  // this file renders the homeWorkList for the user 
  return (
    <>
      {WorkSheetList
        ? WorkSheetList?.homework?.map(
            (
              {
                fk_homework,
                fk_homework__title,
                fk_homework__decription,
                fk_homework__homework_doc,
                fk_homework__fk_class__class_name,
                fk_section__section,
              },
              i
            ) => (
              <div
                className="container bg-white mt-3 pt-1 pb-1 questionlist"
                key={fk_homework}
              >
                <div className="row">
                  <div
                    className="col-lg-12 col-sm-12 col-xs-12"
                    style={{ backgroundColor: "#e9ecef" }}
                  >
                    <div className="row">
                      <div className="col-lg-9">{fk_homework__title}</div>
                      <div className="col-lg-3 text-right">
                        {fk_homework__homework_doc && (
                          <a
                            href={`${MEDIA_BASE_URL}${fk_homework__homework_doc}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Download"
                          >
                            <i className="fa fa-download"></i>
                          </a>
                          
                        )}
                        {console.log(fk_homework__homework_doc)}
                        <i
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Edit"
                          className="fa fa-edit ml-2"
                          onClick={() => editWorkSheet(fk_homework)}
                        ></i>
                        <i
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Delete"
                          className="fa fa-trash-o text-danger ml-2"
                          onClick={() => deleteWorkSheet(fk_homework)}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-sm-12 col-xs-12 mt-2">
                    <b>Class</b> : {fk_homework__fk_class__class_name}
                  </div>
                  <div className="col-lg-4 col-sm-12 col-xs-12 mt-2">
                    <b>Section</b> :{" "}
                    {fk_section__section.map(
                      (data, i) =>
                        data +
                        (i === fk_section__section.length - 1 ? "" : " , ")
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-xs-12 mt-2">
                    {fk_homework__decription}
                  </div>
                </div>
                <div></div>
              </div>
            )
          )
        : ""}
    </>
  );
};

export default WorkSheetList;
