import React, { useEffect, useState } from "react";
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { convertDate } from "../../CommonComponent/DateFormatUtil";
import Pagination from "react-js-pagination";
const CollectionTable = (props) => {
  // this is for fee collection table 
  // fee collection table is reflected here
  const {
    from_date,
    setFrom_date,
    setTo_date,
    to_date,
    optionList,
    AdmissionStatus,
    setAdmissionStatus,
    feeCollectionData,
    placeholder,
    FeeTable,
    Page,
    setCurrentPage,
    CurrentPage,
  } = props;
  const [totalAmount, setTotalAmount] = useState(0);

  const resultPerPage = 10;
  useEffect(() => {
    if (FeeTable === "Hostel") {
      const total = feeCollectionData?.reduce(
        (sum, item) => sum + parseFloat(item.paid_amount) || 0,
        0
      );
      setTotalAmount(total);
    }
  }, [feeCollectionData, FeeTable]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-6 my-1">
                <div className={`input-group`}>
                  <label className="col-form-label mx-2">
                    From Date<span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={from_date}
                    onChange={(e) => setFrom_date(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 my-1">
                <div className={`input-group`}>
                  <label className="col-form-label mx-2">
                    To Date<span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={to_date}
                    onChange={(e) => setTo_date(e.target.value)}
                  />
                </div>
              </div>

              <Select
                className="col-lg-5 col-md-4 col-sm-6 my-1"
                isMulti
                placeholder={placeholder}
                options={optionList}
                value={
                  AdmissionStatus
                    ? optionList.find((a) => a.value === AdmissionStatus)
                    : null
                }
                onChange={(selectedOptions) => {
                  const selectedValues = selectedOptions.map(
                    (option) => option.value
                  );
                  setAdmissionStatus(selectedValues);
                }}
                isSearchable={true}
              />

              <div className="col-lg-1 col-md-4 col-sm-6 my-1 text-right">
                {FeeTable !== "Hostel" &&
                  feeCollectionData?.data?.length > 0 && (
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="download-table-xls-button btn-sm btn btn-success"
                      color="success"
                      table="table-to-xls2"
                      filename="fee_Collection"
                      sheet="tablexls"
                      buttonText="EXPORT"
                    />
                  )}
                {FeeTable === "Hostel" && feeCollectionData?.length > 0 && (
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn-sm  btn btn-success "
                    color="success"
                    table="table-to-xls1"
                    filename="Hostel_fee_Collection"
                    sheet="tablexls"
                    buttonText="EXPORT"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            {FeeTable === "Hostel" && (
              <>
                <table
                  className="table table-striped table-sm text-nowrap text-center"
                  id="table-to-xls1"
                >
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>Receipt No.</th> 
                      <th>Name</th>
                      <th>Receipt Date</th>
                      <th>Payment Mode</th>
                      <th>Payment Status</th>
                      <th>Paid Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {feeCollectionData?.map((list, index) => {
                      return (
                        <tr
                          key={index}
                          className={`${
                            list.payment_status === "CANCELLED"
                              ? "table-danger"
                              : ""
                          } `}
                        >
                          <td>{(CurrentPage - 1) * 10 + index + 1}</td>
                          <td>{list.receipt_no}</td>
                          <td className="text-left">{list.student_name}</td>
                          <td>{convertDate(list.receipt_date)}</td>
                          <td className="">{list.payment_mode}</td>
                          <td className="">{list.payment_status}</td>
                          <td className="text-right">{list.paid_amount}</td>
                        </tr>
                      );
                    })}
                    {/* <tr className="">
                    <th colSpan={6} className="text-right text-dark">
                      <h1 className="page-title">TOTAL</h1>
                    </th>
                    <th colSpan={1} className="text-right text-dark">
                      <h1 className="page-title mr-0">{totalAmount}</h1>
                    </th>
                  </tr> */}
                  </tbody>
                </table>

                <ul className="pagination justify-content-center">
                  {feeCollectionData?.length > 0 && (
                    <Pagination
                      activePage={CurrentPage}
                      itemsCountPerPage={resultPerPage}
                      totalItemsCount={Page}
                      hideFirstLastPages={false}
                      onChange={(e) => setCurrentPage(e)}
                      nextPageText="Next"
                      prevPageText="Previous"
                      lastPageText="Last"
                      firstPageText="1st"
                      itemClass="page-item"
                      linkClass="page-link"
                      activeClass="active"
                      activeLinkClass="active"
                      hideDisabled={false}
                    />
                  )}
                </ul>
              </>
            )}
            {FeeTable !== "Hostel"  && (
              <table
                className="table table-striped table-sm text-nowrap text-center"
                id="table-to-xls2"
              >
                <thead>
                  <tr>
                    <th>Sr No</th>
                    <th>Receipt No.</th>
                    <th>Name</th>
                    <th>Receipt Date</th>
                    <th>Total Amount</th>
                    <th>Late Fee Charges</th>
                    <th>Paid Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {feeCollectionData?.data?.map((list, index) => (
                    <tr
                      key={index}
                      className={`${
                        list.type === "Minus" ? "table-danger" : ""
                      } `}
                    >
                      <td>{index + 1}.</td>
                      <td>{list.Receipt_no}</td>
                      <td className="text-left">{list.Student_name}</td>
                      <td>{convertDate(list.Receipt_date)}</td>
                      <td className="text-right">{list.Total_amount}</td>
                      <td className="text-right">{list.Late_charges}</td>
                      <td className="text-right">{list.Paid_amount}</td>
                    </tr>
                  ))}
                  <tr className="">
                    <th colSpan={4} className="text-right text-dark">
                      <h1 className="page-title">TOTAL</h1>
                    </th>
                    <th colSpan={1} className="text-right text-dark">
                      <h1 className="page-title mr-0">
                        {feeCollectionData?.total_amount}
                      </h1>
                    </th>
                    <th colSpan={1} className="text-right text-dark">
                      <h1 className="page-title mr-0">
                        {feeCollectionData?.total_late_charge
                          ? feeCollectionData?.total_late_charge
                          : feeCollectionData?.total_fine_amount}
                      </h1>
                    </th>
                    <th colSpan={1} className="text-right text-dark">
                      <h1 className="page-title mr-0">
                        {feeCollectionData?.total_paid_amount}
                      </h1>
                    </th>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectionTable;
